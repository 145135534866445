import React, { Component, createRef} from 'react'
import AceEditor from "react-ace";
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import ReactMarkdown from "react-markdown";

import "ace-builds/src-noconflict/mode-markdown";

// styles
const styles = (theme)=>({
    ...theme.otherStyles,
    problemDescription:{
        height: '100%',
        // maxHeight:'100%',
        overflowY:'scroll',
        display:'flex',
        flexDirection:'column',
        padding: '10px 10px',
        backgroundColor:'white',
        // minHeight: '100%'
    },
    problemDescriptionContent:{
        "& ProblemStatement":{
            "margin":"0px",
            "font-size": "0.8571428571428571rem",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-weight": "400",
            "line-height": "1.5",
            "letter-spacing": "0.00938em",
            "margin-bottom": "16px",
        },
        "& p:first-child":{
          'margin-top': '0px'
        },
        "& .problemStatement":{
            "margin":"0px",
            "font-size": "0.8571428571428571rem",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-weight": "400",
            "line-height": "1.5",
            "letter-spacing": "0.00938em",
            "margin-bottom": "16px"
        },
        "& code":{
            // "font-weight":"bold",
            // "font-family":'monaco',
            "font-size": "0.9rem",
            "background-color":'rgb(247, 249, 250)'
        },
        '& h6':{
          "margin":"0px",
          "font-size": "0.8571428571428571rem",
          "font-family": "Roboto, Helvetica, Arial, sans-serif",
          "font-weight": "bold",
          "line-height": "1.5",
          "letter-spacing": "0.00938em",
        },
        '& pre':{ 
          'background-color': 'rgb(247, 249, 250)', 
          'padding': '2px 4px',
          'margin-top':'0.25rem',
          'white-space':'pre-line'
        //   'line-height': '0px'
        },
        '& div':{
          'white-space':'initial', 
          'line-height':'1.5',
        },
        '& span':{
          'display':'inline', 
          'font-weight':'bold'
        }
    }
});

class MarkDownPreview extends Component {

    constructor(){
        super();
        this.aceRef = createRef();
        this.state = {
            language: 'markdown',
            theme:'monokai',
            keybinding:'sublime',
            code:'something improtant',
            loading: false
        }
    }

    componentDidMount(){
        // this.props.setRef(this.aceRef)
    }
    render() {
        const {classes, problemDescription, activeProblemTitle, activeProblemIndex} = this.props;
 
         return (
             <div className={classes.problemDescription}>
                 <Typography variant="h6" className={classes.problemTitle}>
                     {activeProblemIndex+1}. {activeProblemTitle}
                 </Typography>
                 <hr className={classes.visibleSeparator} />
                   {/* <div dangerouslySetInnerHTML={{__html:problemDescription}} className={classes.problemDescriptonContent}>
                   </div> */}
                   <div className={classes.problemDescriptionContent}>
                     <ReactMarkdown >{problemDescription}</ReactMarkdown>
                   </div>
             </div>
         )
     }
}


MarkDownPreview.propTypes = {
    // ref: PropTypes.object.isRequired,
    // keybinding: PropTypes.string.isRequired,
    // mode: PropTypes.string.isRequired,
    // theme:PropTypes.string.isRequired,
    // value:PropTypes.string.isRequired,
}

export default withStyles(styles)(MarkDownPreview)
