import {
    SET_PROBLEMSETS

  } from '../types';
  
const initialState = {
  problemSets: {
      'activeProblemSets':[],
      'hotProblemSets': [],
      'allProblemSets': []
  },
  problemSet:{}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PROBLEMSETS:
      return {
        ...state,
        problemSets: action.payload,
      };
    default:
      return state;
  }
}