export default {
    typography: {
      fontSize: 12,
    },
    palette: {
        primary: {
          light: '#484848',
          main: '#212121',
          dark: '#000000',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#ffa06d',
          main: '#ff6e40',
          // main: '#ff5a40',
          // main: '#ff3640',
          dark: '#c53d13',
          contrastText: '#000000',
        },
        // text: {
        //   primary: '#000',
        //   secondary: '#fff'
        // },
        // primary: {
        //   light: '#63a4ff',
        //   main: '#1976d2',
        //   dark: '#004ba0',
        //   contrastText: '#fff',
        // },
        // secondary: {
        //   light: '#ff867f',
        //   main: '#ff5252',
        //   dark: '#c50e29',
        //   contrastText: '#000',
        // },
    },
    otherStyles: {
      visibleSeparator: {
        width: '100%',
        borderBottom: '1px solid rgba(2,2,2,0.1)',
        marginBottom: 20
      },
      code: {
        backgroundColor: 'rgb(247, 249, 250)',
        padding: '2px 4px',
        marginTop:'0.25rem'
      },
      typography: {
        useNextVariants: true,
      },
      form: {
          textAlign: 'center'
      },
      appIcon: {
          height: 200,
          width: 200,
          margin: '20px auto 0px auto',
      },
      pageTitle: {
          margin: '0px auto 10px auto',
      },
      textField : {
          margin: '10px auto 10px auto',
      },
      '& button': {
          // marginTop: 20,
          position: 'relative',
          borderRadius:'0px' 
      },
      customError : {
          color: 'red',
          fontSize: '0.8rem',
          marginTop: 10
      },
      progress: {
          position: 'absolute'
      }
      // card: {
      //     position: 'relative',
      //     display: 'flex',
      //     marginBottom: 20
      // },
      // image: {
      //     minWidth: 200,
      // },
      // content: {
      //     padding: 25,
      //     objectFit: 'cover',
      // },
      // invisibleSeparator: {
      //     border: 'none',
      //     margin: 4
      // },
      // visibleSeparator: {
      //     width: '100%',
      //     borderBottom: '1px solid rgba(0,0,0,0.1)',
      //     marginBottom: 20
      // },
      // paper: {
      //     padding: 20
      // }
    },
    
} 