import React, { Component, Fragment } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const styles = (theme) => ({
    ...theme.otherStyles,
    outputDescription:{
        // overflow:'scroll',
        display:'flex',
        flexDirection:'column',
        padding: '10px 10px',
        backgroundColor:'white',
        minHeight:'100%'
    },
    outputStatus: {
        '& h6': {
            margin: theme.spacing(1),
            'marginBottom': '0px',
        }
    },
    passColor: {
        'color': green[400]
    },
    failColor: {
        'color': red[400]
    },
    singleTestLog: {
        margin: theme.spacing(1),
    },
    testLogPrints: {
        // margin: theme.spacing(1),
        '& h6': {
            'display': 'flex',
            'align-items':'center',
        },
        '& svg': {
            'marginRight': theme.spacing(1),
        }
    },
    stdout: {
        'border': '1px solid grey',
        'white-space': 'pre-wrap',
        'padding': '8px'
    }
  });


class Output extends Component {

    constructor(){
        super();
        this.state = {
            failedTests :0,
            passedTests :0
        }
    }

    componentDidUpdate(prevProps) {
        let failedCount = 0;
        let passedCount = 0;
        if (prevProps.testLog != this.props.testLog) {
            this.props.testLog && this.props.testLog.forEach((test)=>{
                if (test.status==="Pass") {
                    passedCount+=1
                } else {
                    failedCount+=1
                }
            })
        this.setState({
            failedTests: failedCount, 
            passedTests: passedCount
        })
        }
    }

    render() {
        const {classes, testLog} = this.props;

        let testLogPrints = testLog && testLog.length>0 ? (
            testLog.map((test)=>{
                if (test.status === "Pass"){
                   return(
                       <Fragment>
                            <Grid container direction='column' justify='flex-start' className={classes.testLogPrints}>
                                <Grid key ={test.name} item sm={12} display='flex' className={classes.singleTestLog}>
                                <Typography variant="subtitle1" fontWeight="500" className={classes.passColor}>
                                    <CheckCircleIcon fontSize="small"/> <span fontWeight="bold">{test.name}: {test.status}</span>
                                </Typography>
                                
                                {test.stdout && <Typography className={classes.stdout} fontWeight='bold'>
                                        {test.stdout}
                                </Typography>}
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                } else {
                   return(
                       <Fragment>
                            <Grid container direction='column' justify='flex-start' className={classes.testLogPrints}>
                                <Grid key={test.name} item sm={12} className={classes.singleTestLog}>
                                <Typography variant="subtitle1" fontWeight="500" className={classes.failColor}>
                                    <ErrorOutlineOutlinedIcon fontSize="small"/> <span fontWeight="bold">{test.name}: {test.msg}</span>
                                </Typography>
                                {test.stdout && <Typography className={classes.stdout}>
                                    {test.stdout}
                                </Typography>}
                                </Grid>
                            </Grid>
                        </Fragment>
                    )
                }
            })
        ) : (
            <p> waiting for results </p>
        );

        return (
            <div className={classes.outputDescription}>
                <Grid container justify='flex-start' className={classes.outputStatus}>
                    <Typography display='inline' variant="subtitle1" fontWeight="500" className={classes.passColor}>
                        <Box fontWeight="bold">Passed: {this.state.passedTests}</Box>
                    </Typography>
                    <Typography display='inline' variant="subtitle1" fontWeight="500" className={classes.failColor}>
                        <Box fontWeight="bold">Failed:{this.state.failedTests}</Box>
                    </Typography>
                </Grid>
                <hr className={classes.visibleSeparator} />
                {testLogPrints}
            </div>
        )
    }
}

export default withStyles(styles)(Output)
