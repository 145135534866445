import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {connect} from 'react-redux'
import {getCalendarAttempts} from '../../redux/actions/userActions'

import Login from './Login'
import Signup from './Signup'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
   //marginBottom: "10px",
    minHeight:'18px',
    height:'35px',
    width:'100%',
    '& .MuiButtonBase-root': {
        // fontSize:'0.8571428571428571rem'
        fontSize:'0.9rem',
        padding:'6px 6px'
    },
    '& .MuiTab-root':{
      minHeight:'35px'
    }

  },
  active:{
      backgroundColor: '#282a36',
      color:'white',
      border: 'none',
      // borderBottom: '2px solid grey'
      minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
  notActive:{
      backgroundColor: 'transparent',
      //border: '1px solid grey',
      //borderTop: 'none'
     minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
    signupForm: {
        height:'50vh',
        backgroundColor: '#fff'
    },
    dialogContent:{
      height:'100%',
      padding:'0px !important'
    }
}));


function SignUpDialog(props) {
  const classes = useStyles();
  
  const [activeTab, setActiveTab] = React.useState(0);
  const {isDialogOpen, setIsDialogOpen} = props;

  let updateActiveTab = (event, newValue)=>{
    setActiveTab(newValue)
  }

  let signUpSuccess = ()=>{
    setIsDialogOpen(false)
    props.getCalendarAttempts()
  }

  return (
      <Dialog
        open={isDialogOpen}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <div style={{
              display:'flex',
              justifyContent:'space-between',
              width:'100%'
            }}
        >

          <Tabs value={activeTab} onChange={updateActiveTab} aria-label="simple tabs example" className={classes.tabs} > 
                <Tab label="Signup" {...a11yProps(0)} className={activeTab===0?classes.active:classes.notActive}/>
                <Tab label="Login" {...a11yProps(1)} className={activeTab===1?classes.active:classes.notActive}/>
          </Tabs>

          <IconButton 
            style={{
              padding:'0px',
              paddingRight:'4px'
            }}
            onClick = {()=>{setIsDialogOpen(false)}}
          >
              <CancelIcon style={{
                color:'black',
                fontSize:'1.5rem'
              }}/>
          </IconButton>
        </div>

        <TabPanel value={activeTab} index={0} className={classes.signupForm}>
            <DialogContent className={classes.dialogContent}>
              <Signup signUpSuccess = {signUpSuccess}/>
            </DialogContent>
        </TabPanel>
        <TabPanel value={activeTab} index={1} className={classes.signupForm}>
          <DialogContent className={classes.dialogContent}>
            <Login 
              logInSuccess = {signUpSuccess}
            />
          </DialogContent>
        </TabPanel>
      </Dialog>
  );
}


SignUpDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen : PropTypes.func.isRequired
}


const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
  getCalendarAttempts : getCalendarAttempts
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(SignUpDialog);
