import { CallToActionSharp } from '@material-ui/icons';
import {
    SET_USER,
    SET_UNAUTHENTICATED,
    SET_AUTHENTICATED,
    SET_CALENDAR_ATTEMPTS
} from '../types';
  
const initialState = {
    'authenticated':false,
    'signUpSuccess': false,
    'calendar_attempts': []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
        return {
          ...state,
          authenticated: true,
          ...action.payload
        }
    case SET_AUTHENTICATED:
        return {
          ...state,
          authenticated:true
        };
    case SET_UNAUTHENTICATED:
        return initialState;
    case SET_CALENDAR_ATTEMPTS:
      return {
        ...state,
        calendar_attempts:[...action.payload]
      };
    default:
      return state;
  }
}