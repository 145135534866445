import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import AppIcon from '../images/final-logo-black.png';

import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';

import notFoundImage from '../images/under-construction.svg';

//MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles= (theme)=>({
    ...theme.otherStyles,
    cardGrid:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        // padding:'40px 40px',
        background:'white',
        height:'100vh'
    },
    cardOuter:{
        'box-shadow':'none',
        // padding:'10px',
        // minWidth:'100%'
        width:'fit-content',
        // marginBottom:'10px',
        // marginRight:'10px',
        textAlign:'center',

    },
    cardContent:{
        padding:'20px',
    },
    cardTitle:{
        marginBottom:'20px',
        // paddingBottom:'40px',
    },
    cardErrorTitle:{
        marginBottom:'20px',
        // paddingBottom:'40px',
        color:theme.palette.secondary.main,
        textAlign:'center',
        fontWeight:'bold'
    },
    cardSuccessTitle:{
        marginBottom:'20px',
        // paddingBottom:'40px',
        color:'green',
        textAlign:'center',
        fontWeight:'bold'
    },
    loaderOuter:{
        // padding:'20px'
    },
    progress:{
        // color:'green'
    }
});

class NotFound extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Grid container className={classes.cardGrid}r>
                <Grid item sm={12} md={8}>
                    <Card className={classes.cardOuter}>
                        <CardContent className={classes.cardContent}>
                            <Typography variant='h4' component='h5' gutterBottom>It's going to take some time to be built</Typography>
                            <img src={notFoundImage} style={{width:'100%'}}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(NotFound);