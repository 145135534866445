import React, { Component } from 'react'

// material ui stuff
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles, createMuiTheme } from '@material-ui/core/styles';

// local components
import AttemptArenaContainer from './AttemptArenaContainer';
import Calendar from './Calendar';

//local files
import themeFile from '../../util/theme';

//globals
const theme = createMuiTheme(themeFile);
const styles = (theme)=>({
    ...theme.otherStyles,
})

class DailyProblem extends Component {


    render() {
        return (
            <div>
                {/* <Grid container style={{
                    display:'flex',
                    justifyContent:'center',
                    marginTop:'40px',
                }}>
                    <Grid item sm={6}>
                        <Typography variant='h5'>
                    “What is it that you do to train that is comparable to a pianist practicing the scales?” – Tyler Cowen
                        </Typography>
                    </Grid>
                </Grid> */}
                <Grid container style={{
                    display:'flex',
                    justifyContent:'center',
                    marginTop:'40px'
                }}>
                   <Grid item sm={10} xs={12}>
                        <Calendar />
                    </Grid>
                </Grid>
               <Grid container style={{
                    padding:'4px',
                    paddingBottom:'12px',
                    // marginTop:'20px',
                //    paddingLeft:'10px'
               }}>
                   <Grid item sm={12} xs={12}>
                       <AttemptArenaContainer />
                   </Grid>
               </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(DailyProblem)
