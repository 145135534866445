import React, {Fragment} from 'react'
import { withRouter } from "react-router";

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

//components
import SignUpDialog from '../components/Auth/SignUpDialog';

const AuthButton = ({children, ...props})=> {

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const {disabled, onClick} = props;

    const onAuthButtonClick = ()=>{
        if (props.authenticated){
            onClick()
        } else {
            setIsDialogOpen(true)
        }
    }

    return (
        <Fragment>
            <SignUpDialog 
                isDialogOpen = {isDialogOpen}
                setIsDialogOpen = {setIsDialogOpen}
            />

            <Button 
                size={props.size}
                variant={props.variant} 
                color={props.color}
                disabled={disabled}
                onClick={onAuthButtonClick} 
                style = {props.style}
            >
                {children}
            </Button>
        </Fragment>
    )
}


AuthButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    authenticated: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
}


export default connect(
    (state)=>({
        authenticated : state.user.authenticated
    }),
    {}
)((withRouter)(AuthButton))