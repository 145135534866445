import React, { Component, createRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";

import Button from "@material-ui/core/Button";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/grid';
import axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';


import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme)=>({
  ...theme.otherStyles,
  signUpFormItem:{
    display:"flex",
    flexDirection:'column',
    alignItems:'flex-end',
    '& button':{
        borderRadius:'0px'
    },
    "& .MuiFormLabel-root":{
        color:'white',
    }
    },
  textField:{
    width:'100%',
    color:'#f8f8f8 !important',
    // marginBottom:'8px',
    // "& input": {
    //     color:"#f8f8f8",
    //     // padding:'8px',
    //     // backgroundColor:'black',
    // },
    '& .MuiOutlinedInput-root': {
        color:'#f8f8f8',
        backgroundColor:'black',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
        },
    }
  },
  footer:{
      display:'flex',
      'flex-direction':'row',
      'justify-content':'center',
      'align-items':'center',
      backgroundColor:'#000',
      color:'#eee',
      padding:'8px 1rem',
      '& button':{
          color:'#eee'
      },
  },
  rightDiv:{
      'display':'flex',
      'justify-content':'flex-end',
  }
})

class Footer extends Component {

    constructor(props){
        super();
        this.state = {
            feedback:'',
            errors:'',
            email:'',
            loading:false,
            showSuccessMessage:false
        };
    }

    handleChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    };
    
    onSendClick = ()=>{

        let feedbackData = {
            feedback:this.state.feedback,
            email:this.state.email
        }

        this.setState({
            loading:true
        })

        axios.post(`rest-auth/feedback/`, feedbackData)
        .then((res)=>{
            console.log(res)
            this.setState({
                loading:false,
                feedback:'',
                email:'',
                errors:'',
                showSuccessMessage:true
            })
        })
        .catch((e)=>{
            this.setState({
                loading:false,
                errors:e.response.data
            })
        })
    }

    handleCloseSnackbar = ()=>{
        this.setState({
            showSuccessMessage:false
        })
    }

  render() {
    const {classes, user} = this.props;

    return (
        <Grid container direction="column" style={{
            backgroundColor:'#000',
        }}>
            <Grid container justify='center' style={{
                marginTop:'16px'
            }}>
                <Snackbar open={this.state.showSuccessMessage} autoHideDuration={8000} onClose={this.handleCloseSnackbar}>
                    <Alert className={classes.SuccessMessage} onClose={this.handleCloseSnackbar} severity="success">
                    <Typography variant='body1'>Thank you for your feedback. I am dropping everything else to address this concern.</Typography>
                    </Alert>
                </Snackbar>
                <Grid item container sm={5} className={classes.signUpFormItem}>
                    <TextField 
                        id='feedback' 
                        name='feedback' 
                        label="Angry feedback ="
                        size="small"
                        variant='outlined'
                        className={classes.textField}
                        value={this.state.feedback} 
                        onChange={this.handleChange} 
                        helperText={this.state.errors.feedback}
                        error = {this.state.errors.feedback ? true:false}
                        fullWidth
                        multiline
                        rows={4}
                        />
                    <Grid container direction='row' justify='space-between' alignItems='flex-start'> 
                        <Grid item sm={9} md={10}>
                            <TextField 
                                id='email' 
                                name='email' 
                                label="Your email address (optional)"
                                size="small"
                                variant='outlined'
                                className={classes.textField}
                                value={this.state.email} 
                                onChange={this.handleChange} 
                                helperText={this.state.errors.email}
                                error = {this.state.errors.email ? true:false}
                                style={{
                                    marginTop:'8px'
                                }}
                                />
                        </Grid>
                        <Grid item container sm={3} md={2} justify='flex-end' alignItems='flex-start' style={{
                            display:'flex',
                            height:'100%'
                        }}>
                            <Button
                                variant='contained'
                                color='secondary'
                                size='medium'
                                style={{
                                    borderRadius:'0px',
                                    marginTop:'9px',
                                    // marginBottom:'8px'
                                    height:'initial'
                                }}
                                disabled = {this.state.loading}
                                onClick = {this.onSendClick}
                            >
                                {this.state.loading && (
                                    <CircularProgress size={30} style={{
                                        color:'white',
                                        position:'absolute'
                                    }}/>
                                    )
                                }
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={classes.footer}>
                <Grid item sm={3}>
                    <Typography variant='body1'>@2021 fcomplexity</Typography>
                </Grid>
                <Grid item sm={9} className={classes.rightDiv}>
                    {/* <Link to='/contact'>
                        <Button>
                            Contact
                        </Button>
                    </Link>
                    <Link to='/about'>
                        <Button>
                            About
                        </Button>
                    </Link> */}
                    <Link to='/privacy-policy'>
                        <Button>
                            Privacy Policy
                        </Button>
                    </Link>
                    <Link to='/terms-of-service'>
                        <Button>
                            Terms of Service
                        </Button>
                    </Link>
                </Grid>
            </Grid>
      </Grid>
    )
  }
}



export default withRouter(withStyles(styles)(Footer))
