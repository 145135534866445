import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import MarkDownEditor from './MarkDownEditor';
import MarkDownPreview from './MarkDownPreview';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
   //marginBottom: "10px",
    minHeight:'18px',
    height:'35px',
   '& .MuiButtonBase-root': {
        // fontSize:'0.8571428571428571rem'
        fontSize:'0.7rem',
        padding:'3px 6px'
    },
    '& .MuiTab-root':{
      minHeight:'35px'
    }

  },
  active:{
      backgroundColor: '#fff',
      //border: 'none',
      //borderBottom: '2px solid grey'
      minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
  notActive:{
      backgroundColor: 'transparent',
      //border: '1px solid grey',
      //borderTop: 'none'
     minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
    description: {
        height:'100%',
        // maxHeight:'80vh',
        // backgroundColor: '#fff',
        // overflowY:'scroll',
    },
    preview: {
      height:'100%',
      backgroundColor: '#fff',
      overflowY:'scroll',
    },
}));


export default function SimpleTabs(props) {
  const classes = useStyles();

  return (
    <Fragment>
        <Tabs value={props.activeTab} onChange={props.updateActiveTab} aria-label="simple tabs example" className={classes.tabs}>
          <Tab label="Description" {...a11yProps(0)} className={props.activeTab===0?classes.active:classes.notActive}/>
          <Tab label="Preview" {...a11yProps(1)} className={props.activeTab===1?classes.active:classes.notActive}/>
        </Tabs>
        <TabPanel value={props.activeTab} index={0} className={classes.description}>
          <MarkDownEditor 
            problemDescription = {props.problemDescription}
            problemTitle = {props.problemTitle}
            onChange = {props.onChange}
          />
        </TabPanel>
        <TabPanel value={props.activeTab} index={1} className={classes.preview}>
          <MarkDownPreview 
            problemDescription = {props.problemDescription}
            activeProblemTitle = {props.problemTitle}
            activeProblemIndex = {1}
          />
        </TabPanel>
    </Fragment>

  );
}
