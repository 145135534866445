
import axios from 'axios'
import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';

import CalendarHeatmap from 'react-calendar-heatmap';

import {connect} from 'react-redux'
import {getCalendarAttempts} from '../../redux/actions/userActions'

import 'react-calendar-heatmap/dist/styles.css';
import './calendar-style.css'

import themeFile from '../../util/theme';
const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
})

class Calendar extends Component {

    componentDidMount(){
        this.props.getCalendarAttempts()
    }

    shiftDate = (date, numDays)=>{
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + numDays);
        return newDate;
    }

    render() {
        const today = new Date();
        const {calendar_attempts} = this.props;

        return (
            <div style={{
                // backgroundColor:'white',
                paddingRight:'12px',
                // paddingTop:'24p'
            }}>
                <CalendarHeatmap
                    startDate={this.shiftDate(today, -365)}
                    // numDays={31}
                    endDate={today}
                    values={calendar_attempts}
                    classForValue={(value) => {
                        if (!value) {
                            return 'color-empty';
                        } else if (value.count>4) {
                            return `color-github-${4}`;
                        } else {
                            return `color-github-${value.count}`;
                        }
                    }}
                    tooltipDataAttrs={value => {
                        return {
                            'data-tip': value.date!==null? `${
                            value.count} attempt${value.count>1?"s":""} on ${value.date}`: 'no attempts'
                        };
                    }}
                    showWeekdayLabels={true}
                />
                <ReactTooltip />
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    calendar_attempts: state.user.calendar_attempts
});

const mapActionsToProps = {
    getCalendarAttempts : getCalendarAttempts
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(Calendar));
