import React, {Fragment} from 'react';
import { Route, Redirect} from 'react-router-dom';

//redux 
import {connect} from 'react-redux';

import PropTypes from 'prop-types';
import Navbar from '../components/layout/Navbar';
import HomeNavbar from '../components/layout/HomeNavbar';
import Footer from '../components/layout/Footer';

const DynamicLayoutRoute = ({ component: Component, layout, user, ...rest}) => {

    const actualComponent = (
        <Route {...rest} render={
            props =>(
                <Component 
                    authenticated = {user.authenticated} 
                    user = {user}
                    {...props} 
                    {...rest} 
                />
                )
        }/>
    )

    if (rest.componentName == 'Arena') {
        // TODO: find a cleaner way to make sure that the route only comes from /problemsets/<problemset-title>
        let component = rest.location.problemSetTitle? (
          <Component problemSetTitle={rest.location.problemSetTitle} {...rest}/>
      ): ( 
          <Redirect to={`/problemset/${rest.computedMatch.params.title}/`} />
      )
        return component
    }

    
    if (rest.path==='/' && user.authenticated){
        return (
            <Redirect to='/problemsets'/>
        )
    } else {
        switch (layout) {
            case "DEVNAV": {
                return (
                    <Fragment>
                        <Navbar />
                        {actualComponent}
                        <Footer />
                    </Fragment>
                )
            }
            case "HOMENAV": {
                return (
                    <Fragment>
                        <HomeNavbar />
                        {actualComponent}
                        <Footer />
                    </Fragment>
                )
            }
        }
    }
};

DynamicLayoutRoute.propTypes = {
  user: PropTypes.object.isRequired,
}


const mapStateToProps = (state)=>({
    user : state.user
})

const mapActionsToProps = {

}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(DynamicLayoutRoute);