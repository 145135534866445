import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DynamicLayoutRoute from './DynamicLayoutRoute';


import {connect} from 'react-redux';

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
  <Route
    {...rest}
    render={(props) =>
      rest.authenticated === true ? 
      ( 
        <DynamicLayoutRoute 
            component={Component}
            {...rest} 
            {...props}
        />
            // <Component {...rest} {...props}/> 
      ):(
          rest.componentName == "Arena"?(
            <Redirect 
              to = {{
                  pathname: '/login',
                  linkToRouteTo:`/problemset/${rest.computedMatch.params.title}`
              }}
            />
          ):(
            <Redirect 
              to = {{
                  pathname: '/login',
                  linkToRouteTo:`${rest.computedMatch.url}`
              }}
            />
          )
      )
    }
  />
)};

AuthRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
}

export default connect(
  (state)=>({authenticated:state.user.authenticated}),
  {}
)(AuthRoute);