import React, { Component, createRef, Fragment } from 'react';

import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import {Link} from 'react-router-dom';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import themeFile from '../../util/theme';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CountdownCircleTimer } from 'react-countdown-circle-timer'

// components
import ProblemDescription from './ProblemDescripton';
import Editor from './Editor';
import Clock from '../Clock';
import Output from './Output';
import Signup from "./Signup";

// static files
import dontImage from '../../images/warning-dark-red.svg';
import doImage from '../../images/proud-coder-dark-red.svg';


const styles = (theme)=>({
    ...theme.otherStyles,
    root:{
        'overflow':'hidden',
        'background-color':'white'
    },
    firstSection:{
        'display':'flex',
        'text-align':'center',
        'justify-content':'center',
        'flex-direction':'column',
        'padding-top':'80px',
        'padding-bottom':'80px',
        // 'background-color':'white',
        "& button":{
            'border-radius':'0px',
            'font-size':'0.9rem'
        }
    },
    mainSentence:{
        fontWeight:'bold',
        marginBottom:'1rem'
    },
    mainSubtitle:{
        marginBottom:'2rem',
        fontWeight:'bold'
    },
    initialTimer:{
            fontSize:"48px !important",
            marginRight:'4px',
            marginTop:'24px'
    },
    editorControlLayout:{
        // display:'flex',
        justifyContent:'center',
        // alignItems:'center',
        marginBottom:'8px',

        "& button":{
            borderRadius:'0px'
        },
        "& h1":{
            margin:"0px",
            marginRight:'32px'
        },
        "& .MuiOutlinedInput-root":{
            borderRadius:'0px',
            minWdith:120
        }
    },
    progress: {
        position:'absolute',
        // backgroundColor:'white'
    },
    codeEnvironment: {
        justifyContent:'center',
        alignItems:'start',
        height:'80vh',
        maxHeight:'80vh',
        backgroundColor:'white'
    },
    secondSection:{
        'padding-left':'60px',
        'padding-right':'60px',
        'padding-top':'80px',
        'padding-bottom':'80px',
        // 'background-color': 'white',
        'display':'flex',
        'justify-content':'space-between',
        'align-items':'flex-start'
    },
    secondSectionImage:{
        "width":'120%'
    },
    secondSectionImageContainer:{
        'display':'flex',
        // 'align-items':'flex-start',
        // 'justify-content':'center'
    },    
    thirdSectionImageContainer:{
        'display':'flex',
        'align-items':'flex-start',
        // 'justify-content':'center'
    },
    thirdSection:{
        // 'backgroundColor':'#FFE44B',
        // 'backgroundColor': 'white',
        'padding-left':'60px',
        'padding-right':'60px',
        'padding-top':'80px',
        'padding-bottom':'80px',
        'display':'flex',
        'justify-content':'space-between'
    }
})

class Home extends Component {

    constructor(props){
        super();
        this.state = {
            problemDescription:'',
            code:'something importatn',
            countDownTimerValue: 150,
            technologyName:'python',
            showEditor:false,
            // testLog:[
            //     {
            //         status:"Pass",
            //         name:'1st test',
            //         msg:'somehting'
            //     },
            //     {
            //         status:"Pass",
            //         name:'2st test',
            //         msg:'this equals that'
            //     }
            // ],
            testLog:[],
            loading:false,
            activeTab:0,
            attemptStatus:false,
            showSignup:false,
            worthy:true
        };
    }

    defaultPythonCode= `import re

def solution(a:str)->bool:
    # TODO: write your regex pattern inside the quotes below
    your_pattern = r''

    # uncomment this line to view the test inputs
    # print("input = ", a)
    
    # don't have to modify this code
    return re.match(your_pattern, a) is not None
`
    defaultJavaCode = `
//Note that java execution might take a few seconds

import java.util.regex.Pattern;

public class Program {

    //TODO: write your pattern inside the quotes below
    public static final String yourRegexPattern = "";

    private static final Pattern usernamePattern = Pattern.compile(yourRegexPattern);

    public static boolean solution(String s) {
        
        // uncomment this line to view the test inputs
        // System.out.println("input = "+s);

        return usernamePattern
                    .matcher(s)
                    .matches();
    }
}
`

    defaultCode = {
        'python': this.defaultPythonCode,
        'java': this.defaultJavaCode,
    }

    componentDidMount(){
        this.setState({
            code:this.defaultPythonCode
        })
    }

    firstTimerOnComplete = ()=>{
        this.setState({
            showEditor:true
        })
        this.startTimer();
    }

    timeExpired = ()=>{
        this.setState({
            showSignup:true,
            loading:false,
            activeTab:0,
            attemptStatus:false,
            worthy:false,
        })
    }

    handleFormChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value,
        }, ()=>{
            this.setState({
                code:this.defaultCode[this.state.technologyName]
            })
        })
    }

    onSubmitClick = ()=>{
        this.setState({loading:true})
        axios
            .post('/test/initiation/', {
                'solution': this.state.code,
                'technology': this.state.technologyName,
                'problem-link':'regex-isdigit'
            })
            .then((res)=>{
                if (this.state.worthy) {
                    let showSignup = false;
                    if (res.data.success==true) {
                        showSignup = true
                        this.stopTimer()
                    }
                    this.setState({
                        testLog:res.data.test_cases,
                        attemptStatus: res.data.success,
                        loading:false,
                        activeTab:1,
                        showSignup: showSignup
                    })
                }
            })
            .catch((e)=>{
                console.log(e)
                this.setState({
                    testLog:[e]
                })
                this.setState({loading:false})
            })
    }

    codeOnChange = (value)=>{
        this.setState({
            code:value
        })
    }

    render() {
        const {classes, updateSignupSuccess, setAuthorizationHeader, getUserData} = this.props;
        return (
            <div 
                className={classes.root}
                >
                <div className={classes.firstSection}>
                    {/* <Typography variant='h4' className={classes.mainSentence}>Become a better software engineer by solving real-world engineering problems</Typography> */}
                    {/* <Typography variant='h4' className={classes.mainSentence}>Screw time and space complexity</Typography> */}
                    <Typography variant='h5' className={classes.mainSubtitle}>Become a better software engineer by solving real-world problems</Typography>
                    {/* {this.state.showSignup && <Link to='/problemsets'>
                        <Button variant='contained' color='secondary' size='large' className={classes.mainButton}>Get started</Button>
                    </Link>} */}
                </div>
                {this.state.showEditor &&
                    <div><Grid container className={classes.editorControlLayout}>
                        <Grid 
                            container
                            item 
                            sm={10}
                            direction="row"
                            justify='space-between'
                            alignItems="center"
                        >
                            <FormControl 
                                variant='outlined'
                                size='small'
                                className={classes.formControl}
                                >
                                    <Select
                                    labelId = 'demo-sample-select-outlined-label'
                                    name='technologyName'
                                    defaultValue={this.state.technologyName}
                                    value={this.state.technologyName}
                                    onChange={this.handleFormChange}
                                    >
                                    <MenuItem className={classes.menuItem} value={'python'}>Python 3.6</MenuItem>
                                    <MenuItem className={classes.menuItem} value={'java'}>Java 1.8</MenuItem>
                                    </Select>
                            </FormControl>
                            <Clock 
                                time={this.state.countDownTimerValue}
                                setStartTimer={(startTimer)=> this.startTimer=startTimer}
                                setStopTimer={(stopTimer)=> this.stopTimer=stopTimer}
                                setResetTimer={(resetTimer)=> this.resetTimer=resetTimer}
                                setGetTime={(getTime)=> this.getTime=getTime}
                                timeExpired ={this.timeExpired}
                            />
                            <div>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color='primary'
                                    onClick = {this.onSubmitClick}
                                    disabled = {this.state.loading || !this.state.worthy}
                                >
                                    {this.state.loading && (
                                        <CircularProgress size={30} className = {classes.progress}/>
                                        )
                                    }
                                    Submit
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.codeEnvironment}>
                        <Grid item sm={5} style={{
                            height:'100%',
                            width:'100%'
                        }}>
                            {this.state.activeTab===0 && 
                                <ProblemDescription 
                                    problemDescription = {this.state.problemDescription}
                                />
                            }
                            {this.state.activeTab===1 && 
                                <Output 
                                    testLog = {this.state.testLog}
                                    showDescription = {()=>{this.setState({
                                        activeTab : 0
                                    })}}
                                    fail = {this.state.attemptStatus!==true}
                                />
                            }
                        </Grid>
                        <Grid 
                            item 
                            sm={5} 
                            style={{
                                height:'100%',
                                width:'100%'
                                // display:'flex',
                                // alignItems:'center',
                                // justifyContent:'center'
                            }}
                        >
                            {!this.state.showSignup && <Editor 
                                code = {this.state.code}
                                codeOnChange = {this.codeOnChange}
                                mode = {this.state.technologyName}
                            />}
                            {this.state.showSignup &&
                                <Signup 
                                    worthy={this.state.worthy}
                                    updateSignupSuccess = {updateSignupSuccess}
                                    setAuthorizationHeader = {setAuthorizationHeader}
                                    getUserData = {getUserData}
                                />
                            }
                        </Grid>
                    </Grid></div>
                }
                {!this.state.showEditor &&
                    <Grid container justify='center'>
                        <Grid 
                            container 
                            item sm={8} 
                            justify='center'
                            direction="column"
                            alignItems='center'>

                        <Typography variant='h2'>
                            Your initiation begins in

                        </Typography>
                        <div className={classes.initialTimer}>
                            <CountdownCircleTimer
                                    isPlaying
                                    duration={4}
                                    colors={[
                                    ['#004777', 0.33],
                                    ['#F7B801', 0.33],
                                    ['#A30000', 0.33],
                                    ]}
                                    onComplete = {this.firstTimerOnComplete}
                                    size={150}
                                >
                                    {({ remainingTime }) => remainingTime}
                            </CountdownCircleTimer>
                        </div>
                        </Grid>
                    </Grid>
                }

                <Grid container className={classes.secondSection} spacing={1}>
                    <Grid item sm={8} md={6}>
                        <Typography variant='h4' style={{marginBottom:'3rem', fontWeight:'bold'}}>Use this site if</Typography>
                        <Typography variant='h5' style={{marginBottom:'0.5rem', fontWeight:'bold'}}>You want to master software engineering tools</Typography>
                        <Typography variant='body1' style={{marginBottom:'1.5rem', fontSize:'1rem'}}>We have problems that test your knowledge about esoteric software tools that will make you a super-human software developer</Typography>
                        <Typography variant='h5' style={{marginBottom:'0.5rem', fontWeight:'bold'}}>You need a place to practice basic coding everyday</Typography>
                        <Typography variant='body1' style={{marginBottom:'1.5rem', fontSize:'1rem'}}>We have real world self contained problems that most engineers face during development.</Typography>
                        <Typography variant='h5' style={{marginBottom:'0.5rem', fontWeight:'bold'}}>You need to go deep into a particular tool/framework</Typography>
                        <Typography variant='body1' style={{marginBottom:'1.5rem', fontSize:"1rem"}}>We strive to cover almost every aspect of a particular tool, on the ofchance that you are one of those crazies that want to know everything that a tool has to offer.</Typography>
                    </Grid>
                    <Grid item sm={8} md={6} className={classes.secondSectionImageContainer}>
                        <img src={doImage} className={classes.secondSectionImage}></img>
                    </Grid>
                </Grid> 
                <Grid container className={classes.thirdSection} spacing={1}>
                    <Grid item sm={8} md={5} className={classes.thirdSectionImageContainer}>
                        <img src={dontImage} className={classes.secondSectionImage}></img>
                    </Grid>
                    <Grid item sm={8} md={6}>
                        <Typography variant='h4' style={{marginBottom:'3rem', fontWeight:'bold'}}>Do not use this site if</Typography>
                        <Typography variant='h5' style={{marginBottom:'0.5rem', fontWeight:'bold'}}>You want to get good at algorithm interview questions</Typography>
                        <Typography variant='body1' style={{marginBottom:'1.5rem', fontSize:'1rem'}}>This is not the site to practice interview puzzles, there are tons of other great sites that can do that.</Typography>
                        <Typography variant='h5' style={{marginBottom:'0.5rem', fontWeight:'bold'}}>You want to get noticed by recruiters</Typography>
                        <Typography variant='body1' style={{marginBottom:'1.5rem', fontSize:'1rem'}}>We will not share your data with recruiters, and therefore you will probably not get a job just by using this site.</Typography>
                        <Typography variant='h5' style={{marginBottom:'0.5rem', fontWeight:'bold'}}>You want to get good at coding competitions like ICPC or google code jam.</Typography>
                        <Typography variant='body1' style={{marginBottom:'1.5rem', fontSize:"1rem"}}>Not the site or place for that</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Home))