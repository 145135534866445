import React, { Component,Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import AppIcon from '../images/final-logo-black.png';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";

//redux
import {connect} from 'react-redux';
import {getUserData, setAuthorizationHeader} from '../../redux/actions/userActions';


//MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

const styles= (theme)=>({
    ...theme.otherStyles,
    signUpForm:{
        display:"flex",
        alignItems:'center',
        justifyContent:'center',
        height:'100%',
        backgroundColor:'#282a36',
        color:'#f8f8f8',

    },
    signUpFormItem:{
        display:"flex",
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        '& button':{
            borderRadius:'0px'
        },
        "& .MuiFormLabel-root":{
            color:'white',
        }
    },
    textField:{
        width:'100%',
        color:'#f8f8f8 !important',
        marginBottom:'8px',
        // "& input": {
        //     color:"#f8f8f8",
        //     // padding:'8px',
        //     // backgroundColor:'black',
        // },
        '& .MuiOutlinedInput-root': {
            color:'#f8f8f8',
            backgroundColor:'black',
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'green',
            },
        }
        // "& input:": {
        //     color:"#f8f8f8",
        //     padding:'8px',
        //     // backgroundColor:'black',
        // },
        // "& input:placeholder": {
        //     color:"#f8f8f8",
        //     padding:'8px',
        //     // backgroundColor:'black',
        // },
        // "& .MuiInputBase-root":{
        //     // backgroundColor:'black',
        //     color:'white !important'
        // }
    }
});

class Signup extends Component {
    constructor(){
        super();
        this.state = {
            email:'',
            password:'',
            username: '', 
            errors: {},
            loading: false,
        };
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.setState({
            loading: true,
        })
        const newUserData = {
            email: this.state.email,
            password: this.state.password,
            username: this.state.username
        }
        axios
        .post('/rest-auth/signup/', newUserData)
        .then((res)=>{
            //    this.props.history.push('/login')
            this.setState({loading:false})
            this.props.setAuthorizationHeader(res.data.token)
            this.props.getUserData();
            this.props.history.push('/problemsets')
        })
        .catch((e)=> {
            console.log('errors', e)

            let errorData = {}
            if (e.response) {
                errorData = e.response.data
            } else {
                errorData = {
                    "non_field_errors":'something is not right, please try again later'
                }
            }
            this.setState({
                errors:errorData,
                loading:false
            });
        })
    };
    
    handleChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    };    
    
    render() {
        const {classes, worthy} = this.props;
        const {errors, loading} = this.state;
        return (
            <Grid container className = {classes.signUpForm}>
                <Grid item sm={11} className={classes.signUpFormItem}>
                    {!worthy && 
                        <Fragment>
                            <Typography
                                variant='h6'
                                gutterBottom
                            >
                                You are not worthy of joining our clan, please try again
                            </Typography>
                                <Button 
                                    variant='contained' 
                                    color='secondary'
                                    size="small"
                                    onClick = {()=>{window.location.reload()}}
                                    >
                                        Try again    
                                </Button>          
                        </Fragment>
                    }
                    {worthy && <form noValidate onSubmit={this.handleSubmit} className={classes.form}>
                        <Typography
                            variant='h6'
                            gutterBottom
                        >
                                You are worthy of joining our clan
                        </Typography>
                        <TextField 
                            id='email' 
                            name='email' 
                            type='email' 
                            label='Email'
                            size="small"
                            variant='outlined'
                            className={classes.textField}
                            value={this.state.email} 
                            onChange={this.handleChange} 
                            helperText={errors.email}
                            error= {errors.email ? true:false}
                            fullWidth/>
                        <TextField 
                            id='username' 
                            name='username' 
                            type='username' 
                            label='username' 
                            size="small"
                            variant='outlined'
                            className={classes.textField}
                            value={this.state.username} 
                            onChange={this.handleChange} 
                            helperText={errors.username}
                            error= {errors.username ? true:false}
                            fullWidth/> 
                        <TextField 
                            id='password' 
                            name='password' 
                            type='password' 
                            label='password' 
                            size="small"
                            variant='outlined'
                            className={classes.textField}
                            value={this.state.password} 
                            onChange={this.handleChange} 
                            helperText={errors.password}
                            error={errors.password ? true:false}
                            fullWidth/>
                        {errors.non_field_errors && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.non_field_errors}
                            </Typography>
                        )}
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="secondary"
                            disabled={loading} 
                            className = {classes.button}>
                                {loading && (
                                <CircularProgress size={30} className = {classes.progress}/>
                            )}
                            Enlist
                        </Button>
                        <br />
                        <small >
                            Already have an account ? login  
                                <Link 
                                    to='/login'
                                    style={{
                                        color:'aqua'
                                    }}
                                > here
                                </Link>
                        </small>
                    </form>}
                </Grid>
            </Grid>
        );
    }
}

Signup.propTypes = {
    // classes: PropTypes.object.isRequired,
    // user: PropTypes.object.isRequired,
    // UI: PropTypes.object.isRequired,
    // updateSignupSuccess: PropTypes.func.isRequired
};
  
//   const mapStateToProps = (state) => ({
//     user: state.user,
//     UI: state.UI
//   });
  
// export default withStyles(styles)(Signup);

const mapStateToProps=(state)=>({
    user: state.user,
});


const mapActionsToProps= {
    setAuthorizationHeader: setAuthorizationHeader,
    getUserData: getUserData
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withRouter(withStyles(styles)(Signup)));