import React from 'react';

//mui stuff
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    ...theme.otherStyles,
    editorControlButtons:{
        // marginTop:'20px',
        // marginBottom: '4px',
        '& .MuiFormControl-root':{
            // margin: theme.spacing(1),
            margin:'4px',
            borderRadius:'0px'
        },
        '& .MuiInputBase-root':{
            borderRadius:'0px',
            fontSize:'0.79rem'
        },
        '& .MuiSelect-outlined.MuiSelect-outlined':{
            padding:'8px',
            paddingLeft:'12px',
            paddingRight:'24px'
        }
    },
}));


export default function EditorControls(props) {
  const classes = useStyles();

  const {technologies, activeTechnologyId, handleTechnologyChange, theme, keybinding, handleFormChange} = props;

    let getTechnologyVersion = (technology)=> {
        if (technology=='python') {
            return 'Python 3.6'
        } else if (technology=='java') {
            return 'Java 1.8'
        }
    }

    let technologyOptions = technologies.map(
        (technology, id)=>{
        return (
                <MenuItem key={id} value={technology.name}>{getTechnologyVersion(technology.name)}</MenuItem>
        )}
    )

  return (
    <Grid container direction='row' justify='flex-start' alignItems='center' className={classes.editorControlButtons}>
        <FormControl size='small' variant='outlined' style={{'marginLeft':'0px'}}>
            <Select
                name='technology'
                value={technologies[activeTechnologyId]? technologies[activeTechnologyId].name : ''}
                onChange={handleTechnologyChange}
                className={classes.selectEmpty}>
                    {technologyOptions}
            </Select>
        </FormControl>
        <FormControl size='small' variant='outlined'>
            <Select
            name='theme'
            value={theme}
            onChange={handleFormChange}
            >
            <MenuItem value={'monokai'}>Monokai</MenuItem>
            <MenuItem value={'chrome'}>Chrome</MenuItem>
            <MenuItem value={'terminal'}>Terminal</MenuItem>
            </Select>
        </FormControl>
        <FormControl size='small' variant='outlined'>
            <Select
            name='keybinding'
            value={keybinding}
            onChange={handleFormChange}
            >
            <MenuItem value={'sublime'}>Sublime</MenuItem>
            <MenuItem value={'vim'}>Vim</MenuItem>
            </Select>
        </FormControl>
    </Grid>

  );
}
