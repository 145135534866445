import React, { Component, createRef} from 'react'
import AceEditor from "react-ace";
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types'
// import ReactMarkdown from 'react-markdown'

import "ace-builds/src-noconflict/mode-markdown";

// styles
const styles = (theme)=>({
    ...theme.otherStyles,
    problemDescription:{
        height: '100%',
        height:'78vh',
        // overflow:'scroll',
        display:'flex',
        flexDirection:'column',
        padding: '8px 12px',
        // paddingBottom:'5px',
        backgroundColor:'#282a36',
        color:'#f8f8f8'
        // minHeight: '100%'
    },
    problemDescriptionContent:{
        "& ProblemStatement":{
            "margin":"0px",
            "font-size": "0.8571428571428571rem",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-weight": "400",
            "line-height": "1.5",
            "letter-spacing": "0.00938em",
            "margin-bottom": "16px",
        },
        "& p:first-child":{
          'margin-top': '0px'
        },
        "& .problemStatement":{
            "margin":"0px",
            "font-size": "0.8571428571428571rem",
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
            "font-weight": "400",
            "line-height": "1.5",
            "letter-spacing": "0.00938em",
            "margin-bottom": "16px"
        },
        "& code":{
            "font-weight":"bold",
            "font-family":'monaco',
            "font-size": "0.8rem",
            "background-color":'rgb(247, 249, 250)'
        },
        '& h6':{
          "margin":"0px",
          "font-size": "0.8571428571428571rem",
          "font-family": "Roboto, Helvetica, Arial, sans-serif",
          "font-weight": "bold",
          "line-height": "1.5",
          "letter-spacing": "0.00938em",
        },
        '& pre':{ 
            'background-color':'#111',
        //   'background-color': 'rgb(247, 249, 250)', 
          'padding': '2px 4px',
          'margin-top':'0.25rem',
          'line-height': '0px'
        },
        '& div':{
          'white-space':'initial', 
          'line-height':'1.5',
        },
        '& span':{
          'display':'inline', 
          'font-weight':'bold'
        }
    }
});

class ProblemDescription extends Component {

    constructor(){
        super();
        this.aceRef = createRef();
        this.state = {
            language: 'markdown',
            theme:'monokai',
            keybinding:'sublime',
            code:'something improtant',
            loading: false
        }
    }

    componentDidMount(){
        // this.props.setRef(this.aceRef)
    }
    render() {
        const {classes, problemDescription, problemTitle} = this.props;


        let problemDescription1 = `<div class="problemStatement">Write a regex that returns true if given object is a single digit (0-9), false otherwise.</div>
        <h6>Example 1</h6>
        <pre>
                <div><span>Input</span>: ""</div>
                <div><span>Output</span>: False</div>
        </pre>
        <h6>Example 2</h6>
        <pre>
                <div><span>Input</span>: "7"</div>
                <div><span>Output</span>: True</div>
        </pre>
        <h6>Example 3</h6>
        <pre>
                <div><span>Input</span>: " "</div>
                <div><span>Output</span>: False</div>
        </pre>
        <h6>Example 4</h6>
        <pre>
                <div><span>Input</span>: "a"</div>
                <div><span>Output</span>: False</div>
        </pre>
        <h6>Example 5</h6>
        <pre>
                <div><span>Input</span>: "14"</div>
                <div><span>Output</span>: False</div>
        </pre>
`

{/* <h6>Example 5</h6>
<pre>
        <div><span>Input</span>: "a5"</div>
        <div><span>Output</span>: False</div>
</pre> */}
 
         return (
             <div className={classes.problemDescription}>
                 {/* <Typography variant="h6" className={classes.problemTitle}>
                     {1}. {problemTitle}
                 </Typography>
                 <hr className={classes.visibleSeparator} /> */}
                   <div dangerouslySetInnerHTML={{__html:problemDescription1}} className={classes.problemDescriptionContent}>
                   </div>
                   {/* <div className={classes.problemDescriptionContent}>
                     <ReactMarkdown >{problemDescription1}</ReactMarkdown>
                   </div> */}
             </div>
         )
     }
}


ProblemDescription.propTypes = {
    // ref: PropTypes.object.isRequired,
    // keybinding: PropTypes.string.isRequired,
    // mode: PropTypes.string.isRequired,
    // theme:PropTypes.string.isRequired,
    // value:PropTypes.string.isRequired,
}

export default withStyles(styles)(ProblemDescription)
