import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducers';
import dataReducer from './reducers/dataReducers';
// import uiReducer from './reducers/uiReducers';

const initialState = {};


const middleware = [thunk];

const reducers = combineReducers({
    data: dataReducer,
    user: userReducer
});


const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f  
    )
);


export default store;