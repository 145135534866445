import {
    SET_PROBLEMSETS,
  } from '../types';

  import axios from 'axios';
  
  // Get all screams
  export const getProblemSets = () => (dispatch) => {
    axios
    .get('/problemsets/')
    .then((res)=>{
        dispatch({
          type: SET_PROBLEMSETS, 
          payload: res.data 
        })
        // this.setState({
        //     activeProblemSets:res.data['activeProblemSets'],
        //     hotProblemSets:res.data['hotProblemSets'],
        //     allProblemSets:res.data['allProblemSets']
        // })
    })
    .catch((e)=>console.log(e))
  };