import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import RootRef from "@material-ui/core/RootRef";

import axios from 'axios';

//MUI Stuff
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import EditIcon from '@material-ui/icons/Edit';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const styles= (theme)=>({
    ...theme.otherStyles,
    // mainContainer:{
    //     marginTop:'40px',
    //     marginBottom: '40px'
    // },
    formControl:{
        width:'100%'
    },
    problemCard:{
        backgroundColor : theme.palette.action.hover,
        padding:'8px',
        marginTop:'16px'
    },
    problemDetails:{
        marginTop:'12px',
        "& .MuiTypography-body1":{
            fontWeight:'bold',
            fontSize:'0.9rem'
        },
        "& div": {
            paddingTop:'0px !important'
        }
    }
});



// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    primary: `item ${k}`,
    secondary: k % 2 === 0 ? `Whatever for ${k}` : undefined
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const getListStyle = reOrderEnabled => ({
//   background: isDraggingOver ? '#' : 'inherit',
  padding:'16px',
  marginTop:'16px',
//   backgroundColor:'white'
    border: reOrderEnabled? '1px solid black':'none'
});

class Problems extends Component {
    constructor(props){
        super();
        this.state = {
        };
    }

    onDragEnd = (result)=>{
        // dropped outside the list
        if (!result.destination) {
          return;
        }
   
        // const problems = reorder(
        //     this.state.problems,
        //     result.source.index,
        //     result.destination.index
        // )

        const problems = reorder(
          this.props.problems,
          result.source.index,
          result.destination.index
        );
            
        this.props.updateProblems(problems)
      }

    render() {
        const {classes, problems, reOrderEnabled} = this.props;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <RootRef rootRef={provided.innerRef}>
                  <Grid container style={getListStyle(reOrderEnabled)}>
                    {problems.map((problem, index) => (
                      <Draggable key={problem.link} draggableId={problem.link} index={index}>
                        {(provided, snapshot) => (
                                <Grid 
                                    item 
                                    container 
                                    key={index} 
                                    justify='space-between' 
                                    sm={12} 
                                    className={classes.problemCard}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                >
                                    <div style={{
                                        display:'flex',
                                        flexDirection:'column'
                                    }}>
                                        <Typography variant='h6'>
                                            {problem.title}
                                        </Typography>
    
                                        <Grid item container className={classes.problemDetails} spacing={4}>
                                            <Grid item className={classes.testDuration}>
                                                <Typography variant="subtitle2">Total Time</Typography> 
                                                <Typography variant="body1">{problem.time_limit}</Typography> 
                                            </Grid>
                                            <Grid item className={classes.complexityValue}>
                                                <Typography variant="subtitle2">Complxity</Typography> 
                                                <Typography variant="body1">{problem.complexity_value}</Typography> 
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            color='primary'
                                            style={{
                                                borderRadius:'0px'
                                            }}
                                        >
                                            <Link to={{
                                                pathname:`/problemsets/${this.props.match.params.problemsetLink}/problems/${problem.link}/edit/`,
                                                linkToRouteTo:`/problemsets/${this.props.match.params.problemsetLink}/edit`}}
                                                style={{
                                                    color:'inherit',
                                                    display:'flex'
                                                }}
                                            >
                                                <EditIcon style={{fontSize:'0.9rem', marginRight:'4px'}}/> Edit
                                            </Link>
                                        </Button>
                                    </div>
                                </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                </RootRef>
              )}
            </Droppable>
          </DragDropContext>
        );
    }
}

export default withRouter(withStyles(styles)(Problems));