import axios from 'axios';

//react
import React, { Component, Fragment } from 'react'

//matrial ui
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'

//redux
import {connect} from 'react-redux'
import {getCalendarAttempts, getUserData} from '../../redux/actions/userActions'


//compoments
import EditorSection from './EditorSection'
import ProblemDescriptionSection from './ProblemDescriptionSection'



import themeFile from '../../util/theme';
import { ThreeSixty, TimerSharp } from '@material-ui/icons';
const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
})

class AttemptArenaContainer extends Component {

    state = {
        activeTab:0,
        solutionCode:'solution editor code',
        testCode:'test code',
        problemDescription:' someting',
        title:'something important',
        link:'',
        technologies:[{'name':'python', 'solution':''}],
        activeTechnologyId:0,
        loading:false,
        testLog:[]
    }

    componentDidMount(){
        axios
            .get(`problems/daily/technologies/`)
            .then((res)=>{
                this.setState({
                    problemDescription:res.data.problem.description,
                    title : res.data.problem.title, 
                    link: res.data.problem.link,
                    technologies: res.data.problem.technologies,
                    activeTechnologyId:0,
                    solutionCode: res.data.problem.technologies[0].solution,
                    testCode :res.data.problem.technologies[0].basic_tests
                })
            })
            .catch((e)=>{
                console.log(e)
            })
    }

    updateActiveTab= (event, newValue)=>{
        this.setState({activeTab:newValue})
    }

    onSolutionCodeChange = (value)=>{
        this.setState({
            solutionCode: value
        })
    }

    onTestCodeChange = (value)=>{
        console.log('test')
        this.setState({
            testCode: value
        })
    }

    setActiveTechnology = (value)=>{
        this.state.technologies[this.state.activeTechnologyId].solution = this.state.solutionCode;
        this.state.technologies[this.state.activeTechnologyId].basic_tests = this.state.testCode;

        this.setState({
            activeTechnologyId:value,
            solutionCode: this.state.technologies[value].solution,
            testCode : this.state.technologies[value].basic_tests,
            technologies: this.state.technologies
        })
    }

    onSubmitClick = ()=>{
        
        this.setState({
            loading: true,
            activeTab:1,
            testLog:[]
        })

        const data= {
            'solution': this.state.solutionCode,
            'problem_link':this.state.link,
            'technology_name': this.state.technologies[this.state.activeTechnologyId].name
        }

        axios
            .post('attempts/practice/create/', data)
            .then((res)=>{
                this.setState({
                    loading:false,
                    testLog: res.data.test_cases,
                })
                this.props.getCalendarAttempts()
                if (res.data.success){
                    this.props.getUserData()
                }
            })
            .catch((e)=>{
                console.log(e)
                this.setState({
                    loading:false,
                    testLog: [e]
                })
            })
    }

    onTestClick = (e)=>{
        this.setState({
            loading:true,
            activeTab:1,
            testLog:[]
        })

        const data= {
            'solution': this.state.solutionCode,
            'tests': this.state.testCode,
            'technology': this.state.technologies[this.state.activeTechnologyId].name
        }

        axios
            .post('test/', data)
            .then((res)=>{
                this.setState({
                    loading:false,
                    testLog: res.data.test_cases,
                })
            })
            .catch((e)=>{
                console.log(e)
                this.setState({
                    loading:false,
                    testLog: [e]
                })
            })
    }

    render() {
        return (
            <Grid container style={{
                height:'92vh'
            }}>
                <Grid item sm={5} style={{
                    height:'100%'
                }}>
                    <ProblemDescriptionSection 
                        activeTab = {this.state.activeTab}
                        updateActiveTab = {this.updateActiveTab}
                        problemDescription = {this.state.problemDescription}
                        problemTitle = {this.state.title}
                        testLog = {this.state.testLog}
                    />
                </Grid>
                <Grid item sm={7} style={{
                    height:'100%'
                }}>
                    <EditorSection 
                        solutionCode = {this.state.solutionCode}
                        onSolutionCodeChange = {this.onSolutionCodeChange}
                        testCode = {this.state.testCode}
                        onTestCodeChange = {this.onTestCodeChange}
                        technologies = {this.state.technologies}
                        activeTechnologyId = {this.state.activeTechnologyId}
                        setActiveTechnology = {this.setActiveTechnology}
                        onTestClick = {this.onTestClick}
                        onSubmitClick = {this.onSubmitClick}
                        loading = {this.state.loading}
                    />
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    getCalendarAttempts : getCalendarAttempts,
    getUserData : getUserData
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(AttemptArenaContainer));
