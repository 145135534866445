import React, { Component } from 'react'

import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import themeFile from '../../util/theme';
import Grid from '@material-ui/core/Grid';

import useMediaQuery from '@material-ui/core/useMediaQuery';


import PropTypes from 'prop-types';
import ProblemSet from './ProblemSet';

const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,

    problemSetBucket:{
        marginBottom:'40px',
        // overflow:'overlay'
    },

    problemSetCards:{
        // flexWrap:'nowrap',
        // placeContent:'start',
        // justifyContent:'flex-start',
        // display:'flex',
        // alignItems:'center'
    },

    categoryTitle:{
        marginBottom:'12px',
        fontSize:'1.5rem',
        fontWeight:'600',
    },
    categorySubtitle:{
        marginBottom:'40px',
        color: 'rgba(0, 0, 0, 0.5)',
        lineHeight:'1rem'
    },

})

export const withMediaQuery = (queries = []) => Component => props => {
    const mediaProps = {}
    queries.forEach(q => {
      mediaProps[q[0]] = useMediaQuery(q[1])
    })
    return <Component {...mediaProps} {...props} />
}

class ProblemSetBucket extends Component {
    render() {
        const {classes, isDesktop, title, subTitle, problemSets} = this.props
        return (
            <Grid item container xs={11} sm={12} md={10} justify='flex-start' direction='column' alignItems='flex-start' className={classes.problemSetBucket}>
                <Typography variant='h5' className={classes.categoryTitle}>{title}</Typography>
                <Typography variant='h6' className={classes.categorySubtitle}>{subTitle}</Typography>
                <div style={{
                    height:'100%',
                    width:'100%',
                    overflow:'overlay'
                }}>
                    <Grid container style={
                        !isDesktop?(
                            {
                                width:'200vh',
                            }
                        ):(
                            {
                            }
                        )
                        }>
                        {problemSets.map((problemSet, id)=>{
                            return ( 
                                <Grid item key={id} xs={2} sm={2} md={3} style={{
                                    marginBottom:'20px'
                                }}>
                                    <ProblemSet problemSet = {problemSet} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
            </Grid>
        )
    }
}

ProblemSetBucket.propTypes = {
    title : PropTypes.string.isRequired,
    subTitle : PropTypes.string.isRequired,
    problemSets : PropTypes.array.isRequired
}
export default withStyles(styles)(withMediaQuery([
    ['isDesktop', theme => theme.breakpoints.up('md')]
  ])(ProblemSetBucket))
