import React, { Component, createRef} from 'react'
import AceEditor from "react-ace";
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types'

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";

// styles
const styles = (theme)=>({
    ...theme.otherStyles,
    editorLayout:{
        display:'flex',
        flexDirection:'column',
        height:'100%',
        border:'1px solid',
        width:'initial !important',
        // marginTop:'5px'
    },
    aceEditor: {
        height:'100% !important',
        width:'initial !important',
    }
});

class InitialCodeEditor extends Component {

    constructor(){
        super();
        this.aceRef = createRef();
        this.state = {
            language: 'python',
            theme:'monokai',
            keybinding:'sublime',
            loading: false
        }
    }

    componentDidMount(){
        // this.props.setRef(this.aceRef)
    }

    render() {
        const {classes, initialCode, onInitialCodeChange} = this.props;
        return (
            <div className={classes.editorLayout}>
                <AceEditor
                    ref = {this.aceRef}
                    keyboardHandler={this.state.keybinding}
                    mode={this.state.language}
                    theme={this.state.theme}
                    value = {initialCode}
                    onChange={onInitialCodeChange}
                    className = {classes.aceEditor}
                    wrapEnabled={true}
                    // onLoad={editor => {
                    //     editor.once("change", function() {
                    //         editor.session.getUndoManager().reset();
                    //     });
                    //   }}
                />
            </div>
        )
    }
}


InitialCodeEditor.propTypes = {
    // ref: PropTypes.object.isRequired,
    // keybinding: PropTypes.string.isRequired,
    // mode: PropTypes.string.isRequired,
    // theme:PropTypes.string.isRequired,
    // value:PropTypes.string.isRequired,
}

export default withStyles(styles)(InitialCodeEditor)
