import React, { Component, Fragment} from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import AppIcon from '../images/final-logo-black.png';
import {Link} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import axios from 'axios'

//MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//redux stuff
import {connect} from 'react-redux';
import {setAuthorizationHeader, getUserData} from '../redux/actions/userActions';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles= (theme)=>({
    ...theme.otherStyles,
    loginForm:{
        display:"flex",
        alignItems:'center',
        justifyContent:'center'
    },
    loginFormItem:{
        display:"flex",
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }
})

class Login extends Component {
    constructor(props){
        super();
        this.state = {
            email:'',
            password:'',
            errors: {},
            loading: false,
            // signUpSuccess:props.signUpSuccess,
            // emailActivated:props.emailActivated
        };
    }

    // componentWillReceiveProps(nextProps){
    //     if(nextProps.UI.errors) {
    //         this.setState({errors: nextProps.UI.errors});
    //     }
    // }

    // handleOpenSnackbar = () => {
    //     this.setState({signUpSuccess:true});
    // };

    // handleCloseSnackbar = (event, reason) => {
    //     if (reason === 'clickaway') {
    //       return;
    //     }

    //     this.setState({signUpSuccess:false})
    // };

    // handleCloseSnackbarEmail = (event, reason) => {
    //     if (reason === 'clickaway') {
    //       return;
    //     }

    //     this.setState({emailActivated:false})
    // };

    handleSubmit = (event)=>{
        event.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        }
        this.setState({
            loading:true,
            errors:{}
        })
        axios
            .post('/rest-auth/login/', userData)
            .then((res)=>{
                this.props.setAuthorizationHeader(res.data.token)
                this.setState({loading:false})

                if (this.props.location && this.props.location.linkToRouteTo) {
                    this.props.getUserData(this.props.location.linkToRouteTo)
                } else {
                    this.props.getUserData();
                    this.props.history.push('/problemsets')
                }
            })
            .catch((e)=> {
                console.log(e)
                var data = {'non_field_errors':'something is not right, please try in a few mins later'}
                if (e.response){
                    data = e.response.data
                }
                this.setState({
                    errors:data,
                    loading:false
                });
            })
        // this.props.loginUser(userData, this.props.history);
    };
    
    handleChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    };    
    
    render() {
        const {classes } = this.props;
        const {errors, loading } = this.state;
        return (
            <Fragment>
                <Grid container className = {classes.loginForm}>
                    <Grid item sm={4} className={classes.loginFormItem}>
                        <img src={AppIcon} alt='monkey.png' className={classes.appIcon}/>
                        <Typography variant="h2" className = {classes.pageTitle}> 
                            Login
                        </Typography>
                        <form noValidate onSubmit={this.handleSubmit} className={classes.form}>
                            <TextField 
                                id='email' 
                                name='email' 
                                type='email' 
                                label='email' 
                                className={classes.textField}
                                value={this.state.email} 
                                onChange={this.handleChange} 
                                helperText={errors.email}
                                error= {errors.email? true:false}
                                fullWidth/>
                            <TextField 
                                id='password' 
                                name='password' 
                                type='password' 
                                label='password' 
                                className={classes.textField}
                                value={this.state.password} 
                                onChange={this.handleChange} 
                                helperText={errors.password}
                                error={errors.password? true:false}
                                fullWidth/>
                            {errors.non_field_errors && (
                                <Typography variant="body2" className={classes.customError}>
                                    {errors.non_field_errors}
                                </Typography>
                            )}
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                disabled={loading} 
                                className = {classes.button}>
                                    {loading && (
                                    <CircularProgress size={30} className = {classes.progress}/>
                                )}
                                Login
                            </Button>
                            <br />
                            <small>no account signup <Link to='/signup'>here</Link></small>
                        </form>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }
}

Login.propTypes = {
    setAuthorizationHeader: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired,
};

const mapStateToProps=(state)=>({
    user: state.user,
});


const mapActionsToProps= {
    setAuthorizationHeader: setAuthorizationHeader,
    getUserData: getUserData
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(Login));
