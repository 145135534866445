import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

import axios from 'axios';

import Button from "@material-ui/core/Button";

//MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import CircularProgress from '@material-ui/core/CircularProgress';
import NewProblemSetDialog from '../NewProblemSet/NewProblemSetDialog';
import MarkdownTabs from './ProblemDescriptionEditors/MarkdownTabs';

import SolutionEditorTabs from './SolutionEditors/SolutionEditorTabs';
import TestEditorTabs from './TestEditors/TestEditorTabs';


import ConfirmationDialog from '../../util/ConfirmationDialog';

const styles= (theme)=>({
    ...theme.otherStyles,
    mainDiv:{
        // display:'flex',
        // alignItems:'center',
        // justifyContent:'center',
        // // padding:'40px 40px',
    },
    topButtonLayer:{
        marginTop:'40px',
        marginBottom:'40px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        // paddingLeft:'20px',
        '& button':{
            borderRadius: 0,
            marginRight : theme.spacing(1)
        }
    },
    progress: {
        position:'absolute'
    },
    mainProblemDefinition:{
        padding:'10px'
    },
    basicProblemInfo:{
        padding:'10px',
    },
    formControl:{
        width:'100%'
    }, 
    multiSelectForm:{
        marginTop:theme.spacing(1),
        width:'100%',
        '& label':{
            fontSize:'inherit'
        }
    },
    newProblemSetButton:{
        borderRadius:'0px',
        marginTop: theme.spacing(1),
        borderColor:'green',
        color:'green'
    },

    technologyButtons:{
        marginTop:'40px',
        // backgroundColor:'green'
        padding: '10px'
    },

    leftTechnologyButtons:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'flex-end',
        '& .MuiFormControl-root':{
            width:'33%'
        },
        "& button": {
            marginLeft:theme.spacing(1),
            borderRadius:'0px',
        }
    },

    rightTechnologyButtons:{
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'flex-end',
        '& button':{
            borderRadius:'0px'
        }
    },

    editors:{
        padding:'10px'
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewProblem extends Component {

    constructor(props){
        super();
        this.state = {
            title:'',
            complexityValue:'Level 1',
            errors:{},
            selectedProblemSets:['str'],
            allProblemSets:[],
            problemSetDialogOpen:false,
            problemDescription:'nothing important',
            timeLimit:'',
            markDownActiveTab:0,
            technologyName:'python',
            solutionActiveTab:0,
            solutionCode:'somehting',
            initialCode:'another thing',
            basicTestCode:'basic test',
            stressTestCode:'stress Test',
            testActiveTab: 0,
            loading:false, 
            testLog : [
                {
                    'msg': 'somethign',
                    'name':'name',
                    'status':'success',
                    'stdout':'stdout'
                }
            ],
            technologies:[
                {
                    'name':'python',
                    'solution':'solution',
                    'default_code':'default_code',
                    'basic_tests':'basic_test_code',
                    'stress_tests':'stress_tests'
                }
            ],
            saveSuccess:false,
            showSaveConfirmationDialog:false,
            showDeleteConfirmationDialog:false,
        };
    }


    setInitialState = ()=>{
        let problemLink = this.props.match.params.problemLink;
        let technology = this.props.match.params.technology;
        let problemSetLink = this.props.match.params.problemSetLink;

        this.setState({
            loading:true
        })

        if (problemLink){
            axios
            .get(`/problemsets/authored/links/`)
            .then((res)=>{
                let psIndex = res.data.allProblemSets.indexOf(problemSetLink);

                if (psIndex == -1){
                    this.props.history.push('/users/dashboard')
                }
                this.setState({allProblemSets:res.data.allProblemSets})
            })
            .catch((err)=>console.log(err))

            axios
            .get(`/problems/${problemLink}/technologies/`)
            .then((res)=>{
                let problem = res.data.problem;
                this.setState({
                    title : problem.title,
                    complexityValue : problem.complexity_value,
                    selectedProblemSets : problem.problem_sets,
                    problemDescription : problem.description,
                    timeLimit : problem.time_limit,
                    technologies : problem.technologies,
                    solutionCode : problem.technologies[0].solution,
                    initialCode : problem.technologies[0].default_code,
                    basicTestCode : problem.technologies[0].basic_tests,
                    stressTestCode : problem.technologies[0].stress_tests,
                    loading:false,
                })
            })
            .catch((err)=>{
                
                console.log(err)

                this.setState({
                    loading:false
                })
            
            })

        } else {
            axios
            .get(`/problemsets/authored/links/`)
            .then((res)=>{
                let psIndex = res.data.allProblemSets.indexOf(problemSetLink);

                if (psIndex == -1){
                    this.props.history.push('/users/dashboard')
                }

                this.setState({
                    allProblemSets:res.data.allProblemSets,
                    loading:false,
                    selectedProblemSets:[problemSetLink]
                })
            })
            .catch((err)=>{
                this.setState({
                    loading:false
                })
            })
        }
    }

    componentDidMount(){
        this.setInitialState();
    }

    handleCloseSnackbar = ()=>{
        let username = this.props.user.username;

        this.setState({
            saveSuccess:false
        })

        if (this.props.location && this.props.location.linkToRouteTo) {
            this.props.history.push(this.props.location.linkToRouteTo)
        } else {
            this.props.history.push(`/users/dashboard/`)
        }

    }

    onSaveClick = ()=>{
        this.setState({
            showSaveConfirmationDialog:true
        })
    }

    onSaveConfirmationClick = ()=>{
        this.setState({
            loading:true
        })
        let problemLink = this.props.match.params.problemLink;

        if (problemLink) {
            var newProblemData = {
                'title': this.state.title,
                'description': this.state.problemDescription,
                'complexity_value':this.state.complexityValue,
                'time_limit':this.state.timeLimit,
                'problemsets': this.state.selectedProblemSets,
            }

            var technologyData = {
                'name': this.state.technologyName,
                'solution': this.state.solutionCode,
                'default_code': this.state.initialCode,
                'basic_tests': this.state.basicTestCode,
                'stress_tests': this.state.stressTestCode
            }


            axios
                .put(
                    `/problems/${problemLink}/update/`, 
                    newProblemData
                )
                .then((res)=>{
                    let newProblemLink = res.data.problem_link;
                    axios.put(
                        `/problems/${newProblemLink}/technologies/${this.state.technologyName}/update/`,
                        technologyData
                    )
                    .then((res)=>{
                        this.setState({
                            loading:false,
                            saveSuccess:true,
                            showSaveConfirmationDialog:false,
                        })
                    })
                    .catch((e)=>{
                        var data = {'non_field_errors':'something is not right, please try in a few mins later'}
                        if (e.response){
                            if (e.response.status != 500){
                                data = e.response.data
                            }
                        }
                        this.setState({
                            errors:data,
                            loading:false,
                            showSaveConfirmationDialog:false,
                        });
                    })
                    
                })
                .catch((e)=>{
                    var data = {'non_field_errors':'something is not right, please try in a few mins later'}
                    if (e.response){
                        if (e.response.status != 500){
                            data = e.response.data
                        }
                    }
                    this.setState({
                        errors:data,
                        loading:false,
                        showSaveConfirmationDialog:false
                    });
                })

        } else {
            var newProblemData = {
                'title': this.state.title,
                'description': this.state.problemDescription,
                'complexity_value':this.state.complexityValue,
                'time_limit':this.state.timeLimit,
                'problemsets': this.state.selectedProblemSets,
            }

            axios
                .post(
                    `/problems/create/`, 
                    newProblemData
                )
                .then((res)=>{
                    var newTechnologyData = {
                        'name': this.state.technologyName,
                        'solution': this.state.solutionCode,
                        'default_code': this.state.initialCode,
                        'basic_tests': this.state.basicTestCode,
                        'stress_tests': this.state.stressTestCode
                    }
                    
                    axios.post(`/problems/${res.data.link}/technologies/create/`, newTechnologyData)
                    .then((res)=>{
                        this.setState({
                            loading:false,
                            saveSuccess:true,
                            showSaveConfirmationDialog:false,
                        })
                    })
                    .catch((e)=>{
                        console.log(e.response.data)
                        this.setState({
                            errors:e.response.data,
                            loading:false,
                            showSaveConfirmationDialog:false
                        })
                    })
                })
                .catch((e)=>{
                    var data = {'non_field_errors':'something is not right, please try in a few mins later'}
                    if (e.response){
                        if (e.response.status != 500){
                            data = e.response.data
                            console.log(data)
                        }
                    }
                    this.setState({
                        errors:data,
                        loading:false,
                        showSaveConfirmationDialog:false,
                    });
                })
        }
    }

    onDeleteClick = ()=>{
        this.setState({
            showDeleteConfirmationDialog:true
        })
    }

    onDeleteConfirmationClick = ()=>{
        this.setState({
            loading:true
        })
        let problemLink = this.props.match.params.problemLink;

        axios
            .delete(`problems/${problemLink}/delete/`)
            .then((res)=>{
                this.setState({
                    loading:false,
                    saveSuccess:true,
                    showDeleteConfirmationDialog:false,
                })
            })
            .catch((e)=>{
                console.log(e.response.data)
                this.setState({
                    loading:false,
                    errors:e.response.data,
                    showDeleteConfirmationDialog:false,
                })
            })
    }

    updateProblemSets = (allProblemSets)=>{
        this.setState({allProblemSets:allProblemSets})
    }

    handleTextFieldChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }; 
    
    handleFormChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        this.setState({selectedProblemSets:value});
    };

    handleDialogOpen = ()=>{
        this.setState({
            problemSetDialogOpen:true
        })
    }

    handleDialogClose = ()=>{
        this.setState({
            problemSetDialogOpen:false
        })
    }

    updateMarkDownActiveTab = (event,newValue) =>{
        this.setState({markDownActiveTab:newValue})
    }

    markDownEditorOnChange=(value)=>{
        this.setState({problemDescription:value});
    }

    handleTechnologyChange = (e)=>{
        const tech_ind = this.state.technologies.findIndex((t)=>{
            return t.name==e.target.value;
        })

        this.setState({
            technologyName : this.state.technologies[tech_ind].name,
            solutionCode : this.state.technologies[tech_ind].solution,
            initialCode : this.state.technologies[tech_ind].default_code,
            basicTestCode : this.state.technologies[tech_ind].basic_tests,
            stressTestCode : this.state.technologies[tech_ind].stress_tests
        })
    }

    getTechnologyVersion = (technology)=> {
        if (technology=='python') {
            return 'Python 3.6'
        } else if (technology=='java') {
            return 'Java 1.8'
        }
    }

    onInsertExampleClick= ()=>{
        var initialCode = `def solution(a):\n\t#write your code here`

        var basicTests = `from .basic_test_runner import *
class TestBasic(TestRunner):
    def test_correctness(self):
        # change the inputs to assert_equals

        self.assert_equals(solution("the cat jumped over the car"), False)
        self.assert_equals(solution("fat tony is a realist"), True)
        self.assert_equals(solution("this sentence does not include any of the above words"), False)
        self.assert_equals(solution("cat got fat, now all it does is sit on the mat"), True)`

        var stressTests = `from .basic_test_runner import *
class TestStress(TestRunner):
    def test_correctness(self):
        # change the inputs to assert_equals

        self.assert_equals(solution("the cat jumped over the car"), False)
        self.assert_equals(solution("fat tony is a realist"), True)
        self.assert_equals(solution("this sentence does not include any of the above words"), False)
        self.assert_equals(solution("cat got fat, now all it does is sit on the mat"), True)`

        this.setState({
            initialCode:initialCode,
            solutionCode:initialCode,
            basicTestCode:basicTests,
            stressTestCode: stressTests
        })
        
    }

    onBasicTestClick = ()=>{
        this.setState({loading:true})
        axios
            .post('/test/', {
                'solution': this.state.solutionCode,
                'tests': this.state.basicTestCode,
                'technology': this.state.technologyName
            })
            .then((res)=>{
                console.log(res.data)
                this.setState({
                    testLog:res.data.test_cases,
                    loading:false,
                    solutionActiveTab:2
                })
            })
            .catch((e)=>{
                console.log(e)
                // this.props.updateTestLog([e])
                this.setState({loading:false})
            })
    }

    onStressTestClick = ()=>{
        this.setState({loading:true})
        axios
            .post('/test/', {
                'solution': this.state.solutionCode,
                'tests': this.state.stressTestCode,
                'technology': this.state.technologyName
            })
            .then((res)=>{
                console.log(res.data)
                this.setState({
                    testLog:res.data.test_cases,
                    loading:false,
                    solutionActiveTab:2
                })
            })
            .catch((e)=>{
                console.log(e)
                // this.props.updateTestLog([e])
                this.setState({loading:false})
            })
    }

    solutionCodeOnChange = (value)=>{
        this.setState({solutionCode:value})
    }

    initialCodeOnChange = (value)=>{
        this.setState({initialCode:value})
    }

    updateSolutionActiveTab = (event,newValue) =>{
        this.setState({solutionActiveTab:newValue})
    }

    basicTestCodeOnChange = (value)=>{
        this.setState({basicTestCode:value})
    }

    stressTestCodeOnChange = (value)=>{
        this.setState({stressTestCode:value})
    }

    updateTestActiveTab = (event,newValue) =>{
        this.setState({testActiveTab:newValue})
    }

    render() {
        const {classes} = this.props;

        return (
        <div className={classes.mainDiv}>

            <Snackbar open={this.state.saveSuccess} autoHideDuration={1000} onClose={this.handleCloseSnackbar}>
                <Alert className={classes.SuccessMessage} onClose={this.handleCloseSnackbar} severity="success">
                <Typography variant='body1'>Update successful, routing you to the dashboard in 1 sec</Typography>
                </Alert>
            </Snackbar>

            <ConfirmationDialog 
                dialogOpen={this.state.showSaveConfirmationDialog}
                title = {`Save confirmation`}
                question = {`This will save the problem, are you sure?`}
                cancelCallback = {()=>{this.setState({showSaveConfirmationDialog:false})}}
                yesCallback = {this.onSaveConfirmationClick}
            />

            <ConfirmationDialog 
                dialogOpen={this.state.showDeleteConfirmationDialog}
                title = {`Delete confirmation`}
                question = {`This will delete the problem permanently, are you sure?`}
                cancelCallback = {()=>{this.setState({showDeleteConfirmationDialog:false})}}
                yesCallback = {this.onDeleteConfirmationClick}
            />

            <div className={classes.topButtonLayer}>
                <Button 
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick = {this.onSaveClick}
                    disabled ={this.state.loading}
                >
                    {this.state.loading && (
                        <CircularProgress size={30} className = {classes.progress}/>
                        )
                    }
                    Save
                </Button>
                <Button 
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick = {this.onDeleteClick}
                    disabled = {this.state.loading}
                >   
                    {this.state.loading && (
                        <CircularProgress size={30} className = {classes.progress}/>
                        )
                    }        
                    Delete
                </Button>
            </div>
            {this.state.errors.non_field_errors && <Typography variant='h6' style={{
                textAlign:'center',
                color:'red'
            }}>{this.state.errors.non_field_errors}</Typography>}
            <Grid container className={classes.mainProblemDefinition}>
                <Grid item sm={6} className={classes.basicProblemInfo}>
                    <TextField 
                            id='title' 
                            name='title' 
                            type='text' 
                            label='Title' 
                            className={classes.textField}
                            value={this.state.title} 
                            onChange={this.handleTextFieldChange} 
                            helperText={this.state.errors.title}
                            error= {this.state.errors.title? true:false}
                            fullWidth
                    />
                    <FormControl 
                        size='small' 
                        className={classes.formControl}
                        error = {this.state.errors.complexity_value? true:false}
                    >
                        <InputLabel id="demo-simple-select-label">Complexity</InputLabel>
                        <Select
                        labelId = 'demo-sample-select-label'
                        name='complexityValue'
                        defaultValue={this.state.complexityValue}
                        value={this.state.complexityValue}
                        onChange={this.handleFormChange}
                        >
                        <MenuItem className={classes.menuItem} value={'LEVEL1'}>Level 1</MenuItem>
                        <MenuItem className={classes.menuItem} value={'LEVEL2'}>Level 2</MenuItem>
                        </Select>
                        <FormHelperText>{this.state.errors.complexity_value}</FormHelperText>
                    </FormControl>
                    <TextField 
                            id='timeLimit' 
                            name='timeLimit' 
                            type='text' 
                            label='Time Limit' 
                            className={classes.textField}
                            value={this.state.timeLimit} 
                            onChange={this.handleTextFieldChange} 
                            helperText={this.state.errors.time_limit}
                            error= {this.state.errors.time_limit? true:false}
                            fullWidth
                    />
                    <FormControl 
                        className={classes.multiSelectForm}
                        error={this.state.errors.problemsets?true:false}    
                    >
                        <InputLabel shrink htmlFor="select-multiple-native" >Select ProblemSet</InputLabel>
                        <Select
                        multiple
                        native
                        value={this.state.selectedProblemSets}
                        onChange={this.handleChangeMultiple}
                        inputProps={{
                            id: 'select-multiple-native',
                        }}
                        >
                        {this.state.allProblemSets.map((name) => (
                            <option key={name} value={name}>
                            {name}
                            </option>
                        ))}
                        </Select>
                        <FormHelperText>{this.state.errors.problemsets}</FormHelperText>
                    </FormControl>
                    <Button 
                        size='small' 
                        variant='outlined'
                        className={classes.newProblemSetButton}
                        onClick = {this.handleDialogOpen}
                    >
                        Add a new problemset
                    </Button>
                    <NewProblemSetDialog 
                        problemSetDialogOpen = {this.state.problemSetDialogOpen}
                        handleDialogClose = {this.handleDialogClose}
                        updateProblemSets = {this.updateProblemSets}
                    />
                </Grid>
                <Grid item sm={6} style={{
                    height:'60vh'
                }}>
                    <MarkdownTabs 
                        problemDescription = {this.state.problemDescription}
                        activeTab = {this.state.markDownActiveTab}
                        updateActiveTab = {this.updateMarkDownActiveTab}
                        problemTitle = {this.state.title}
                        onChange = {this.markDownEditorOnChange}
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.technologyButtons}>
                <Grid item sm={6} className={classes.leftTechnologyButtons}>
                        <FormControl size='small'>
                                <InputLabel id="demo-simple-select-label">Technology</InputLabel>
                                <Select
                                labelId = 'demo-sample-select-label'
                                name='technologyName'
                                defaultValue={this.state.technologies[0].name}
                                value={this.state.technologyName}
                                onChange={this.handleTechnologyChange}
                                >
                                {this.state.technologies.map((technology) => (
                                    <MenuItem 
                                        className={classes.menuItem} 
                                        value={technology.name}
                                    >
                                        {this.getTechnologyVersion(technology.name)}
                                    </MenuItem>
                                ))}

                                {/* <MenuItem className={classes.menuItem} value={'java'}>Java 1.8</MenuItem> */}
                                </Select>
                        </FormControl>
                    {!this.props.match.params.problemLink && 
                        <Button
                            size="small"
                            variant="outlined"
                            color='primary'
                            onClick = {this.onInsertExampleClick}
                        >Add example code</Button>
                    }
                </Grid>

                <Grid item sm={6} className={classes.rightTechnologyButtons}>
                    <Button
                        size="small"
                        variant="outlined"
                        style={{color:'green', borderColor:'green', marginRight:'8px'}}
                        onClick = {this.onBasicTestClick}
                        disabled ={this.state.loading}
                    >
                        {this.state.loading && (
                            <CircularProgress size={30} className = {classes.progress}/>
                            )
                        }
                        Run Basic Tests
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        style={{color:'green', borderColor:'green'}}
                        onClick = {this.onStressTestClick}
                        disabled ={this.state.loading}
                    >
                        {this.state.loading && (
                            <CircularProgress size={30} className = {classes.progress}/>
                            )
                        }
                        Run Stress Tests
                    </Button>
                </Grid>

            </Grid>

            <Grid container className={classes.editors}>
                <Grid item sm={6} className={classes.solutionEditors}>
                    <SolutionEditorTabs
                        solutionCode = {this.state.solutionCode}
                        initialCode = {this.state.initialCode}
                        solutionCodeOnChange = {this.solutionCodeOnChange}
                        initialCodeOnChange = {this.initialCodeOnChange}
                        activeTab = {this.state.solutionActiveTab}
                        updateActiveTab = {this.updateSolutionActiveTab}
                        testLog = {this.state.testLog}
                    />
                </Grid>

                <Grid item sm={6} className={classes.testEditors}>
                    <TestEditorTabs
                        basicTestCode = {this.state.basicTestCode}
                        basicTestCodeOnChange = {this.basicTestCodeOnChange}
                        stressTestCode = {this.state.stressTestCode}
                        stressTestCodeOnChange = {this.stressTestCodeOnChange}
                        activeTab = {this.state.testActiveTab}
                        updateActiveTab = {this.updateTestActiveTab}
                    />
                </Grid>

            </Grid>
        </div>
        );
    }
}

export default withStyles(styles)(NewProblem);