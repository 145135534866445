import React, { Component, Fragment } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
    ...theme.otherStyles,
    solution:{
        // overflow:'scroll',
        display:'flex',
        flexDirection:'column',
        padding: '10px 10px',
        backgroundColor:'white',
        minHeight:'100%',
        // alignItems:'center'
    },
    buttonWrapper:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginTop:theme.spacing(1),
        '& svg':{
            fontSize:'0.9rem'
        }
    },
    dialogTitle:{
        '& h2':{
            fontWeight:'bold',
        }
    },
    dialogButtons:{
        '& button':{
            borderRadius:'0px'
        }
    }
  });


class ConfirmationDialog extends Component {

    constructor(){
        super();
        this.state = {
            dialogOpen:false,
        }
    }

    handleCloseDialog = (e) => {
    }

    render() {
        const {classes, dialogOpen, title, question, cancelCallback, yesCallback} = this.props;

        return (
            <Dialog
                open={dialogOpen}
                onClose={cancelCallback}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle 
                    id="alert-dialog-title"
                    className={classes.dialogTitle}
                >
                    {title}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {question}
                </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogButtons}>
                        <Button variant='outlined' size='small' value='cancel' onClick={cancelCallback} color="primary">
                        Cancel
                        </Button>
                        <Button variant='contained' size='small' value='showSolution' onClick={yesCallback} color="primary" autoFocus>
                        Yes, do it already !!!
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ConfirmationDialog.propTypes = {
    dialogOpen : PropTypes.bool.isRequired,
    title : PropTypes.string.isRequired,
    question : PropTypes.string.isRequired,
    cancelCallback : PropTypes.func.isRequired,
    yesCallback : PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfirmationDialog)