import React, { Component } from 'react'

import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import {Link} from 'react-router-dom';
import themeFile from '../../util/theme';

import PropTypes from 'prop-types';

const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
    cardOuter:{
        width:'min-content',
        marginBottom:'20px',
        marginRight:'10px',
    },
    cardMedia:{
        position:'relative',
        backgroundColor:theme.palette.secondary.dark
    },
    cardContent:{
        padding:'20px',
    },
    cardTitle:{
        fontWeight:'bold',
        marginBottom:'20px',
        paddingTop:'40px',
        paddingBottom:'40px',
        textTransform:'capitalize',
        position:'absolute',
        top:'0px',
        // backgroundColor:'red'
        color:'white'
    },
    problemSetInfoDesktop: {
        display: 'flex',
        alignItems:'center',
        justifyContent:'start',
        "& .MuiTypography-body1":{
            fontWeight:'bold',
            fontSize:'0.9rem'
        },
        "& .MuiTypography-subtitle2":{
            // fontWeight:'bold',
            // fontSize:'0.9rem'
        },
    },
    testDuration:{
        marginRight:theme.spacing(3),
      },
    totalQuestions:{
        marginRight:theme.spacing(3)
    },

})

class ProblemSet extends Component {
    render() {
        const {classes, problemSet} = this.props
        return (
            <Card className={classes.cardOuter} variant="outlined">
                <CardActionArea component={Link} to={`problemset/${problemSet.link}`} className={classes.cardActionArea}>
                    <CardMedia
                        component="img"
                        height='140'
                        className={classes.cardMedia}
                    />
                    <CardContent>
                        <Typography variant='h5' className={classes.cardTitle}>{problemSet.title}</Typography>
                        <div className={classes.problemSetInfoDesktop}>
                            <div className={classes.testDuration}>
                            <Typography variant="subtitle2">Total Time</Typography> 
                            <Typography variant="body1">{problemSet.totalTime.split(':').slice(1,).join(':')} mins</Typography> 
                            </div>
                            <div className={classes.totalQuestions}>
                            <Typography variant="subtitle2">Total Problems</Typography> 
                            <Typography variant="body1">{problemSet.numberOfProblems}</Typography> 
                            </div>
                            <div className={classes.difficultLevel}>
                            <Typography variant="subtitle2">Difficulty</Typography> 
                            <Typography variant="body1">{problemSet.difficultyLevel}</Typography> 
                            </div>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
}

ProblemSet.propTypes = {
    problemSet : PropTypes.object.isRequired
}

export default withStyles(styles)(ProblemSet)
