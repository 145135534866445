import React, { Component, createRef} from 'react'
import AceEditor from "react-ace";
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types'

import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-python";

// styles
const styles = (theme)=>({
    ...theme.otherStyles,
    editorLayout:{
        display:'flex',
        flexDirection:'column',
        height:'60%',
        border:'1px solid',
        width:'initial !important',
        marginTop:'5px'
    },
    testEditorHeading:{
        fontWeight:'bold',
        padding:'8px 5px',
        borderBottom:'1px solid'
    },
    aceEditor: {
        height:'100% !important',
        width:'initial !important',
    }
});

class TestEditor extends Component {

    constructor(){
        super();
        this.testRef = createRef();
        this.state = {
            language: 'javascript',
            theme:'github',
            keybinding:'sublime',
            tests: '',
            loading: false
        }
    }

    componentDidMount(){
        this.props.setRef(this.testRef)
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.editorLayout}>
                <Typography variant='subtitle2' className={classes.testEditorHeading}>Sample Tests</Typography>
                 <AceEditor
                    ref = {this.testRef}
                    onChange={this.props.testEditorOnChange}
                    keyboardHandler= {this.props.keybinding}
                    mode = {this.props.mode}
                    theme={this.props.theme}
                    value = {this.props.value}
                    className = {classes.aceEditor}
                    wrapEnabled = {true}
                />
            </div>
        )
    }
}


TestEditor.propTypes = {
    // ref: PropTypes.object.isRequired,
    keybinding: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    theme:PropTypes.string.isRequired,
    value:PropTypes.string.isRequired,
}

export default withStyles(styles)(TestEditor)
