import React, { Component, createRef, Fragment } from 'react'
import { withRouter } from "react-router";

import PropTypes from 'prop-types'

//matrial ui
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography"

//compoments
import EditorControls from './EditorControls'
import SolutionEditor from '../NewProblem/SolutionEditors/SolutionEditor'

import themeFile from '../../util/theme';
import AuthButton from '../../util/AuthButton';

const theme = createMuiTheme(themeFile);


const styles = (theme)=>({
    ...theme.otherStyles,
    root:{
        '& button':{
            borderRadius:'0px'
        }
    },
    progressBar: {
        position:'absolute'
    },
})

class EditorSection extends Component {

    constructor(){
        super();
        this.state = {
            theme:'terminal',
            keybinding:'sublime',
            testEditorOpened:false,
        }
    }


    handleTechnologyChange=(e)=>{
        const tech_ind = this.props.technologies.findIndex((t)=>{
            return t.name==e.target.value;
        })
        this.props.setActiveTechnology(tech_ind);
    }

    handleFormChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {

        const {classes, solutionCode, onSolutionCodeChange, testCode, onTestCodeChange, technologies, activeTechnologyId, loading, onSubmitClick, onTestClick} = this.props

        return (
            <Grid container className={classes.root} style={{
                width:'100%',
                height:'100%',
            }}>

                    <EditorControls 
                        activeTechnologyId = {activeTechnologyId}
                        technologies = {technologies}
                        handleTechnologyChange = {this.handleTechnologyChange}
                        theme = {this.state.theme}
                        keybinding = {this.state.keybinding}
                        handleFormChange={this.handleFormChange}
                    />
                <div style={(!this.state.testEditorOpened ? {
                            width:'100%',
                            height:'87%',
                            overflow: 'hidden'
                }:{
                    width:'100%',
                    height:'54%',            
                })}>
                    <SolutionEditor 
                        solutionCode = {solutionCode} 
                        onSolutionChange = {onSolutionCodeChange}
                        keybinding = {this.state.keybinding}
                        language = {technologies[activeTechnologyId]?technologies[activeTechnologyId].name:''}
                        theme = {this.state.theme}
                    />
                </div>

                {this.state.testEditorOpened && 
                    // TODO: make sure the editor is a generic thing used throughout the website
                    (<Fragment>
                        <Typography variant='subtitle2' style={{
                                    fontWeight:'bold',
                                    padding:'8px 5px',
                                    borderBottom:'1px solid',
                        }}>Sample Tests</Typography>
                    <div style={{
                            width:'100%',
                            height:'26%',
                    }}>
                        <SolutionEditor 
                            solutionCode = {testCode}
                            onSolutionChange = {onTestCodeChange}
                            keybinding = {this.state.keybinding}
                            language = {technologies[activeTechnologyId]?technologies[activeTechnologyId].name:''}
                            theme = {this.state.theme}
                        />
                        </div>
                    </Fragment>)
                }
                <Grid container direction='row' justify='space-between' alignItems='center' style={{
                    margin:'8px 0px'
                }}>
                    <div>
                        <Button 
                            size='medium'
                            variant='outlined'
                            onClick={
                                ()=>{this.setState({testEditorOpened:!this.state.testEditorOpened})}
                            } 
                            style={{
                                'marginLeft': '0px', 
                                'marginBottom':'0px'
                            }}
                        >
                            Test Cases
                            {this.state.testEditorOpened? (
                                <ArrowDropDownIcon fontSize="small" />
                            ): (
                                <ArrowDropUpIcon fontSize="small" />
                            )}
                        </Button>
                    </div>
                    <div>
                        <Button 
                            size='medium'
                            variant='outlined' 
                            color='primary' 
                            onClick={onTestClick} 
                            style={{marginRight:'8px'}}
                            disabled={loading}
                            >
                            {loading && (
                                <CircularProgress size={24} className={classes.progressBar}/>
                            )}
                            Test
                            
                        </Button>
                        <AuthButton
                            size='medium'
                            variant='contained' 
                            color='primary' 
                            disabled = {loading}
                            onClick = {onSubmitClick}
                        >{loading && (
                            <CircularProgress size={24} className={classes.progressBar}/>
                        )}
                        Submit
                        </AuthButton>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

EditorSection.propTypes = {
    solutionCode: PropTypes.string.isRequired,
    onSolutionCodeChange: PropTypes.func.isRequired,
    testCode: PropTypes.string.isRequired,
    onTestCodeChange: PropTypes.func.isRequired,
    technologies: PropTypes.array.isRequired,
    activeTechnologyId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
    onTestClick: PropTypes.func.isRequired,
}

export default withRouter(withStyles(styles)(EditorSection))
