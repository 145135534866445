import React, { Component } from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import themeFile from '../../util/theme';

//Redux
import { connect } from 'react-redux';
import { getProblemSets } from '../../redux/actions/dataActions';


//components
import ProblemSetBucket from './ProblemSetBucket';

const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
    root:{
        marginTop:'60px',
        padding:'20px 10px',
        paddingLeft:'60px',
        justifyContent:'center',
        display:'flex',
        alignItems:'flex-start',
        flexDirection:'column'
    },
})

class ProblemSets extends Component {
    
    constructor(){
        super();
        this.state = {}
    }
    
    componentDidMount(){
        this.props.getProblemSets();
    }
    
    render() {
        const {classes, problemSets: {activeProblemSets, hotProblemSets, allProblemSets}} = this.props
        return (
            <Grid container className={classes.root}>
                    {activeProblemSets
                    .length>0 && (
                        <ProblemSetBucket 
                            title={'Active ProblemSets'}
                            subTitle = {'Problemsets that you have attempted recently'}
                            problemSets = {activeProblemSets}
                        />
                    )}
                    
                    {hotProblemSets.length>0 &&
                        <ProblemSetBucket 
                            title = {'Hot ProblemSets'}
                            subTitle = {'Problemsets that other users have attempted the most'}
                            problemSets = {hotProblemSets}
                        />
                    }

                    {allProblemSets.length>0 && 
                        <ProblemSetBucket 
                            title = {'All ProblemSets'}
                            subTitle = {''}
                            problemSets = {allProblemSets}
                        />
                    }
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    problemSets: state.data.problemSets
});

const mapActionsToProps = {
    getProblemSets: getProblemSets
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(ProblemSets));