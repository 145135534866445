import React, { Component} from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import themeFile from '../util/theme'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import axios from 'axios';
const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
    gridContainer:{
        'justify-content':'center',
        height:'100%'
    },
    gridItem:{
        padding:'10px'
    },
    tableContainer:{
        marginTop:'40px',
        marginBottom: '20px',
        padding:'10px',
        paddingTop:'20px',
        width:'auto'
        
    },
    table:{
        // minWidth: 650,
    },
    root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
})

  function EnhancedTableHead(props) {
    const { headCells, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    let notOrder = (order)=>{
      if (order=='asc'){
        return 'desc'
      } else  {
        return 'asc'
      }
    }
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? (order=='asc'?'desc':'asc') : ('desc')}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant='subtitle2' style={{fontWeight:'bold'}}>{headCell.label}</Typography>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


class Leaderboard extends Component {
    constructor(){
        super();
        this.state={
            orderBy:'',
            order: '',
            users: [
                {
                    'rank': 1,
                    'username': '',
                    'problemsSolved': 0,
                    'numberOfSessions': 0,
                    'pointsGained': 0
                }
            ],
            headCells : [
                { id: 'rank', numeric: true, disablePadding: false, label: 'rank' },
                { id: 'username', numeric: false, disablePadding: true, label: 'username' },
                { id: 'problemsSolved', numeric: true, disablePadding: true, label: 'problems solved' },
                { id: 'numberOfSessions', numeric: true, disablePadding: true, label: 'number of sessions' },
                { id: 'pointsGained', numeric: true, disablePadding: true, label: 'points gained' },
            ],
        }
    }

    
    componentDidMount(){
        axios
            .get('leaders/')
            .then((res)=>{
                var newUsers = res.data.leaders.map((user, id)=>{
                    var newUser = {}
                    newUser['rank'] = id+1
                    newUser['username'] = user['username']
                    newUser['problemsSolved'] = user['problems_solved']
                    newUser['numberOfSessions'] = user['sessions']
                    newUser['pointsGained'] = user['points_gained']
                    return newUser
                })
                this.setState({users:newUsers})
            })
            .catch((e)=>console.log(e))
    }

    descendingComparator= (a, b, orderBy)=> {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
      getComparator = (order, orderBy)=>{
        return order === 'desc'
          ? (a, b) => this.descendingComparator(a, b, orderBy)
          : (a, b) => -this.descendingComparator(a, b, orderBy);
      }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({order: isAsc? 'desc': 'asc'})
        this.setState({orderBy:property})
    }

    stableSort = (array, comparator) =>{
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }

    render() {
        const {classes} = this.props
        return (
            <div style={{
              minHeight:'64vh'
            }}>
                <Grid container className={classes.gridContainer}>
                    <Grid item lg={8} sm={10} className={classes.gridItem}>
                        {/* <Card className={classes.root} variant="outlined"> */}
                            {/* <CardContent> */}
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <EnhancedTableHead
                                        headCells= {this.state.headCells}
                                        classes={classes}
                                        order = {this.state.order}
                                        orderBy = {this.state.orderBy}
                                        onRequestSort = {this.handleRequestSort}
                                        rowCount = {this.state.users.length}
                                    />
                                    {/* <TableHead>
                                    <TableRow>
                                        <TableCell>rank</TableCell>
                                        <TableCell align="left">Username</TableCell>
                                        <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                            >
                                            problemsSolved
                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                            </TableSortLabel>
                                        </TableCell>

                                        <TableCell align="right">numberOfSessions</TableCell>
                                        <TableCell align="right">pointsGained</TableCell>
                                    </TableRow>
                                    </TableHead> */}
                                    <TableBody>
                                    { this.stableSort(this.state.users, this.getComparator(this.state.order, this.state.orderBy))
                                        .map((row) => (
                                        <TableRow key={row.rank} className={classes.root}>
                                            <TableCell  align='right'><Typography variant='body2'>{row.rank}</Typography></TableCell>
                                            <TableCell align="left"><Typography variant='body2'>{row.username} </Typography></TableCell>
                                            <TableCell align="right"><Typography variant='body2'>{row.problemsSolved}</Typography></TableCell>
                                            <TableCell align="right"><Typography variant='body2'>{row.numberOfSessions}</Typography></TableCell>
                                            <TableCell align="right"><Typography variant='body2'>{row.pointsGained}</Typography></TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                               </TableContainer>
                            {/* </CardContent> */}
                        {/* </Card> */}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Leaderboard)