import React, { Component, createRef} from 'react'
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Editor from './Editor';
import ProblemStatementTabs from './ProblemStatementTabs';
import Button from '@material-ui/core/Button';
import Clock from './Clock';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import TemporaryDrawer from './TempProblemDrawer'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
    ...theme.otherStyles,
    arena:{
        marginTop:'-48px',
        height:'100vh',
        // maxHeight:'100vh',
        // overflow:'scroll',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
    },
    dialogButtons:{
        justifyContent:'center',
        // display:'flex',
        // textAlign:'center',
        '& button':{
            borderRadius:'0px'
        }
    },
    editorLayout: {
        padding: '10px 10px',
        height:'100%',
        // height: '100vh',
    },
    countDownTimer: {
        textAlign:'center',
        fontWeight: 'bold',
        // fontSize: '26.4',
        marginTop:'48px',
        "& h1":{
            'margin-bottom':'0px',
            'font-size': '26.4px'
        }
    },
    timers:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        // marginTop:'68px',
        // marginBottom: '20px',
    },
    problemStatementTabs: {
        height:'100%',
        display:'flex',
        flexDirection:"column",
        '& button':{
            borderRadius:'0px'
        }
    },
    editor: {
        height:'100%',
        '& button':{
            borderRadius:'0px'
        }
    },
    bothEditors:{
        height:'100%',
    }
  })

class Arena extends Component {
    constructor(){
        super();
        this.state = {
            switchingErrorNotice: false,
            drawerOpen:false,
            testLog:[], 
            problemStatmentActiveTab:0,
            activeProblemId:0,
            activeTechnologyId:0,
            activeProblem:
                {
                    'tests':''
                },
            session: {
                'status':"",
                'id':'',
                'timeRemaining':'',
            },
            problems:[{
                'id':-1,
                'title':'',
                'description':'<p>nothing yet</p>',
                'link':'',
                'time_limit':'00:20:30',
                'show_solution':false,
                'technologies':[
                    {
                        'name':'python',
                        'user_solution': '',
                        'tests': '',
                        'default_code':'',
                        'expected_solution':''
                    }
                ]
            }],
            countDownTimerValue:0,
            dialogOpen:false
        }
    }
    
    parseTime = (timeValue)=> {
        var hms = timeValue;   // your input string
        var a = hms.split(':'); // split it at the colons
        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
        return seconds
    }

    formatTime= (time)=>{
        //TODO: see if we can resolve the hours issue
        let seconds = time % 60;
        let minutes = Math.floor(time / 60);

        // let hours = Math.floor(time/3600);
        minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
        seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
        return '0:'+ minutes + ':' + seconds;
    }

    setInitialState(session, old=false){
        console.log('session', session) 
        var newSession={}

        newSession.id = session.session_id
        newSession.status = session.status
        newSession.timeRemaining = session.time_remaining

        var newActiveProblemId = session.active_problem_id;
        var newActiveTechnologyId = 0;
        var time_remaining = '0:0:0'

        var problems = [... session.problems]

        // problems.map((id, problem)=>{
        //     if(problem.status == 'in_progress') {
        //         newActiveProblemId = id;
        //     }
        // });

        this.setState({
            session:newSession,
            problems:problems,
            activeProblemId:newActiveProblemId,
            activeTechnologyId:newActiveTechnologyId,
            drawerOpen:true,
            countDownTimerValue:this.parseTime(session.time_remaining)
        })

        // I only need this so that the innerhtml is correctly rendered
        this.setState({drawerOpen:false})
        this.updateCodeInEditor(session.problems[newActiveProblemId].technologies[newActiveTechnologyId].user_solution)
        this.updateCodeInTestEditor(session.problems[newActiveProblemId].technologies[newActiveTechnologyId].tests)
        this.startTimer();
    }

    componentDidMount(){
        var problemsetId = this.props.problemSetTitle;

        axios
        .get(`/start-new-session/${problemsetId}/`)
        .then((res)=>{
            if (res.data.status==='FINISHED') {
                this.setState({dialogOpen:true})
                localStorage.removeItem('problemSetTitle')
            } else {
                this.setInitialState(res.data)
            }
        })
        .catch((err)=>console.log(err))
    }


    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        this.setState({switchingErrorNotice:false})
    };

    updateTestLog = (newTestLog=null)=>{
        this.setState({testLog:newTestLog})
    }

    updateProblemStatementActiveTab = (event,newValue) =>{
        this.setState({problemStatmentActiveTab:newValue})
    }

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        this.setState({drawerOpen : open });
    };


    fetchExpectedSolution = ()=>{
        var activeProblemLink = this.state.problems[this.state.activeProblemId].link

        axios
            .put(`attempt/get-solutions/${this.state.session.id}/${activeProblemLink}/`)
            .then((res)=>{
                var problems = this.state.problems;
                res.data.technologies.map((technology, id)=>{
                    problems[this.state.activeProblemId].technologies[id].expected_solution = technology.expected_solution;
                });
                problems[this.state.activeProblemId].show_solution = true;
                if (problems[this.state.activeProblemId].status=='in_progress') {
                    problems[this.state.activeProblemId].status = 'failure';
                }
                
                this.setState({
                    problems:problems,
                })
                this.stopTimer();
            })
            .catch((e)=>{
                console.log('error', e);
            })

    }

    setActiveTechnology=(technologyId)=>{
        //save old state before switching
        const codeInEditor = this.getCodeInEditor();
        const codeInTestEditor = this.getCodeInTestEditor();
        var currActiveProblem = this.state.problems[this.state.activeProblemId];
        currActiveProblem.technologies[this.state.activeTechnologyId]['user_solution'] = codeInEditor;

        if (codeInTestEditor) {
            currActiveProblem.technologies[this.state.activeTechnologyId]['tests'] = codeInTestEditor;
        }

        let problems = [...this.state.problems];
        problems[this.state.activeProblemId] = currActiveProblem;

        this.setState(
            {
                problems : problems,
                activeTechnologyId: technologyId,
            });
        
        var newActiveTechnology = this.state.problems[this.state.activeProblemId].technologies[technologyId]
        this.updateProblemStatementActiveTab(null, 0)
        this.updateCodeInEditor(newActiveTechnology['user_solution'])
        this.updateCodeInTestEditor(newActiveTechnology['tests']) 
    }

    setActiveProblem=(problemId, problemStatus='valid')=>{
        if (problemStatus=='invalid' || problemStatus=='not_started') {
            console.log('inside invlaied state');
            this.setState({switchingErrorNotice:true});
            return;
        }
        //save old state before switching
        const codeInEditor = this.getCodeInEditor();
        const codeInTestEditor = this.getCodeInTestEditor();
        var currActiveProblem = this.state.problems[this.state.activeProblemId];

        // save old state
        currActiveProblem.technologies[this.state.activeTechnologyId].user_solution = codeInEditor;

        if (codeInTestEditor) {
            currActiveProblem.technologies[this.state.activeTechnologyId]['tests'] = codeInTestEditor;
        }

        let problems = [...this.state.problems];
        problems[this.state.activeProblemId] = currActiveProblem;

        var activeTechnologyId=0;
        if(this.state.activeTechnologyId>=0 && this.state.activeTechnologyId<this.state.problems[problemId].technologies.length){
            activeTechnologyId = this.state.activeTechnologyId;
        }
       
        // reset the timer 
        if (this.state.problems[problemId].status=='not_started'){
            var problemLink = this.state.problems[problemId].link
            // the timer should already be stopped
            this.stopTimer();
            axios
                .post(`/create-attempt/${this.state.session.id}/${problemLink}/`)
                .then((res)=>{
                    console.log('attempt id', res.data.attemptId, 'created', problems[problemId].time_limit)
                    problems[problemId].status = 'in_progress';
                    this.setState({
                        countDownTimerValue:this.parseTime(problems[problemId].time_limit)
                    }, ()=>{
                        console.log('timer value', this.state.countDownTimerValue)
                        this.resetTimer();
                        this.startTimer();
                    })
                })
                .catch((e)=>{console.log(e)})
        }

        this.setState(
            {
                problems : problems,
                activeProblemId: problemId,
                activeTechnologyId: activeTechnologyId,
            });

        
        // Populate the new editor
        var newActiveProblemContent = this.state.problems[problemId].technologies[activeTechnologyId]
        this.updateProblemStatementActiveTab(null, 0)
        this.updateCodeInEditor(newActiveProblemContent['user_solution'])
        this.updateCodeInTestEditor(newActiveProblemContent['tests'])
    }

    updateProblemStatus = ()=>{
        //update value in the current active problem
        // var currActiveProblem = this.state.problems[this.state.activeProblemId];
        // currActiveProblem['status'] ='success';
        // currActiveProblem.technologies[this.state.activeTechnologyId]['solution'] = solution;
        //update value in the problem list
        let problems = [...this.state.problems]
        if (problems[this.state.activeProblemId]['status']=='in_progress') {
            problems[this.state.activeProblemId]['status'] = 'success';
            this.stopTimer();
        }

        this.setState({
            problems: problems,
        })
    }

    timeExpired = ()=>{
        if (this.state.activeProblemId == this.state.problems.length-1) {
            this.stopTimer();
            this.setState({
                dialogOpen:true
            })

            localStorage.removeItem('problemSetTitle')
            axios
            .get(`end-session/${this.state.session.id}/`)
            .then((res)=>{
                // just checking but there is no way an end timer even is happening while the attempt is success
                if (this.state.problems[this.state.activeProblemId].status=='in_progress') {
                    this.state.problems[this.state.activeProblemId].status = 'failure';
                }
                this.setState({problems:this.state.problems})
            })
            .catch((e)=>{
                console.log(e)
            })
        } else {
            this.stopTimer();
            axios
            .post(`end-attempt/${this.state.session.id}/${this.state.problems[this.state.activeProblemId].link}/`, {})
            .then((res)=>{
                this.state.problems[this.state.activeProblemId].status = res.data.status
                this.setState({problems:this.state.problems})
            })
            .catch((e)=>{
                console.log(e)
            })
        }

    }

    handleDialogClose = ()=>{
        this.setState({
            dialogOpen:false
        })
    }

    handleEndSession = ()=>{
        this.setState({
            countDownTimerValue:this.parseTime('0:00:00'),
            dialogOpen:true,
            
        })
        this.stopTimer();
        localStorage.removeItem('problemSetTitle')
        axios
        .get(`end-session/${this.state.session.id}/`)
        .then((res)=>{
            var problems = this.state.problems;
            if (problems[this.state.activeProblemId].status=='in_progress') {
                problems[this.state.activeProblemId].status = 'failure';
            }
            this.setState({
                problems: problems
            })
        })
        .catch((e)=>{
            console.log(e)
        })
    }

    saveCurrentState=()=>{
        localStorage.setItem('arenaState', JSON.stringify(this.state));
    }
   
    componentWillUnmount(){
        // this.setActiveProblem(this.state.activeProblem.id)
        // let problems = [... this.state.problems]
        this.stopTimer(); 
        // problems[this.state.activeProblem.id]['time_limit'] = timerValue 
        // localStorage.setItem('sessionId', this.props.sessionId)
        // localStorage.setItem('problems', JSON.stringify(this.state.problems))
    }

    render(){
        const {classes} = this.props

        return (
            <div className={classes.arena}>
                {/* <VerticalTabs /> */}
                {/* <Stepper /> */}
                {/* <ProblemDrawer 
                    open={this.state.open}
                    handleOpenDrawer = {this.handleDrawerOpen.bind(this)}
                    handleDrawerClose = {this.handleDrawerClose}
                />  */}
                <Snackbar open={this.state.switchingErrorNotice} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
                    <Alert className={classes.SuccessMessage} onClose={this.handleCloseSnackbar} severity="error">
                    <Typography variant='body1'>Cannot perform switching. All of the previous problems have to be attemped before.</Typography>
                    </Alert>
                </Snackbar>
                
                <TemporaryDrawer 
                    drawerOpen = {this.state.drawerOpen}
                    toggleDrawer = {this.toggleDrawer.bind(this)} 
                    problems = {this.state.problems}
                    setActiveProblem={this.setActiveProblem.bind(this)}
                />

                {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                        Open alert dialog
                    </Button> */}
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Your session has ended"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your session has ended, you can go to your dashboard, view the leaderboard, checkout other problemsets, or stay here and play with the problems.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogButtons}>
                        {/* <Link to='/dashboard' >
                            <Button variant='contained' onClick={this.handleDialogClose} color="primary">
                            Dashboard
                            </Button>
                        </Link> */}
                        <Link to='/leaderboard'>
                            <Button variant='contained' onClick={this.handleDialogClose} color="primary" autoFocus>
                            Leaderboard
                            </Button>
                        </Link>
                        <Link to='/problemsets'>
                            <Button variant='contained' onClick={this.handleDialogClose} color="primary" autoFocus>
                            Other ProblemSets 
                            </Button>
                        </Link>
                            <Button variant='contained' onClick={this.handleDialogClose} color="secondary" autoFocus>
                                Stay here
                            </Button>
                    </DialogActions>
                </Dialog>

                <Grid container className={classes.timers}>
                    <Grid item sm={6} className={classes.countDownTimer} >
                        <Clock 
                            // time={320}
                            time={this.state.countDownTimerValue}
                            setStartTimer={(startTimer)=> this.startTimer=startTimer}
                            setStopTimer={(stopTimer)=> this.stopTimer=stopTimer}
                            setResetTimer={(resetTimer)=> this.resetTimer=resetTimer}
                            setGetTime={(getTime)=> this.getTime=getTime}
                            timeExpired ={this.timeExpired}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.editorLayout} >
                    <Grid item sm={5} xs={12} className={classes.problemStatementTabs}>
                        <ProblemStatementTabs 
                            activeProblem = {this.state.problems[this.state.activeProblemId]}
                            activeProblemId = {this.state.activeProblemId}
                            testLog={this.state.testLog} 
                            activeTab={this.state.problemStatmentActiveTab}
                            updateActiveTab={this.updateProblemStatementActiveTab.bind(this)}     
                            toggleDrawer={this.toggleDrawer.bind(this)}
                            setActiveProblem={this.setActiveProblem.bind(this)}
                            totalProblems = {this.state.problems.length}
                            fetchExpectedSolution = {this.fetchExpectedSolution}
                            showSolution = {this.state.problems[this.state.activeProblemId].show_solution}
                            expectedSolution = {this.state.problems[this.state.activeProblemId].technologies[this.state.activeTechnologyId].expected_solution}
                        />
                    </Grid>
                    <Grid item sm={7} xs={12} className={classes.editor}>
                        {/* <div className={classes.bothEditors}> */}
                            <Editor 
                                setGetCodeInEditor={(getCodeInEditor)=> this.getCodeInEditor=getCodeInEditor}
                                setGetCodeInTestEditor={(getCodeInTestEditor)=> this.getCodeInTestEditor=getCodeInTestEditor}
                                setUpdateCodeInEditor={(updateCodeInEditor)=> this.updateCodeInEditor=updateCodeInEditor}
                                setUpdateCodeInTestEditor={(updateCodeInTestEditor)=> this.updateCodeInTestEditor=updateCodeInTestEditor}
                                formatTime = {this.formatTime}
                                updateProblemStatus = {this.updateProblemStatus}
                                getTime = {this.getTime}
                                updateTestLog={this.updateTestLog.bind(this)} 
                                updateActiveTab={this.updateProblemStatementActiveTab.bind(this)}
                                activeProblemLink = {this.state.problems[this.state.activeProblemId].link}
                                activeProblemTests = {this.state.problems[this.state.activeProblemId].technologies[this.state.activeTechnologyId]? this.state.problems[this.state.activeProblemId].technologies[this.state.activeTechnologyId].tests: this.state.problems[this.state.activeProblemId].technologies[0].tests}
                                activeProblemCode = {this.state.problems[this.state.activeProblemId].technologies[this.state.activeTechnologyId]?this.state.problems[this.state.activeProblemId].technologies[this.state.activeTechnologyId].user_solution:this.state.problems[this.state.activeProblemId].technologies[0].user_solution}
                                technologies = {this.state.problems[this.state.activeProblemId].technologies}
                                handleEndSession = {this.handleEndSession}
                                setActiveTechnology = {this.setActiveTechnology}
                                activeTechnologyId = {this.state.activeTechnologyId}
                            />
                            {/* <TestEditor /> */}
                        {/* </div> */}
                        
                    </Grid>
                </Grid>

                {/* <AppBar position="static">
                    <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        News
                    </Typography>
                    <Button color="inherit">Login</Button>
                    </Toolbar>
                </AppBar> */}

            </div>

        )
    }
}

Arena.propTypes = {
    // problems: PropTypes.object.isRequired
}

export default withStyles(styles)(Arena)