import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import SolutionEditor from './SolutionEditor';
import InitialCodeEditor from './InitialCodeEditor';
import Output from './Output';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
   //marginBottom: "10px",
    minHeight:'18px',
    height:'35px',
   '& .MuiButtonBase-root': {
        // fontSize:'0.8571428571428571rem'
        fontSize:'0.7rem',
        padding:'6px 6px'
    },
    '& .MuiTab-root':{
      minHeight:'35px'
    }

  },
  active:{
      backgroundColor: '#fff',
      //border: 'none',
      //borderBottom: '2px solid grey'
      minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
  notActive:{
      backgroundColor: 'transparent',
      //border: '1px solid grey',
      //borderTop: 'none'
     minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
    actualSolution: {
        height:'60vh',
        // maxHeight:'80vh',
        // backgroundColor: '#fff',
        overflowY:'scroll',
    },
    initialCode: {
      height:'60vh',
      // backgroundColor: '#fff',
      overflowY:'scroll',
    },
}));


export default function SimpleTabs(props) {
  const classes = useStyles();
  
  const {updateActiveTab, activeTab, solutionCode, solutionCodeOnChange, initialCode, initialCodeOnChange, testLog} = props;

  return (
    <Fragment>
        <Tabs value={activeTab} onChange={updateActiveTab} aria-label="simple tabs example" className={classes.tabs}>
          <Tab label="Actual Solution" {...a11yProps(0)} className={activeTab===0?classes.active:classes.notActive}/>
          <Tab label="Initial Code" {...a11yProps(1)} className={activeTab===1?classes.active:classes.notActive}/>
          <Tab label="Output" {...a11yProps(1)} className={activeTab===1?classes.active:classes.notActive}/>
        </Tabs>
        <TabPanel value={activeTab} index={0} className={classes.actualSolution}>
          <SolutionEditor
            solutionCode = {solutionCode} 
            onSolutionChange = {solutionCodeOnChange}
            keybinding = {'sublime'}
            language = {'python'}
            theme = {'monokai'}
          />

        </TabPanel>
        <TabPanel value={activeTab} index={1} className={classes.initialCode}>
          <InitialCodeEditor
            initialCode = {initialCode}
            onInitialCodeChange = {initialCodeOnChange}
          />

        </TabPanel>
        <TabPanel value={activeTab} index={2} className={classes.initialCode}>
          <Output
            testLog = {testLog}
          />

        </TabPanel>
    </Fragment>

  );
}
