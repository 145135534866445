import React, { Component, createRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Logo from '../../images/final-logo-white-1.png';
import UserImage from '../../images/ninja.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Grid from '@material-ui/core/Grid';
import { withRouter } from "react-router";
import themeFile from '../../util/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
    loginLink: {
      marginRight: theme.spacing(2),
    },
    toolbarDesktop:{
      display:'flex',
      alignItems:'center',
      justifyContent:"center",
      '& button':{
        borderRadius:'0px'
      },
      maxHeight:'45px',
      minHeight:'45px',
      // width:'100%',
    },    
    toolbarMobile:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:"center",
      '& button':{
        borderRadius:'0px'
      },
      height:'max-content',
      // maxHeight:'100px',
      // minHeight:'100px',
      flexWrap:'wrap',
      boxSizing:'border-box',
      paddingBottom:'20px'
    },
    leftNavbar:{
      display:'flex',
      alignItems:'center',
      justifyContent:"flex-start"
    },
    logo: {
      maxWidth: '50px',
      maxHeight: '50px',
      marginRight: theme.spacing(1),
    },
    centerNavbar:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    developersActive: {
        "padding-top": "13px",
        "padding-bottom": "10px",
        "border-bottom": "3px solid",
        "border-bottom-color":theme.palette.secondary.main,
        "padding-left": "8px",
        "padding-right": "8px",
        "color": theme.palette.secondary.main,
        "& span":{
            "font-weight":'bold'
        }
    },
    developers: {
        "padding-top": "13px",
        "padding-bottom": "10px",
        "padding-left": "8px",
        "padding-right": "8px",
        "color": '#eee',
        "& span":{
            "font-weight":'bold'
        }
    },
    companiesActive: {
        "padding-top": "13px",
        "padding-bottom": "10px",
        "border-bottom": "3px solid",
        "border-bottom-color":theme.palette.secondary.main,
        "padding-left": "8px",
        "padding-right": "8px",
        "color": theme.palette.secondary.main,
        "& span":{
            "font-weight":'bold'
        }
    },
    companies: {
        "padding-top": "13px",
        "padding-bottom": "10px",
        "padding-left": "8px",
        "padding-right": "8px",
        "color":'#eee',
        "& span":{
            "font-weight":'bold'
        }
    },
    researchersActive: {
        "padding-top": "13px",
        "padding-bottom": "10px",
        "border-bottom": "3px solid",
        "border-bottom-color":theme.palette.secondary.main,
        "padding-left": "8px",
        "padding-right": "8px",
        "color": theme.palette.secondary.main,
        "& span":{
            "font-weight":'bold'
        }
    },
    researchers:{
        "padding-top": "13px",
        "padding-bottom": "10px",
        "padding-left": "8px",
        "padding-right": "8px",
        "color": '#eee',
        "& span":{
            "font-weight":'bold'
        }
    },
    rightNavbar:{
      display:"flex",
      alignItems:'center',
      justifyContent:'flex-end'
    },
    rightNavbarMobile:{
      display:'flex',
      alignItems:'center',
      justifyContent:'flex-end',
      marginTop:'10px'
    },
    userImage:{
      maxWidth: '35px',
      maxHeight: '30px',
      borderRadius:'3px',
      marginRight: theme.spacing(1)
    },
    userName:{
      color:theme.palette.secondary.main
    },
    points: {
      // fontFamily:'serif',
      color:"white",
      marginLeft:theme.spacing(1)
    }
})

export const withMediaQuery = (queries = []) => Component => props => {
  const mediaProps = {}
  queries.forEach(q => {
    mediaProps[q[0]] = useMediaQuery(q[1])
  })
  return <Component {...mediaProps} {...props} />
}

class HomeNavbar extends Component {

    render() {
        const {classes,activeLink='developers', isDesktop=false} = this.props;
        return (
            <AppBar position="static">
            <Toolbar variant='dense' className={isDesktop? classes.toolbarDesktop: classes.toolbarMobile}>
              <Grid item sm={2} className = {classes.leftNavbar}>
                <Link to='/'>
                  <img src={Logo} alt="Codedojo" className={classes.logo} />
                </Link>
              </Grid>
              <Grid item sm={8} className = {classes.centerNavbar}>
                <Link to="/" className={window.location.pathname==='/'?classes.developersActive:classes.developers}>
                  <Typography variant='button' >
                    Developers
                  </Typography>
                </Link>
                {/* <Link to="#" className={classes.companies} >
                  <Typography variant='button' >
                    Companies
                  </Typography>
                </Link>
                <Link to="#" className={classes.companies}>
                  <Typography variant='button' >
                    Instructors
                  </Typography>
                </Link> */}
                <Link to="/research" className={window.location.pathname==='/research'?classes.developersActive:classes.developers}>
                  <Typography variant='button' >
                    Researchers
                  </Typography>
                </Link>
              </Grid>
              <Grid item sm={2} className = {isDesktop?classes.rightNavbar: classes.rightNavbarMobile}>
                <Link to="/login" className={classes.loginLink}>
                    <Button 
                    variant='contained' 
                    color='primary'
                    size="small"
                    >
                        Log In
                    </Button>          
                </Link>
                <Link to="/signup">
                    <Button 
                    variant='contained' 
                    color='secondary'
                    size="small"
                    >
                        Sign Up
                    </Button>
                </Link>
              </Grid>
            </Toolbar>
          </AppBar>
        )
    }
}
// export default withStyles(styles)(ProblemSet)
export default withStyles(styles)(withMediaQuery([
  ['isDesktop', theme => theme.breakpoints.up('sm')]
])(HomeNavbar))