import {
    SET_USER,
    SET_UNAUTHENTICATED,
    SET_CALENDAR_ATTEMPTS
} from '../types';

import axios from 'axios';

const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));


// export const loginUser = (userData, history) => (dispatch) => {
//     axios
//         .post('/login', userData)
//         .then((res) => {
//             setAuthorizationHeader(res.data.token);
//             dispatch(getUserData());
//             history.push('/problemsets');
//         })
//         .catch((err) => {
//             dispatch({
//                 type: SET_ERRORS,
//                 payload: err.response.data
//             });
//         });
// };

export const getUserData = (routeLocation=null) => (dispatch) => {
    axios
    .get('/rest-auth/get-profile-info')
    .then((res) => {
        dispatch({
            type: SET_USER,
            payload: res.data
        });

       if (routeLocation) {
            window.location.href = routeLocation;
       }
    }) 
    .catch((err) => {
        console.log(err)
        dispatch(logOutUser())
    });
};

export const logOutUser = () => (dispatch) => {
    if(!isSafari) {
      localStorage.removeItem('CDToken');
    } 
    delete axios.defaults.headers.common['Authorization'];  

    dispatch({
        type: SET_UNAUTHENTICATED
    });
}

export const setAuthorizationHeader = (token) => (dispatch) =>{
    const CDToken = `Token ${token}`;
    if(!isSafari) {
      localStorage.setItem('CDToken', CDToken);
    }
    axios.defaults.headers.common['Authorization'] = CDToken;
};

export const getCalendarAttempts = () => (dispatch) =>{
    axios
    .get('attempts/calendar/get/')
    .then((res)=>{
        dispatch({
            type: SET_CALENDAR_ATTEMPTS,
            payload: res.data.calendar_attempts
        });
    })
    .catch((e)=>{
        console.log(e)
    })
};