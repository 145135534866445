import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import axios from 'axios';

//MUI Stuff
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


//redux stuff
import {connect} from 'react-redux';
// import {setAuthorizationHeader, getUserData} from '../redux/actions/userActions';

import Problems from './Problems';
import ConfirmationDialog from '../../util/ConfirmationDialog';

const styles= (theme)=>({
    ...theme.otherStyles,
    mainButtons:{
        '& button':{
            borderRadius:'0px',
            marginRight:'8px'
        }
    },
    formControl:{
        width:'100%'
    },
    problemCard:{
        backgroundColor : theme.palette.action.hover,
        padding:'8px',
        marginTop:'16px'
    },
    problemDetails:{
        marginTop:'12px',
        "& .MuiTypography-body1":{
            fontWeight:'bold',
            fontSize:'0.9rem'
        },
        "& div": {
            paddingTop:'0px !important'
        }
    }
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NewProblemSet extends Component {

    constructor(props){
        super();
        this.state = {
            reOrder:false,
            title:'',
            difficulty_level:'',
            problems:[
            ],
            errors:{
            },
            newProblemSet:false,
            loading:false,
            resetDialogCheck:false,
            deleteDialogCheck:false,
            saveSuccess:false,
            showSaveConfirmationDialog:false,
            showDeleteConfirmationDialog:false,
        };
    }

    handleTextFieldChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    handleFormChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setInitialState = (problemsetLink)=>{
        axios
        .get(`problemsets/authored/${problemsetLink}/`)
        .then((res)=>{
            this.setState({
                title:res.data.problemset.title,
                difficulty_level: res.data.problemset.difficulty_level,
                problems:res.data.problemset.problems
            })
        })
        .catch((e)=>{
            console.log(e.response.data)
            if (e.response.data.problemset=='problemset not found') {
                this.props.history.push('/users/dashboard')
            }
        })
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.user.username !== this.props.user.username && !this.state.newProblemSet) {
    //         let username = nextProps.user.username;
    //         let problemsetLink = this.props.match.params.problemsetLink;
    //         console.log('direct landing on this page', username)

    //         axios
    //         .get(`problemsets/authored/${problemsetLink}/`)
    //         .then((res)=>{
                
    //             this.setState({
    //                 title:res.data.problemset.title,
    //                 difficulty_level: res.data.problemset.difficulty_level,
    //                 problems:res.data.problemset.problems
    //             })
    //         })
    //         .catch((e)=>{
    //             console.log(e)
    //             console.log('something')
    //             console.log(e.response.data)
    //         })
    //     }
    //   }

    componentDidMount(){
        let problemsetLink = this.props.match.params.problemsetLink;
        if (problemsetLink){
            this.setState({newProblemSet:false})
            this.setInitialState(problemsetLink);
        } else {
            this.setState({
                newProblemSet:true
            })
        }
    }

    toggeleReOrder = ()=>{
        this.setState({
            reOrder:!this.state.reOrder,
        })
    }

    updateProblems = (problems)=>{
        this.setState({
            problems:problems
        })
    }

    onSaveProblemSetClick = ()=>{
        this.setState({
            showSaveConfirmationDialog:true
        })
    }

    updateProblemSet = ()=>{
        this.setState({
            loading:true,
            showSaveConfirmationDialog:false
        })

        // TODO: implement update, call the api to update the problems within the problemset

        var problemSetData = {
            'title':this.state.title,
            'difficulty_level':this.state.difficulty_level
        }

        axios
            .put(`problemsets/authored/${this.props.match.params.problemsetLink}/update/`, problemSetData)
            .then((res)=>{
                
                this.setState({
                    loading:false,
                    saveSuccess:true
                })

            })
            .catch((e)=>{
                this.setState({
                    errors : e.response.data,
                    loading : false
                })
            })
    }

    handleSaveSuccessClose = ()=>{
        let username = this.props.user.username;

        this.setState({
            saveSuccess:false
        })

        this.props.history.push(`/users/dashboard/`)
    }

    onDeleteProblemSetClick = ()=>{
        this.setState({
            showDeleteConfirmationDialog:true
        })
    }


    deleteProblemSet = ()=>{
        axios
            .delete(`problemsets/authored/${this.props.match.params.problemsetLink}/delete/`)
            .then((res)=>{
                this.setState({
                    loading:false,
                    saveSuccess:true
                })
            })
            .catch((e)=>{
                this.setState({
                    errors : e.response.data,
                    loading : false
                })
            })
    }

    render() {
        const {classes} = this.props;

        return (
            <Grid container justify='center' className={classes.mainContainer}>
                <Snackbar open={this.state.saveSuccess} autoHideDuration={2000} onClose={this.handleSaveSuccessClose}>
                    <Alert className={classes.SuccessMessage} onClose={this.handleSaveSuccessClose} severity="success">
                    <Typography variant='body1'>Update successful, routing you to the dashboard in 2 sec</Typography>
                    </Alert>
                </Snackbar>

                <ConfirmationDialog 
                    dialogOpen={this.state.showSaveConfirmationDialog}
                    title = {`Save confirmation`}
                    question = {`This will save the problemset, are you sure?`}
                    cancelCallback = {()=>{this.setState({showSaveConfirmationDialog:false})}}
                    yesCallback = {this.updateProblemSet}
                />

                <ConfirmationDialog 
                    dialogOpen={this.state.showDeleteConfirmationDialog}
                    title = {`Delete confirmation`}
                    question = {`This will delete the problemset, are you sure?`}
                    cancelCallback = {()=>{this.setState({showDeleteConfirmationDialog:false})}}
                    yesCallback = {this.deleteProblemSet}
                />

                <Grid item sm={10} style={{
                    marginTop:'40px',
                    marginBottom:'40px',
                    justifyContent:'center',
                    display:'flex'
                }}>
                    <Typography variant='h5' style={{
                            textTransform:'uppercase'
                    }}>Problemset</Typography>
                </Grid>

                <Grid item container direction='row' justify='center' className={classes.mainButtons}>
                    <Button
                        variant='contained'
                        size='small'
                        color="primary"
                        onClick={this.onSaveProblemSetClick}
                        disabled={this.state.loading}
                    >
                        {this.state.loading && (
                            <CircularProgress size={30} className = {classes.progress}/>
                            )
                        }
                        Save
                    </Button>

                    <Button
                        variant='contained'
                        size='small'
                        color="primary"
                        onClick={this.onDeleteProblemSetClick}
                        style={{marginRight:'0px'}}
                        disabled={this.state.loading}
                    >
                        {this.state.loading && (
                            <CircularProgress size={30} className = {classes.progress}/>
                            )
                        }
                        Delete
                    </Button>
                </Grid>

                <Grid item container direction='row' justify='space-between' alignItems='flex-end' xs={10} sm={10} spacing={2}>
                    <Grid item xs={12} sm={8} style={{
                        paddingLeft:'0px'
                    }}>
                        <TextField 
                                id='title' 
                                name='title' 
                                type='text' 
                                label='Title' 
                                className={classes.textField}
                                value={this.state.title} 
                                onChange={this.handleTextFieldChange} 
                                helperText={this.state.errors.title}
                                error= {this.state.errors.title? true:false}
                                fullWidth
                                style={{
                                    marginBottom:'4px'
                                }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{
                        paddingRight:'0px'
                    }}>
                        <FormControl 
                            size='small' 
                            className={classes.formControl}
                            error = {this.state.errors.difficulty_level? true:false}
                        >
                            <InputLabel id="demo-dialog-select-label">Difficulty Level</InputLabel>
                            <Select
                                labelId="demo-dialog-select-label"
                                name = 'difficulty_level'
                                value = {this.state.difficulty_level}
                                onChange={this.handleFormChange}
                                defaultValue  = {this.state.difficulty_level}
                            >
                                <MenuItem value={'Pre Mortal'}>Pre Mortal</MenuItem>
                                <MenuItem value={'Mortal'}>Mortal</MenuItem>

                            </Select>
                            <FormHelperText>{this.state.errors.difficulty_level}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>


                <Grid item container sm={10} direction='column' justify="center" alignItems="center" style={{
                    backgroundColor:'white',
                    marginTop:'16px',
                    padding:'16px 8px'
                }}>
                    <Grid item sm={12} container justify='center'>
                        <Grid item container justify='space-between' style={{
                            padding:'16px'
                        }}>
                            <Button 
                                variant='contained' 
                                color='primary' 
                                size='small' 
                                style={{
                                    borderRadius:'0px',
                                    marginRight:'8px'
                                }}
                            > <Link 
                                to={{
                                    pathname:`/problemsets/${this.props.match.params.problemsetLink}/problems/new`,
                                    linkToRouteTo:`/problemsets/${this.props.match.params.problemsetLink}/edit`
                                }}
                                style={{
                                    color:'inherit'
                                }}
                                > 
                                    new problem 
                                </Link>
                            </Button>

                            <Button 
                                variant='outlined' 
                                color='primary' 
                                size='small' 
                                style={{
                                    borderRadius:'0px'
                                }}
                                onClick={this.toggeleReOrder}
                            > {this.state.reOrder ? 'End ReOrdering': 'ReOrder'}
                            </Button>
                        </Grid>

                        <Problems
                            problems={this.state.problems}
                            updateProblems={this.updateProblems}
                            reOrderEnabled={this.state.reOrder}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps=(state)=>({
    user: state.user
});


const mapActionsToProps= {
    // setAuthorizationHeader: setAuthorizationHeader,
    // getUserData: getUserData
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(NewProblemSet));
