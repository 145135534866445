import React, { Component, Fragment } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
    ...theme.otherStyles,
    solution:{
        // overflow:'scroll',
        display:'flex',
        flexDirection:'column',
        padding: '10px 10px',
        backgroundColor:'white',
        minHeight:'100%',
        // alignItems:'center'
    },
    buttonWrapper:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginTop:theme.spacing(1),
        '& svg':{
            fontSize:'0.9rem'
        }
    },
    dialogTitle:{
        '& h2':{
            fontWeight:'bold',
        }
    },
    dialogButtons:{
        '& button':{
            borderRadius:'0px'
        }
    },
    code: {
        'border': '1px solid grey',
        'white-space': 'pre',
        'overflow-x':'scroll',
        'padding': '8px',
        'background-color': 'rgb(247, 249, 250)', 
    }
  });


class Solution extends Component {

    constructor(){
        super();
        this.state = {
            dialogOpen:false,
        }
    }

    openDialog = ()=>{
        if (this.props.activeProblemStatus=='success') {
            //if its already a success no need to fetch
            this.props.fetchExpectedSolution();
        } else {
            this.setState({
                dialogOpen:true,
            })
        }
    }

    handleShowSolution = (e) => {
        this.setState({
            dialogOpen:false,
        })
        this.props.fetchExpectedSolution();
    }

    handleCloseDialog = (e) => {
        this.setState({
            dialogOpen:false
        })
    }

    render() {
        const {classes, expectedSolution, showSolution} = this.props;

        let test = "import re\ndef foo(a):\n\tprint('something')";

        let solutionContent = !showSolution ?(
            <div className={classes.buttonWrapper}>
                <Button variant='contained' color='secondary' size='small' onClick={this.openDialog}>
                    <LockOpenOutlinedIcon fontSize='small'/>
                    <span>Unlock Solution</span>
                </Button>
            </div>
        ):(
            <pre className={classes.code}>{expectedSolution}</pre>
        );

        // console.log('testLogPrints', testLogPrints);

        return (
            <div className={classes.solution}>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle 
                        id="alert-dialog-title"
                        className={classes.dialogTitle}
                    >
                            {"Unlock Solution"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will cause you to forfeit your points. Are you sure?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.dialogButtons}>
                            <Button variant='outlined' size='small' value='cancel' onClick={this.handleCloseDialog} color="primary">
                            Cancel
                            </Button>
                            <Button variant='contained' size='small' value='showSolution' onClick={this.handleShowSolution} color="primary" autoFocus>
                            Yes, Show me the solutions
                            </Button>
                    </DialogActions>
                </Dialog>

                {solutionContent}
            </div>
        )
    }
}

Solution.propTypes = {
    fetchExpectedSolution: PropTypes.func.isRequired,
    showSolution: PropTypes.bool.isRequired,
    expectedSolution: PropTypes.string.isRequired,
    // classes: PropTypes.object.isRequired,
    // loginUser: PropTypes.func.isRequired,
    // UI: PropTypes.object.isRequired
};

export default withStyles(styles)(Solution)
