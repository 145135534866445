import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';

import axios from 'axios';


import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';


import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';


const styles = (theme) => ({
    ...theme.otherStyles,
    container:{
        // display: 'flex',
        // minWidth: 120
        // justifyContent:'center',
        // aligntItems:'center',
        // flexDirection:'column'
    },
    formControl:{
        marginTop: theme.spacing(2),
        width:'100%'
    }
  });


class NewProblemSetDialog extends Component {

    constructor(){
        super();
        this.state = {
            title : '',
            difficultyLevel : '',
            errors: {},
            loading: false
        }
    }


    handleTextFieldChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }; 
    
    handleFormChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleClose = (e)=>{
        this.props.handleDialogClose();
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        const newProblemSetInfo = {
            title: this.state.title,
            difficulty_level: this.state.difficultyLevel
        }
        this.setState({
            loading:true,
            errors:{}
        })
        axios
            .post('/problemsets/authored/new/', newProblemSetInfo)
            .then((res)=>{
                this.setState({loading:false})
                this.props.updateProblemSets(res.data.allProblemSets)
                this.props.handleDialogClose();
            })
            .catch((e)=> {
                console.log(e.response.data)
                var data = {'non_field_errors':'something is not right, please try in a few mins later'}
                if (e.response){
                    if (e.response.status != 500){
                        data = e.response.data
                    }
                }
                this.setState({
                    errors:data,
                    loading:false
                });
            })
        // this.props.loginUser(userData, this.props.history);
    };

    render() {
        const {classes, problemSetDialogOpen, handleDialogClose} = this.props;

        return (
            <div className={classes.outputDescription}>
                <Dialog 
                    disableBackdropClick 
                    disableEscapeKeyDown 
                    open={problemSetDialogOpen} 
                    onClose={handleDialogClose}
                    maxWidth={'sm'}
                    fullWidth={true}
                >
                        <DialogTitle>New Problem Set</DialogTitle>
                        <DialogContent>
                        <form className={classes.container}>
                            <TextField 
                                id='title' 
                                name='title' 
                                type='text' 
                                label='Title' 
                                value={this.state.title} 
                                onChange={this.handleTextFieldChange} 
                                helperText={this.state.errors.title}
                                error= {this.state.errors.title? true:false}
                                fullWidth
                            />
                            <FormControl 
                                size='small'
                                className={classes.formControl}
                                error = {this.state.errors.difficultyLevel? true:false}
                            >
                                <InputLabel id="demo-dialog-select-label">Difficulty Level</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    name = 'difficultyLevel'
                                    value={this.state.difficultyLevel}
                                    onChange={this.handleFormChange}
                                    defaultValue  = {this.state.difficultyLevel}
                                >
                                    <MenuItem value='Pre Mortal'>Pre Mortal</MenuItem>
                                    <MenuItem value='Mortal'>Mortal</MenuItem>
                                </Select>
                                <FormHelperText>{this.state.errors.difficultyLevel}</FormHelperText>
                            </FormControl>
                            {this.state.errors.non_field_errors && (
                                <Typography variant="body2" className={classes.customError}>
                                    {this.state.errors.non_field_errors}
                                </Typography>
                            )}
                        </form>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                        {this.state.loading && (
                                <CircularProgress size={30} className = {classes.progress}/>
                            )}
                            Submit
                        </Button>
                        </DialogActions>
                    </Dialog>
            </div>
        )
    }
}


NewProblemSetDialog.propTypes = {
    problemSetDialogOpen : PropTypes.bool.isRequired,
    handleDialogClose : PropTypes.func.isRequired
    // classes: PropTypes.object.isRequired,
    // loginUser: PropTypes.func.isRequired,
    // UI: PropTypes.object.isRequired
};
  
export default withStyles(styles)(NewProblemSetDialog)
