import React, { Component,Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// import AppIcon from '../images/final-logo-black.png';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";

//redux stuff
import { connect } from 'react-redux';
import { setAuthorizationHeader, getUserData } from '../../redux/actions/userActions';


//MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

const styles= (theme)=>({
    ...theme.otherStyles,
    loginForm:{
        display:"flex",
        alignItems:'center',
        justifyContent:'center',
        height:'100%',
        backgroundColor:'#282a36',
        color:'#f8f8f8',
        padding:'40px'

    },
    loginFormItem:{
        display:"flex",
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        '& button':{
            borderRadius:'0px'
        },
        "& .MuiFormLabel-root":{
            color:'white',
        }
    },
    textField:{
        width:'100%',
        color:'#f8f8f8 !important',
        marginBottom:'8px',
        '& .MuiOutlinedInput-root': {
            color:'#f8f8f8',
            backgroundColor:'black',
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'green',
            },
        }
    }
});

class Login extends Component {
    constructor(){
        super();
        this.state = {
            email:'',
            password:'',
            errors: {},
            loading: false,
        };
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        this.setState({
            loading: true,
        })
        const newUserData = {
            email: this.state.email,
            password: this.state.password,
        }
        axios
        .post('/rest-auth/login/', newUserData)
        .then((res)=>{
            this.setState({loading:false})
            this.props.setAuthorizationHeader(res.data.token)
            this.props.getUserData();
            this.props.logInSuccess()
        })
        .catch((e)=> {
            console.log('errors', e)

            let errorData = {}
            if (e.response) {
                errorData = e.response.data
            } else {
                errorData = {
                    "non_field_errors":'something is not right, please try again later'
                }
            }
            this.setState({
                errors : errorData,
                loading:false
            });
        })
    };
    
    handleChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    };    
    
    render() {
        const {classes} = this.props;
        const {errors, loading} = this.state;
        return (
            <Grid container className = {classes.loginForm}>
                <Grid item sm={11} className={classes.loginFormItem}>
                    <form noValidate onSubmit={this.handleSubmit} className={classes.form}>
                        <TextField 
                            id='email' 
                            name='email' 
                            type='email' 
                            label='Email'
                            size="small"
                            variant='outlined'
                            className={classes.textField}
                            value={this.state.email} 
                            onChange={this.handleChange} 
                            helperText={errors.email}
                            error= {errors.email ? true:false}
                            fullWidth/>
                        <TextField 
                            id='password' 
                            name='password' 
                            type='password' 
                            label='password' 
                            size="small"
                            variant='outlined'
                            className={classes.textField}
                            value={this.state.password} 
                            onChange={this.handleChange} 
                            helperText={errors.password}
                            error={errors.password ? true:false}
                            fullWidth/>
                        {errors.non_field_errors && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.non_field_errors}
                            </Typography>
                        )}
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="secondary"
                            disabled={loading} 
                            style={{
                                marginTop:'8px'
                            }} 
                            className = {classes.button}>
                                {loading && (
                                <CircularProgress size={30} className = {classes.progress}/>
                            )}
                            Login
                        </Button>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

Login.propTypes = {
    logInSuccess: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({

});

const mapActionsToProps = {
    setAuthorizationHeader: setAuthorizationHeader,
    getUserData : getUserData
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(Login));