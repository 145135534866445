import React, { Component, createRef, Fragment} from 'react'
import AceEditor from "react-ace";
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types'

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";

import "ace-builds/src-min-noconflict/theme-terminal";

// styles
const styles = (theme)=>({
    ...theme.otherStyles,
    editorLayout:{
        display:'flex',
        flexDirection:'column',
        height:'100%',
        border:'1px solid',
        width:'100%',
        // marginTop:'5px'
    },
    aceEditor: {
        height:'100% !important',
        width:'100% !important',
    }
});

class SolutionEditor extends Component {

    constructor(){
        super();
        this.aceRef = createRef();
    }

    render() {
        const {classes, solutionCode, onSolutionChange, keybinding, language, theme} = this.props;
        return (
            <Fragment>
                <AceEditor
                    keyboardHandler={keybinding}
                    mode={language}
                    theme={theme}
                    value = {solutionCode}
                    onChange={onSolutionChange}
                    className = {classes.aceEditor}
                    wrapEnabled={true}
                    // onLoad={editor => {
                    //     editor.once("change", function() {
                    //         editor.session.getUndoManager().reset();
                    //     });
                    //   }}
                />
            </Fragment>
        )
    }
}


SolutionEditor.propTypes = {
    keybinding: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    theme:PropTypes.string.isRequired,
    solutionCode:PropTypes.string.isRequired,
    onSolutionChange:PropTypes.func.isRequired,
}

export default withStyles(styles)(SolutionEditor)
