import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import AppIcon from '../images/final-logo-black.png';

import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';


//redux stuff
import {connect} from 'react-redux';

//MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';


const styles= (theme)=>({
    ...theme.otherStyles,
    cardGrid:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    cardOuter:{
        // padding:'10px',
        // minWidth:'100%'
        width:'fit-content',
        // marginBottom:'10px',
        // marginRight:'10px',
        textAlign:'center'
    },
    cardContent:{
        padding:'20px',
    },
    cardTitle:{
        marginBottom:'20px',
        // paddingBottom:'40px',
    },
    cardErrorTitle:{
        marginBottom:'20px',
        // paddingBottom:'40px',
        color:theme.palette.secondary.main,
        textAlign:'center',
        fontWeight:'bold'

    },
    cardSuccessTitle:{
        marginBottom:'20px',
        // paddingBottom:'40px',
        color:'green',
        textAlign:'center',
        fontWeight:'bold'
    },
    loaderOuter:{
        // padding:'20px'
    },
    progress:{
        // color:'green'
    }
});

class EmailConfirmation extends Component {

    constructor(props){
        super();
        this.state = {
            loading:false,
            error:''
        };
    }


    componentDidMount(){
        const uid = this.props.match.params.uid
        const key = this.props.match.params.key

        axios
            .put(`rest-auth/signup/account-confirm-email/${uid}/${key}/`)
            .then((res)=>{
                if (this.props.authenticated){
                    this.props.history.push("/problemsets")
                } else {
                    this.props.history.push('/login')
                }
                this.setState({loading:false})
            })
            .catch((e)=>{
                console.log(e)
                if (e.response) {
                    this.setState({
                        loading:false,
                        error:e.response.data
                    })
                }
            })
    }

    render() {
        const {classes} = this.props;
        const {error, loading} = this.state;
        return (
            <Grid container>
                <Grid item sm/>                
                <Grid item sm className={classes.cardGrid}>
                    <img src={AppIcon} alt='monkey.png' className={classes.appIcon}/>
                    <Card className={classes.cardOuter} variant="outlined">
                        <CardContent>
                            {loading && (<Typography variant='h5' className={classes.cardTitle}>Please wait while we validate the information</Typography>)}
        {!loading && error!=='' && (<Typography variant='h5' className={classes.cardErrorTitle}>{error}</Typography>)}
        {!loading && error==='' && (<Typography variant='h5' className={classes.cardSuccessTitle}>Account activated! routing you now</Typography>)}
                            {loading && (<div className={classes.loaderOuter}>
                                <CircularProgress size={40} className = {classes.progress}/>
                            </div>)}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm/>
            </Grid>
        );
    }
}
EmailConfirmation.propTypes = {
    // classes: PropTypes.object.isRequired,
    // user: PropTypes.object.isRequired,
    // UI: PropTypes.object.isRequired,
    // updateEmailActivated: PropTypes.func.isRequired
};

const mapStateToProps=(state)=>({
    authenticated: state.user.authenticated
});


const mapActionsToProps= {
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(EmailConfirmation));
