import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  root: {
    //   '& .MuiMobileStepper-root':{
    //       'padding':'0px',
    //       'background':'transparent',
    //       'margin-bottom':'-8px',
    //   },
    //   '& .MuiButtonBase-root':{
    //       'margin-bottom':'0px'
    //   }
    // maxWidth: 400,
    // flexGrow: 1,
  },
  stepper:{
    'padding':'0px',
    'background':'transparent',
    'margin-bottom':'-8px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    // height: 50,
    // paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
}));

export default function TextMobileStepper(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(props.activeProblemId);
  const maxSteps = props.totalProblems;

  const handleNext = () => {
    props.setActiveProblem(props.activeProblemId+1)
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    props.setActiveProblem(props.activeProblemId-1)
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <MobileStepper
        className={classes.stepper}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={props.activeProblemId}
        nextButton={
          <Button size="small" variant='contained' color='primary' onClick={handleNext} disabled={props.activeProblemStatus=='not_started' || props.activeProblemStatus=='in_progress' || props.activeProblemId === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button variant='contained' color='primary' size="small" onClick={handleBack} disabled={props.activeProblemId === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
}
