import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Output from './Output';
import Solution from './Solution';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextMobileStepper from './Stepper';

import MarkDownPreview from './NewProblem/ProblemDescriptionEditors/MarkDownPreview'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
   //marginBottom: "10px",
   minHeight:'40px',
    height:'40px',
   '& .MuiButtonBase-root': {
        fontSize:'0.8571428571428571rem'
    }
  },
  active:{
      backgroundColor: '#fff',
      //border: 'none',
      //borderBottom: '2px solid grey'
      minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
  notActive:{
      backgroundColor: 'transparent',
      //border: '1px solid grey',
      //borderTop: 'none'
     minWidth: '25%',
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
    problemLayout: {
        height:'78vh',
        // maxHeight:'80vh',
        backgroundColor: '#fff',
        overflowY:'scroll',
    },
    outputLayout: {
      height:'78vh',
      // backgroundColor: '#fff',
      overflowY:'scroll',
    },
    problemButtons:{
      '& button':{
          margin: theme.spacing(1),
      }
    }
}));


export default function SimpleTabs(props) {
  const classes = useStyles();
  // const [forceUpdate] = useReducer(x => x + 1, 0);

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
    // setValue(newValue);
  // };
  return (
    <Fragment>
        <Tabs value={props.activeTab} onChange={props.updateActiveTab} aria-label="simple tabs example" className={classes.tabs}>
          <Tab label="Description" {...a11yProps(0)} className={props.activeTab===0?classes.active:classes.notActive}/>
          <Tab label="Output" {...a11yProps(1)} className={props.activeTab===1?classes.active:classes.notActive}/>
          <Tab label="Solution" {...a11yProps(2)} className={props.activeTab===2?classes.active:classes.notActive}/>
        </Tabs>
        <TabPanel value={props.activeTab} index={0} className={classes.problemLayout}>
          <MarkDownPreview  
            problemDescription = {props.activeProblem.description}
            activeProblemTitle = {props.activeProblem.title}
            activeProblemIndex = {props.activeProblemId}
          />
        </TabPanel>
        <TabPanel value={props.activeTab} index={1} className={classes.outputLayout}>
          <Output testLog={props.testLog}/>
        </TabPanel>
        <TabPanel value={props.activeTab} index={2} className={classes.problemLayout}>
          <Solution 
            expectedSolution={props.expectedSolution} 
            fetchExpectedSolution={props.fetchExpectedSolution}
            showSolution={props.showSolution}
            activeProblemStatus = {props.activeProblem.status}
          />
        </TabPanel>
        <Grid container direction='row' justify='space-between' alignItems='center' className={classes.problemButtons}>
                    <div>
                        <Button variant='outlined' onClick={props.toggleDrawer(true)} color='primary' style={{'marginLeft': '0px', 'marginBottom':'0px'}}>Problems</Button> 
                    </div>
                    <TextMobileStepper 
                        activeProblemId={props.activeProblemId}
                        setActiveProblem={props.setActiveProblem}  
                        totalProblems = {props.totalProblems}
                        activeProblemStatus = {props.activeProblem.status}
                    />
                    {/* <div>
                        <Button 
                            variant='outlined' 
                            color='primary' 
                            style={{'marginLeft': '0px', 'marginBottom':'0px', 'position':'relative'}}
                            >
                            Test
                        </Button> 
                        <Button variant='contained' color='primary' disabled style={{'marginRight': '0px', 'marginBottom':'0px'}}>Submit</Button>
                    </div> */}
          </Grid>
    </Fragment>

  );
}
