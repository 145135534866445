import React, { Component } from 'react'
import {createMuiTheme} from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';


import axios from 'axios'

//import logo from './logo.svg';
import './App.css';

import Arena from './components/Arena';
import DailyProblem from './components/DailyProblem/DailyProblem'
import Home from './components/Home/Home';
import Leaderboard from './components/Leaderboard';
import NewProblem from './components/NewProblem/NewProblem';
import NewProblemSet from './components/NewProblemSet/NewProblemSet';
import ProblemSet from './components/ProblemSet';
import ProblemSets from './components/ProblemSets/ProblemSets';
import UserDashboard from './components/UserDashboard/UserDashboard';
import Footer from './components/layout/Footer';
import HomeNavbar from './components/layout/HomeNavbar';
import Navbar from './components/layout/Navbar';

import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Signup from './pages/Signup';
import EmailConfirmation from './pages/EmailConfirmation';
import AuthRoute from './util/AuthRoute.js'
import DynamicLayoutRoute from './util/DynamicLayoutRoute';


// redux
import {Provider} from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import {getUserData} from './redux/actions/userActions'



import themeFile from './util/theme';
const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
  ...theme.otherStyles,
})

const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

class App extends Component {

  componentWillMount(){
        axios.defaults.baseURL = "http://localhost:8000";
        if (process.env.NODE_ENV!=='development') {
          axios.defaults.baseURL = "https://api.fcomplexity.com/";
          console.log('running production code')
        }

        if(!isSafari) {
          var CDToken = localStorage.getItem('CDToken')    
          if (CDToken) {
            store.dispatch({type:SET_AUTHENTICATED})
            axios.defaults.headers.common['Authorization'] = CDToken;
            store.dispatch(getUserData())
          }
        }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Switch>
              <DynamicLayoutRoute 
                exact 
                path='/leaderboard' 
                layout="DEVNAV"
                component={Leaderboard}
              />
              <DynamicLayoutRoute 
                exact 
                path='/problemsets'
                layout="DEVNAV"
                component={ProblemSets}/>

              <DynamicLayoutRoute 
                exact 
                path='/problems/daily'
                layout="DEVNAV"
                component={DailyProblem}
              />

              <DynamicLayoutRoute 
                exact 
                path='/rest-auth/signup/account-confirm-email/:uid/:key/' 
                layout="DEVNAV"
                component={EmailConfirmation}
              />

              <AuthRoute 
                exact 
                path='/arena/:title' 
                component={Arena} 
                componentName = 'Arena'
              />

              <DynamicLayoutRoute 
                exact 
                path='/problemset/:title' 
                layout="DEVNAV"
                component={ProblemSet}
              />

              <DynamicLayoutRoute 
                exact 
                path='/login' 
                layout="DEVNAV"
                component={Login}
              />
                
              <DynamicLayoutRoute
                exact 
                path='/signup' 
                layout="DEVNAV"
                component={Signup}
                />
              <DynamicLayoutRoute 
                exact 
                path='/' 
                layout="HOMENAV"
                component={Home}
              />
              
              {/* <AuthRoute
                exact
                path='/problemsets/new'
                layout="DEVNAV"
                component={NewProblemSet}
              /> */}

              <AuthRoute
                exact
                path='/problemsets/:problemsetLink/edit'
                layout="DEVNAV"
                component={NewProblemSet}
              />

              {/* <DynamicLayoutRoute
                exact
                path='/problems/:problemLink/edit/:technology?'
                layout="DEVNAV"
                component={NewProblem}
              /> */}
              
              <AuthRoute
                exact
                path='/problemsets/:problemSetLink/problems/new/'
                layout="DEVNAV"
                component={NewProblem}
              />

              <AuthRoute
                exact
                path='/problemsets/:problemSetLink/problems/:problemLink/edit'
                layout="DEVNAV"
                component={NewProblem}
              />
              

              {/* TODO: for now we are only focusing on python implementation */}
              {/* <DynamicLayoutRoute
                exact
                path='/problemsets/:problemSetLink/problems/:problemLink/edit/:technology?'
                layout="DEVNAV"
                user={this.state.user}
                logOutUser={this.logOutUser}
                component={NewProblem}
                /> */}

              <AuthRoute
                exact
                path='/users/dashboard/'
                layout="DEVNAV"
                component={UserDashboard}
                />
              <DynamicLayoutRoute
                layout="HOMENAV"
                component={NotFound}
              />

            </Switch>
            {/* <Arena /> */}
          </Router>
      </Provider>
      </MuiThemeProvider>
    )
  }
}


export default withStyles(styles)(App)
