import React, { Component, createRef} from 'react'
import AceEditor from "react-ace";
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types'

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-min-noconflict/theme-terminal";

// styles
const styles = (theme)=>({
    ...theme.otherStyles,
    editorLayout:{
        display:'flex',
        flexDirection:'column',
        height:'100%',
        minHeight:'60vh',
        border:'1px solid',
        width:'initial !important',
        // marginTop:'5px'
    },
    testEditorHeading:{
        fontWeight:'bold',
        padding:'8px 5px',
        borderBottom:'1px solid'
    },
    aceEditor: {
        height:'100% !important',
        width:'initial !important',
    }
});

class Editor extends Component {

    constructor(){
        super();
        this.testRef = createRef();
        this.state = {
            theme:'terminal',
            keybinding:'sublime',
            tests: '',
            loading: false,
        }
    }

    render() {
        const {classes, code, codeOnChange, mode} = this.props;
        return (
            <div className={classes.editorLayout}>
                 <AceEditor
                    ref = {this.testRef}
                    onChange={codeOnChange}
                    keyboardHandler= {this.state.keybinding}
                    mode = {mode}
                    theme={this.state.theme}
                    value = {code}
                    className = {classes.aceEditor}
                    wrapEnabled = {true}
                />
            </div>
        )
    }
}


Editor.propTypes = {
    // ref: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    code:PropTypes.string.isRequired,
}

export default withStyles(styles)(Editor)
