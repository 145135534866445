import React, { Fragment} from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import MarkdownPreview from '../NewProblem/ProblemDescriptionEditors/MarkDownPreview'
import Output from '../NewProblem/SolutionEditors/Output'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
   //marginBottom: "10px",
    minHeight:'18px',
    height:'35px',
   '& .MuiButtonBase-root': {
        // fontSize:'0.8571428571428571rem'
        fontSize:'0.88rem',
        padding:'3px 6px'
    },
    '& .MuiTab-root':{
      minHeight:'35px'
    }

  },
  active:{
      backgroundColor: '#fff',
      //border: 'none',
      //borderBottom: '2px solid grey'
      minWidth: '25%',
      // fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
  notActive:{
      backgroundColor: 'transparent',
      //border: '1px solid grey',
      //borderTop: 'none'
     minWidth: '25%',
      // fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'none'
  },
    description: {
        // height:'100%',
        paddingTop:'4px',
        height:'87%',
        minHeight:'87%',
        backgroundColor: '#fff',
        overflowY:'hidden',
    },
    output: {
      paddingTop:'4px',
      height:'87%',
      minHeight:'87%',
      backgroundColor: '#fff',
      overflowY:'scroll',
    },
}));


export default function ProblemDescriptionTabs(props) {
  const classes = useStyles();

  const {problemDescription, problemTitle, testLog, activeTab, updateActiveTab} = props;

  return (
    <Fragment>
        <Tabs value={props.activeTab} onChange={updateActiveTab} aria-label="simple tabs example" className={classes.tabs}>
          <Tab label="Description" {...a11yProps(0)} className={activeTab===0?classes.active:classes.notActive}/>
          <Tab label="Output" {...a11yProps(1)} className={activeTab===1?classes.active:classes.notActive}/>
        </Tabs>
        <TabPanel value={activeTab} index={0} className={classes.description}>
            <MarkdownPreview 
                problemDescription = {problemDescription}
                activeProblemTitle = {problemTitle}
                activeProblemIndex = {0}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1} className={classes.output}>
            <Output testLog = {testLog} />
        </TabPanel>
    </Fragment>

  );
}


ProblemDescriptionTabs.propTypes = {
    problemDescription : PropTypes.string.isRequired,
    problemTitle : PropTypes.string.isRequired,
    testLog : PropTypes.array.isRequired,
    activeTab : PropTypes.number.isRequired,
    updateActiveTab: PropTypes.func.isRequired,
}