import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import AppIcon from '../images/final-logo-black.png';
import {Link} from 'react-router-dom';


//redux
import {connect} from 'react-redux';
import {getUserData, setAuthorizationHeader} from '../redux/actions/userActions';

//MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

const styles= (theme)=>({
    ...theme.otherStyles,
    signUpForm:{
        display:"flex",
        alignItems:'center',
        justifyContent:'center'
    },
    signUpFormItem:{
        display:"flex",
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }
});

class Signup extends Component {
    constructor(){
        super();
        this.state = {
            email:'',
            password:'',
            username: '', 
            errors: {},
            loading: false,
        };
    }
    // componentWillReceiveProps(nextProps){
    //     if(nextProps.UI.errors) {
    //         this.setState({errors: nextProps.UI.errors});
    //     }
    // }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        })
        const newUserData = {
            email: this.state.email,
            password: this.state.password,
            username: this.state.username
        }
        axios
        .post('/rest-auth/signup/', newUserData)
        .then((res)=>{
            this.setState({loading:false})
            this.props.setAuthorizationHeader(res.data.token)
            this.props.getUserData();
            this.props.history.push('/problemsets')

        })
        .catch((e)=> {
            console.log('errors', e)
            this.setState({
                errors:e.response.data,
                loading:false
            });
        })
    };
    
    handleChange = (event)=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    };    
    
    render() {
        const {classes} = this.props;
        const {errors, loading} = this.state;
        return (
            <Grid container className = {classes.signUpForm}>
                <Grid item sm={4} className={classes.signUpFormItem}>
                    <img src={AppIcon} alt='monkey.png' className={classes.appIcon}/>
                    <Typography variant="h2" className = {classes.pageTitle}> 
                        Signup
                    </Typography>
                    <form noValidate onSubmit={this.handleSubmit} className={classes.form}>
                        <TextField 
                            id='email' 
                            name='email' 
                            type='email' 
                            label='Email' 
                            className={classes.textField}
                            value={this.state.email} 
                            onChange={this.handleChange} 
                            helperText={errors.email}
                            error= {errors.email ? true:false}
                            fullWidth/>
                        <TextField 
                            id='username' 
                            name='username' 
                            type='username' 
                            label='username' 
                            className={classes.textField}
                            value={this.state.username} 
                            onChange={this.handleChange} 
                            helperText={errors.username}
                            error= {errors.username ? true:false}
                            fullWidth/> 
                        <TextField 
                            id='password' 
                            name='password' 
                            type='password' 
                            label='password' 
                            className={classes.textField}
                            value={this.state.password} 
                            onChange={this.handleChange} 
                            helperText={errors.password}
                            error={errors.password ? true:false}
                            fullWidth/>
                        {errors.non_field_errors && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.non_field_errors}
                            </Typography>
                        )}
                        <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary"
                            disabled={loading} 
                            className = {classes.button}>
                                {loading && (
                                <CircularProgress size={30} className = {classes.progress}/>
                            )}
                            Signup
                        </Button>
                        <br />
                        <small>Already have an account ? login <Link to='/login'>here</Link></small>
                    </form>
                </Grid>
            </Grid>
        );
    }
}

Signup.propTypes = {
    // classes: PropTypes.object.isRequired,
    // user: PropTypes.object.isRequired,
    // UI: PropTypes.object.isRequired,
    // updateSignupSuccess: PropTypes.func.isRequired
  };
  
//   const mapStateToProps = (state) => ({
//     user: state.user,
//     UI: state.UI
//   });
  
const mapStateToProps=(state)=>({
    user: state.user,
});


const mapActionsToProps= {
    setAuthorizationHeader: setAuthorizationHeader,
    getUserData: getUserData
}

export default connect(
    mapStateToProps,
    mapActionsToProps,
)(withStyles(styles)(Signup));
