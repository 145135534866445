import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
// import { withRouter } from "react-router";


//MUI Stuff
import Grid from '@material-ui/core/Grid';


// local stuff
import userImage from '../../images/ninja.png';
import { Typography } from '@material-ui/core';


import UserDashboardTabs from './UserDashboardTabs';

const styles= (theme)=>({
    ...theme.otherStyles,
    basicInfoContainer: {
        backgroundColor:'#282a36',
        color:'#f8f8f8',
        marginTop:'32px',
        padding:'8px 16px 32px 16px',
    },
    userImage:{
        height:'75px',
        width:'75px',
        marginRight:'8px'
    },
    tabsContainer:{
        // backgroundColor:'white',
        // color:'#f8f8f8',
        marginTop:'32px',
        // padding:'8px',
    }
});

    


class UserDashboard extends Component {

    render() {
        const {classes, user} = this.props;
        return (
            <Grid container justify='center'>
                <Grid 
                    item 
                    container 
                    sm={10} 
                    className={classes.basicInfoContainer}
                >
                    <Grid item container sm={4}>
                        <img 
                            src={userImage} 
                            alt="Codedojo" 
                            className={classes.userImage} 
                        />
                        <div >
                            <Typography variant='button' style={{
                                fontSize:'1.0rem'
                            }}>
                                {user.username}
                            </Typography>
                            <Typography variant='subtitle1'>
                                {user.total_points}
                            </Typography>
                        </div>
                    </Grid>

                </Grid>
                <Grid 
                    item 
                    sm={10}
                    className={classes.tabsContainer}
                >
                    <UserDashboardTabs 
                        activeTab={0}
                    />
                </Grid>
            </Grid>

        );
    }
}

export default withStyles(styles)(UserDashboard)