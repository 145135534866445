import React, { Component} from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import axios from 'axios';


import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone';
import EditIcon from '@material-ui/icons/Edit';

import NewProblemSetDialog from '../NewProblemSet/NewProblemSetDialog'

import themeFile from '../../util/theme'
const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
    problemSetDashboard:{
        padding:'16px'
    },
    problemSetCard:{
        backgroundColor : theme.palette.action.hover,
        marginTop:'16px',
        padding:'8px',
    },
    problemsetDetails: {
        marginTop:'8px',
        "& .MuiTypography-body1":{
            fontWeight:'bold',
            fontSize:'0.9rem'
        }
    },
    testDuration:{
        marginRight:theme.spacing(3),
      },
    totalQuestions:{
        marginRight:theme.spacing(3),
        "& .MuiTypography-body1":{
            textAlign:'center'
        }
    },
})

class AuthoredProblemSets extends Component {
    constructor(){
        super();
        this.state={
            orderBy:'',
            order: '',
            problemsets: [
                {
                    // 'rank': 1,
                    'title': 'regex problem sets',
                    'link':'regex-problem-sets',
                    'acceptance_status': 'beta',
                    'difficultyLevel':'mortal',
                    'numberOfProblems':'28',
                    'totalTime':'0:00:60'
                },
                {
                    // 'rank': 1,
                    'title': 'regex problem sets',
                    'link':'regex-problem-sets',
                    'acceptance_status': 'beta',
                    'difficultyLevel':'mortal',
                    'numberOfProblems':'28',
                    'totalTime':'0:02:30'
                }
            ],
            problemSetDialogOpen:false,
        }
    }

    componentDidMount(){
        // // TODO make sure the following is always fetched from the url
        // let username = this.props.match.params.username;

        // axios
        // .get(`users/${username}/problems/`)
        // .then((res)=>{

        //     let problems = res.data.problems;
        //     this.setState({problems:problems})

        // })
        // .catch((e)=>console.log(e))

        axios
        .get('/problemsets/authored/')
        .then((res)=>{
            console.log(res.data)           
            
            this.setState({
                problemsets:res.data['allProblemSets'],
            })
        })
        .catch((e)=>console.log(e))
    }


    onNewProblemSetClick = ()=>{
        this.setState({
            problemSetDialogOpen:true
        })
    }

    handleDialogClose = ()=>{
        this.setState({
            problemSetDialogOpen:false
        })
    }

    updateProblemSets = (allProblemSets)=>{
        axios
        .get('/problemsets/authored/')
        .then((res)=>{
            this.setState({
                problemsets:res.data['allProblemSets'],
            })
        })
        .catch((e)=>console.log(e))
    }

    render() {
        const {classes} = this.props
        return (
            <Grid container direction="column" className={classes.problemSetDashboard}>
                <Grid item sm={12} container justify='center'>
                    <Button 
                        variant='contained' 
                        color='primary' 
                        size='small' 
                        style={{
                            borderRadius:'0px'
                        }}
                        onClick={this.onNewProblemSetClick}
                    >
                            new problemset
                    </Button>
                    <NewProblemSetDialog 
                        problemSetDialogOpen = {this.state.problemSetDialogOpen}
                        handleDialogClose = {this.handleDialogClose}
                        updateProblemSets = {this.updateProblemSets}
                    />
                </Grid>
                {this.state.problemsets.map((problemset, id)=>{
                    return (
                        <Grid item container justify='space-between' key={id} sm={12} className={classes.problemSetCard}>
                            <div style={{
                                display:'flex',
                            }}>
                                <div style={{
                                    display:'flex',
                                    alignItems:'center',
                                    marginRight:'8px'
                                }}><LibraryBooksTwoToneIcon style={{fontSize:40}}/></div>
                                <div>
                                    <Typography variant='h6'>
                                        {problemset.title}
                                    </Typography>

                                    <Grid item container className={classes.problemsetDetails}>
                                        <div className={classes.testDuration}>
                                            <Typography variant="subtitle2">Total Time</Typography> 
                                            <Typography variant="body1">{problemset.totalTime.split(':').slice(1,).join(':')} mins</Typography> 
                                        </div>
                                        <div className={classes.totalQuestions}>
                                            <Typography variant="subtitle2">Total Problems</Typography> 
                                            <Typography variant="body1">{problemset.numberOfProblems}</Typography> 
                                        </div>
                                        <div className={classes.difficultLevel}>
                                            <Typography variant="subtitle2">Difficulty</Typography> 
                                            <Typography variant="body1">{problemset.difficultyLevel}</Typography> 
                                        </div>
                                    </Grid>
                                </div>
                            </div>
                            <div>
                                <Link to={{
                                    pathname:`/problemsets/${problemset.link}/edit`
                                }}>
                                    <EditIcon /> 
                                </Link>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }
}

export default withRouter(withStyles(styles)(AuthoredProblemSets))