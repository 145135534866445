import React, { Component, createRef, Fragment} from 'react'
import AceEditor from "react-ace";
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TestEditor from './TestEditor';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';


import axios from 'axios'
import PropTypes from 'prop-types'

import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/keybinding-vim";
import "ace-builds/src-noconflict/keybinding-sublime";

// styles
const styles = (theme)=>({
    ...theme.otherStyles,
    editorLayout:{
        display:'flex',
        flexDirection:'column',
        height:'100%',
    },
    formControl: {
        margin: theme.spacing(1),
        // minWidth: 120,
        color:'black',

        //   '& .MuiInput-underline:before': {
            // borderBottomColor: 'grey', // Semi-transparent underline
        //   },
        //   '& .MuiInput-underline:hover:before': {
            // borderBottomColor: 'grey', // Solid underline on hover
        //   },
        //   '& .MuiInput-underline:after': {
            // borderBottomColor: 'grey', // Solid underline on focus
        //   }, 
      },
    selectEmpty: {
        color:'black',
        border:'1px solid grey',
        paddingLeft: '8px'
    },
    menuItem: {
        color:'black',
    },
    editorBottom:{
        '& button':{
            margin: theme.spacing(1),
        }
    },
    editorTop:{
        '& .MuiFormControl-root':{
            margin: theme.spacing(1),
            'margin-bottom': '2px'
        },
    },
    progressBar: {
        position:'absolute'
    },
    aceEditor: {
        height:'100% !important',
        border:'1px solid',
        width:'inherit !important',
    }
});



export class Editor extends Component {

    constructor(props){
        super();
        this.aceRef = createRef();
        this.testRef=null;
        this.state = {
            theme:'chrome',
            keybinding:'sublime',
            code: '',
            loading: false,
            testEditorOpened:false,
            tests:'',
            resetCode:false,
        }
    } 
    componentDidMount(){
        this.props.setUpdateCodeInEditor(this.updateCodeInEditor);
        this.props.setGetCodeInEditor(this.getCodeInEditor);
        this.props.setUpdateCodeInTestEditor(this.updateCodeInTestEditor);
        this.props.setGetCodeInTestEditor(this.getCodeInTestEditor);
    }

    defaultCode = `def add(a:int, b:int):
    return a+b`


    handleEndSession=()=>{
        this.props.handleEndSession()
    }

    handleFormChange=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleTechnologyChange=(e)=>{
        const tech_ind = this.props.technologies.findIndex((t)=>{
            return t.name==e.target.value;
        })
        this.props.setActiveTechnology(tech_ind);
        // this.setState({
        //     [e.target.name]:e.target.value
        // })
    }

    // handleReset=(e)=>{
    //     // var editor = this.refs.ace.editor.getSession()
    //     //TODO: alert are you sure you want to do this
    //     // editor.setValue(this.defaultCode)
    //     this.setState({code:this.props.activeProblemCode})
    // }

    handleSubmitClick=(e)=>{
        var currTime = this.props.getTime();
        currTime = this.props.formatTime(currTime);
        this.props.updateTestLog()
        this.props.updateActiveTab(e, 1)

        //get code
        var editor = this.aceRef.current.editor
        var editorCode = editor.getSession().getValue()
        this.setState(
            {
                loading:true,
                code:editorCode
            }
        )

        axios
            .post('/submit/', {
                'solution': editorCode,
                'time-taken': currTime,
                'problem-link':this.props.activeProblemLink,
                'technology': this.props.technologies[this.props.activeTechnologyId].name
            })
            .then((res)=>{
                if (res.data.success === true) {
                    this.props.updateProblemStatus()
                    // this.props.updateActiveTab(e,0)
                }
                this.props.updateTestLog(res.data.test_cases)
                this.setState({loading:false})
            })
            .catch((e)=>{
                this.props.updateTestLog([e])
                this.setState({loading:false})
            })
    }

    updateCodeInEditor=(solution)=>{
        this.setState({
            code:solution
        })
        
        var editor = this.aceRef.current.editor;
        editor.getSession().setValue(solution);
    }

    updateCodeInTestEditor=(solution)=>{
        this.setState({
            tests:solution
        })

        if (this.testRef && this.testRef.current) {
            var testEditor = this.testRef.current.editor
            testEditor.getSession().setValue(solution); 
        }
    }

    getCodeInTestEditor=()=>{
        // console.log('get test code', this.testRef)
        if (this.testRef && this.testRef.current) {
            var testEditor = this.testRef.current.editor;
            var testEditorCode = testEditor.getSession().getValue();
            return testEditorCode;
        } else {
            return '';
        }

        // return '';
    }

    getCodeInEditor=()=>{
        var editor = this.aceRef.current.editor;
        var editorCode = editor.getSession().getValue();
        return editorCode;
    }

    
    openTestEditor=(e)=>{
        this.setState({testEditorOpened:!this.state.testEditorOpened})
    }

    handleTestClick=(e)=>{
        // set state
        this.props.updateTestLog()
        this.props.updateActiveTab(e, 1)


        var editor = this.aceRef.current.editor
        var editorCode = editor.getSession().getValue()
        // get the test code
        if (this.testRef && this.testRef.current) {
            var testEditor = this.testRef.current.editor
            var testCode = testEditor.getSession().getValue() 
        } else {
            var testCode = this.props.activeProblemTests
        }

        this.setState({
            loading:true, 
            test : testCode,
            code: editorCode,
        })
        // this.props.updateTestLog()
        axios
            .post('/test/', {
                'solution': editorCode,
                'tests': testCode,
                'technology': this.props.technologies[this.props.activeTechnologyId].name
            })
            .then((res)=>{
                this.props.updateTestLog(res.data.test_cases)
                this.setState({loading:false})
            })
            .catch((e)=>{
                console.log(e)
                this.props.updateTestLog([e])
                this.setState({loading:false})
            })
    } 

    setChildRef=(ref)=>{
        this.testRef = ref;
    }

    codeEditorOnChange=(value)=>{
        this.setState({code:value});
    }

    testEditorOnChange=(value)=>{
        this.setState({tests:value})
    }

    getTechnologyVersion = (technology)=> {
        if (technology=='python') {
            return 'Python 3.6'
        } else if (technology=='java') {
            return 'Java 1.8'
        }
    }

    render() {
        const {classes} = this.props;

        var technologyOptions = this.props.technologies.map((technology, id)=>{
            return (
                    <MenuItem className={classes.menuItem} value={technology.name}>{this.getTechnologyVersion(technology.name)}</MenuItem>
            )  
            })

        return (
            <div className={classes.editorLayout}>
                <Grid container direction='row' justify='space-between' alignItems='center' className={classes.editorTop}>
                    <div>
                    <FormControl size='small' style={{'marginLeft':'0px'}}>
                        <Select
                            name='technology'
                            defaultValue={this.props.technologies[this.props.activeTechnologyId].name}
                            value={this.props.technologies[this.props.activeTechnologyId].name}
                            onChange={this.handleTechnologyChange}
                            className={classes.selectEmpty}>
                                {technologyOptions}
                        </Select>
                    </FormControl>
                     <FormControl size='small'>
                        <Select
                        name='theme'
                        defaultValue={this.state.theme}
                        value={this.state.theme}
                        onChange={this.handleFormChange}
                        className={classes.selectEmpty}>
                        <MenuItem className={classes.menuItem} value={'monokai'}>Monokai</MenuItem>
                        <MenuItem className={classes.menuItem} value={'chrome'}>Chrome</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl size='small'>
                        <Select
                        name='keybinding'
                        defaultValue={this.state.keybinding}
                        value={this.state.keybinding}
                        onChange={this.handleFormChange}
                        className={classes.selectEmpty}>
                        <MenuItem className={classes.menuItem} value={'sublime'}>Sublime</MenuItem>
                        <MenuItem className={classes.menuItem} value={'vim'}>Vim</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                    <div>
                        <Button 
                            variant='contained' 
                            size="small" 
                            color='secondary'
                            onClick={this.handleEndSession}    
                        >
                                End Session
                        </Button>
                    </div>
                </Grid>
                
                <AceEditor
                    ref = {this.aceRef}
                    keyboardHandler={this.state.keybinding}
                    mode={this.props.technologies[this.props.activeTechnologyId].name}
                    theme={this.state.theme}
                    value = {this.state.code}
                    onChange={this.codeEditorOnChange}
                    // defaultValue={this.defaultCode}
                    className = {classes.aceEditor}
                    wrapEnabled={true}
                    onLoad={editor => {
                        console.log('on load')
                        editor.once("change", function() {
                            editor.session.getUndoManager().reset();
                        });
                      }}
                />
                {this.state.testEditorOpened && 
                    <TestEditor 
                        setRef = {this.setChildRef}
                        keybinding= {this.state.keybinding}
                        mode = {this.props.technologies[this.props.activeTechnologyId].name}
                        theme={this.state.theme}
                        value = {this.state.tests}
                        testEditorOnChange = {this.testEditorOnChange}
                        // setGetCodeInTestEditor={(getCodeInTestEditor)=> this.getCodeInTestEditor=getCodeInTestEditor}
                        // setUpdateCodeInTestEditor={(updateCodeInTestEditor)=> this.updateCodeInTestEditor=updateCodeInTestEditor}
                        // defaultValue={this.defaultCode}
                        // className = {classes.aceEditor}
                    />
                }
                <Grid container direction='row' justify='space-between' alignItems='center' className={classes.editorBottom}>
                    <div>
                        {/* <Button onClick={this.handleReset} style={{'marginLeft': '0px', 'marginBottom':'0px'}}>Reset</Button>  */}
                        <Button onClick={this.openTestEditor} style={{'marginLeft': '0px', 'marginBottom':'0px'}}>
                            Test Cases
                            {this.state.testEditorOpened? (
                                <ArrowDropDownIcon fontSize="small" />
                            ): (
                                <ArrowDropUpIcon fontSize="small" />
                            )}
                        </Button>
                    </div>
                    <div>
                        <Button 
                            size='medium'
                            variant='outlined' 
                            color='primary' 
                            onClick={this.handleTestClick} 
                            style={{'marginLeft': '0px', 'marginBottom':'0px', 'position':'relative'}}
                            disabled={this.state.loading}
                            >
                            {this.state.loading && (
                                <CircularProgress size={24} className={classes.progressBar}/>
                            )}
                            Test
                            
                        </Button> 
                        <Button 
                            size='medium'
                            variant='contained' 
                            color='primary' 
                            disabled={this.state.loading}
                            onClick={this.handleSubmitClick} 
                            style={{'marginRight': '0px', 'marginBottom':'0px'}}>
                             {this.state.loading   && (
                                <CircularProgress size={24} className={classes.progressBar}/>
                            )}                           
                            Submit
                        </Button>
                    </div>
                </Grid>
            </div>
        )
    }
}

Editor.propTypes = {
    updateTestLog : PropTypes.func.isRequired,
    updateActiveTab: PropTypes.func.isRequired,
    activeProblemLink: PropTypes.string.isRequired,
}

export default withStyles(styles)(Editor)