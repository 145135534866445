import React, { Component, Fragment } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import Button from '@material-ui/core/Button';

const styles = (theme) => ({
    ...theme.otherStyles,
    root:{
        // overflow:'scroll',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        alignItems:'space-between',
        // padding: '8px 8px',
        backgroundColor:'#282a36',
        // minHeight:'100%',
        height:'80vh',
        "& button" : {
            borderRadius:'0px',
            backgroundColor:'#ef5350'
        },
        color:"#f8f8f8"
    },
    outputDescription:{
        // overflow:'scroll',
        height:'100%',
        overflow:'scroll'
    },
    outputStatus: {
        '& h6': {
            margin: theme.spacing(1),
            'marginBottom': '0px',
        }
    },
    passColor: {
        'color': green[400]
    },
    failColor: {
        'color': red[400]
    },
    singleTestLog: {
        margin: theme.spacing(1),
    },
    testLogPrints: {
        backgroundColor:'black',
        // margin: theme.spacing(1),
        '& h6': {
            'display': 'flex',
            'align-items':'center',
        },
        '& svg': {
            'marginRight': theme.spacing(1),
        }
    },
    stdout: {
        'border': '1px solid grey',
        'white-space': 'pre-wrap',
        'padding': '8px'
    }
  });


class Output extends Component {

    render() {
        const {classes, testLog, showDescription, fail} = this.props;

        let passedTests = testLog.filter((test)=>test.status=="Pass").length
        let failedTests = testLog.length - passedTests

        let testLogPrints = testLog && testLog.length>0 ? (
            testLog.map((test, id)=>{
                if (test.status === "Pass"){
                   return(
                        <Grid key={id} container direction='column' justify='flex-start' className={classes.testLogPrints}>
                            <Grid className={classes.singleTestLog}>
                            <Typography variant="subtitle1" fontWeight="500" className={classes.passColor}>
                                <CheckCircleIcon fontSize="small"/> <span fontWeight="bold">{test.name}: {test.status}</span>
                            </Typography>
                            
                            {test.stdout && <Typography className={classes.stdout} fontWeight='bold'>
                                    {test.stdout}
                            </Typography>}
                            </Grid>
                        </Grid>
                    )
                } else {
                   return(
                        <Grid key={id} container direction='column' justify='flex-start' className={classes.testLogPrints}>
                            <Grid item sm={12} className={classes.singleTestLog}>
                            <Typography variant="subtitle1" fontWeight="500" className={classes.failColor}>
                                <ErrorOutlineOutlinedIcon fontSize="small"/> <span fontWeight="bold">{test.name}: {test.msg}</span>
                            </Typography>
                            {test.stdout && <Typography className={classes.stdout}>
                                {test.stdout}
                            </Typography>}
                            </Grid>
                        </Grid>
                    )
                }
            })
        ) : (
            <p>Press Submit to see outputs</p>
        );

        return (
            <div className={classes.root}>
                <div className={classes.outputDescription}>
                    <Grid container justify='flex-start' className={classes.outputStatus}>
                        <Typography display='inline' variant="subtitle1" fontWeight="500" className={classes.passColor}>
                            <Box fontWeight="bold">Passed: {passedTests}</Box>
                        </Typography>
                        <Typography display='inline' variant="subtitle1" fontWeight="500" className={classes.failColor}>
                            <Box fontWeight="bold">Failed: {failedTests}</Box>
                        </Typography>
                    </Grid>

                    <hr className={classes.visibleSeparator} />
                    {testLogPrints}
                </div>
                <div style={{
                    justifyContent:'center',
                    display:'flex',
                    padding:'8px'
                }}>
                    {fail && <Button 
                        variant='contained'
                        size='small'
                        color='secondary'
                        onClick = {showDescription}
                    >
                        View Description
                    </Button>}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Output)
