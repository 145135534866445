import React, { Component } from 'react';

class Clock extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            count:props.time,
            running:false,
        }
    }
    componentDidMount(){
        this.props.setStartTimer(this.handleStart);
        this.props.setStopTimer(this.handleStop);
        this.props.setResetTimer(this.handleReset);
        this.props.setGetTime(this.getTime);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.running !== prevState.running){
          switch(this.state.running) {
            case true:
              this.handleStart();
            }
        }
        if(prevProps.time !== this.props.time) {
          this.setState({count: this.props.time});
        }
    }
    handleStart= ()=> {
        this.timer = setInterval(() => {
          const newCount = this.state.count - 1;
          if (newCount===0) {
            this.handleStop()
            this.props.timeExpired()
          }
          this.setState(
            {count: newCount >= 0 ? newCount : 0}
          );
        }, 1000);
      }
    
      handleStop=()=> {
        if(this.timer) {
          clearInterval(this.timer);
          this.setState(
            {running:false}
          );
        };
        return this.state.count;
      }
     
      getTime=()=>{
        return this.state.count;
      }
      
      handleReset=()=> {
        console.log('reset timer')
        this.setState(
          {count: this.props.time}
        );
      };
    
      handleCountdown=(time)=>{
          console.log('inside handleCountdown', time);
        this.setState({
          count: time,
          running: true
        });
    }

    format(time) {
        // #this.setState({count:time});
        let seconds = time % 60;
        let minutes = Math.floor(time / 60);
        minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
        seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
        return minutes + ':' + seconds;
      }
    
    render() {
        return (
          <div className="displayedTime">
            <h1>{this.format(this.state.count)}</h1>
          </div>
        )
    }
}

export default Clock
