import React, { Component, createRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Logo from '../../images/final-logo-white-1.png';
import UserImage from '../../images/ninja.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { withRouter } from "react-router";

//Redux
import { connect } from 'react-redux';
import { logOutUser } from '../../redux/actions/userActions';

//local files
import themeFile from '../../util/theme';


const theme = createMuiTheme(themeFile);
const styles = (theme)=>({
  ...theme.otherStyles,
  loginLink: {
    marginRight: theme.spacing(2),
  },
  toolbar:{
    display:'flex',
    alignItems:'center',
    justifyContent:"space-between",
    '& button':{
      borderRadius:'0px'
    },
    maxHeight:'45px',
    minHeight:'45px'
  },
  leftNavbar:{
    display:'flex',
    alignItems:'center',
    justifyContent:"start",
  },
  logo: {
    maxWidth: '50px',
    maxHeight: '50px',
    marginRight: theme.spacing(1),
  },
  activeProblemSets:{
    "padding-top": "13px",
    "padding-bottom": "10px",
    "border-bottom": "3px solid",
    "border-bottom-color":theme.palette.secondary.main,
    "padding-left": "8px",
    "padding-right": "8px",
    "color": theme.palette.secondary.main,
    "& span":{
        "font-weight":'bold'
    }
  },
  problemSets:{
    "padding-top": "13px",
    "padding-bottom": "10px",
    "padding-left": "8px",
    "padding-right": "8px",
    "color": '#eee',
    "& span":{
        "font-weight":'bold'
    }
  },
  activeLeaderboard:{
    "padding-top": "13px",
    "padding-bottom": "10px",
    "border-bottom": "3px solid",
    "border-bottom-color":theme.palette.secondary.main,
    "padding-left": "8px",
    "padding-right": "8px",
    "color": theme.palette.secondary.main,
    "& span":{
        "font-weight":'bold'
    }
  },
  leaderboard:{
    "padding-top": "13px",
    "padding-bottom": "10px",
    "padding-left": "8px",
    "padding-right": "8px",
    "color": '#eee',
    "& span":{
        "font-weight":'bold'
    }
  },
  rightNavbar:{
    display:"flex",
    alignItems:'center',
    justifyContent:'end'
  },
  userImage:{
    maxWidth: '35px',
    maxHeight: '30px',
    borderRadius:'3px',
    marginRight: theme.spacing(1)
  },
  userName:{
    color:theme.palette.secondary.main
  },
  points: {
    // fontFamily:'serif',
    color:"white",
    marginLeft:theme.spacing(1)
  }
})

class Navbar extends Component {

  constructor(){
    super();
    this.state={
      anchorEl:null,
      dropDownRef : createRef(),
      openDropDown:false
    }
  }

  handleProfileClick = (e)=>{
    this.setState({anchorEl:e.currentTarget, openDropDown:true})
  }

  handleProfileClose = ()=>{
    this.setState({anchorEl: null})
  }

  routeToDashboard = ()=>{
    this.setState({anchorEl: null})
    this.props.history.push('/users/dashboard')
  }

  handleLogOut=()=>{
    this.setState({anchorEl: null})
    this.props.logOutUser()
    // this.props.history.push('/')
  }

  render() {
    const {classes, user} = this.props;

    return (
      <div >
      <AppBar position="static">
        <Toolbar variant='dense' className={classes.toolbar}>
          <div className = {classes.leftNavbar}>
            <Link to='/'>
              <img src={Logo} alt="Codedojo" className={classes.logo} />
            </Link>
            <Link to="/problems/daily" className={window.location.pathname==='/problems/daily' ? classes.activeProblemSets: classes.problemSets} >
              <Typography variant='button'>
                Daily
              </Typography>
            </Link>

            <Link to="/problemsets" className={window.location.pathname==='/problemsets' ? classes.activeProblemSets: classes.problemSets} >
              <Typography variant='button'>
                Problemsets
              </Typography>
            </Link>

            <Link to="/leaderboard" className={window.location.pathname==='/leaderboard'?classes.activeLeaderboard: classes.leaderboard}>
              <Typography variant='button'>
                Leaderboard
              </Typography>
                  
            </Link>
          </div>
          <div className = {classes.rightNavbar}>
              {user.authenticated ? (
                <Fragment>
                  <Button aria-controls={this.dropDownRef} aria-haspopup="true" onClick={this.handleProfileClick} >
                    <img src={UserImage} alt="noImage" className={classes.userImage} /> 
                    <div className={classes.userName}>
                      {user.username}<span className={classes.points}>{user.total_points}</span>
                    </div>
                    {this.state.anchorEl?(
                        <ArrowDropUpIcon fontSize="small" style={{color:'white'}}/>
                    ):(
                        <ArrowDropDownIcon fontSize="small" style={{color:'white'}}/> 
                    )}
                  </Button>
                  <Menu
                    ref = {this.dropDownRef}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    // id="simple-menu"
                    // variant='menu'
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleProfileClose}
                  >
                    <MenuItem onClick={this.routeToDashboard}>Dashboard</MenuItem>
                    <MenuItem onClick={this.handleProfileClose}>Settings</MenuItem>
                    <MenuItem onClick={this.handleLogOut}>Log Out</MenuItem>
                  </Menu>
                </Fragment>
              ):(
                <Fragment>
                    <Link to="/login" className={classes.loginLink}>
                        <Button 
                        variant='contained' 
                        color='primary'
                        size="small"
                        >
                          Log In
                      </Button>          
                    </Link>
                    <Link to="/signup">
                      <Button 
                        variant='contained' 
                        color='secondary'
                        size="small"
                        >
                          Sign Up
                      </Button>
                    </Link>
                </Fragment>
              )}

          </div>
        </Toolbar>
      </AppBar>
    </div>
    )
  }
}


Navbar.propTypes = {
  logOutUser: PropTypes.func.isRequired,
  // classes: PropTypes.object.isRequired,
  // loginUser: PropTypes.func.isRequired,
  // UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user : state.user
});

const mapActionsToProps = {
  logOutUser: logOutUser
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withRouter(withStyles(styles)(Navbar)));