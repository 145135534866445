import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';



const useStyles = makeStyles({
    passColor: {
        'color': green[400]
    },
    failColor: {
        'color': red[400]
    },
    notStartedColor:{
      'color':'grey',
    },
    startedColor:{
      'color':'blue'
    },
    list: {
        width: 250,
        // background:''
        color:"black"
    },
    textWrapper:{
        '& .MuiListItemText-primary':{
            display:'flex',
        },
    },
    textIcon:{
        marginTop:'2px',
        marginRight:'2px',
        display:'inline'
    },
    listItemtext: {
        display:'inline'
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer(props) {
  const classes = useStyles();
//   const [state, setState] = React.useState({
//     drawerOpen: false,
//   });

    const handleClick = (i, status='valid')=>{
        props.setActiveProblem(i, status);
    }

  const list = (
    <div className={classes.list}
    //   className={clsx(classes.list, {
    //     [classes.fullList]: anchor === 'top' || anchor === 'bottom',
    //   })}
      role="presentation"
      onClick={props.toggleDrawer(false)}
      onKeyDown={props.toggleDrawer(false)}
    >
      <List>
        {props.problems.map((problem, index) => (
          <ListItem button key={index} className={
              problem.status==='success' ? 
                (classes.passColor): (
                  problem.status==='not_started' ? 
                    (classes.notStartedColor): (
                      problem.status === 'in_progress'?
                        (classes.startedColor):(classes.failColor)
                    )
                )} name={index} onClick={()=>handleClick(index, problem.status)} >
            {/* <ListItemIcon> {index} </ListItemIcon> */}
            <ListItemText className={classes.textWrapper}>
                  {problem.status==='success' && 
                  <Fragment>
                      <div className={classes.textIcon}><CheckCircleIcon fontSize="small" /></div> 
                      <div className={classes.listItemText} >P{index+1}: {problem.title}</div>
                  </Fragment>
                  }
                  {problem.status!=='success' && 
                  <Fragment>
                      <div className={classes.textIcon}><KeyboardArrowRightIcon fontSize="small" /></div> 
                      <div className={classes.listItemText} >P{index+1}: {problem.title}</div>
                  </Fragment>
                  }
            </ListItemText>
          </ListItem>
        ))}
      </List>
      {/* <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  return (
    <div>
        <React.Fragment >
          <Drawer anchor='left' open={props.drawerOpen} onClose={props.toggleDrawer(false)}>
            {list}
          </Drawer>
        </React.Fragment>
    </div>
  );
}