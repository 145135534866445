import React, { Component, Fragment } from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import themeFile from '../util/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
const theme = createMuiTheme(themeFile);

const styles = (theme)=>({
    ...theme.otherStyles,
    gridContainer:{
        'justify-content':'center'
    },
    gridItem:{
        padding:'10px'
    },
    root: {
        minWidth: 300,
        color:'black',
        padding: '20px 83px 50px',
        borderRadius: '3px',
        marginTop: '20px',
        marginBottom: '20px',
        background: 'white',
      },
      title: {
          fontWeight: 'bold',
          paddingBottom:"20px",
          textTransform:'capitalize'
      },

        problemSetInfoDesktop: {
            display: 'flex',
            alignItems:'center',
            justifyContent:'start',
            "& .MuiTypography-body1":{
                fontWeight:'bold'
            },
            "& .MuiTypography-subtitle2":{
                fontSize:'0.8rem'
            },
        },

      problemSetInfoMobile: {
        display: 'flex',
        flexDirection:'column',
        // alignItems:'start',
        // justifyContent:'start',
        "& .MuiTypography-body1":{
            fontWeight:'bold',
            marginBottom:theme.spacing(1)
        },

        "& .MuiTypography-subtitle2":{
            fontSize:'0.8rem'
        },
       },
      testDuration:{
        marginRight:theme.spacing(3),
      },
      totalQuestions:{
          marginRight:theme.spacing(3)
      },
    
      instructions:{
          marginTop: '60px'
      },
      instructionTitle:{
          paddingBottom:"20px"
      },
      instructionSubtitle:{
            color:'#111',
            marginTop:"-20px",
            marginBottom:'20px',
            fontWeight:'bold'
      },
      list:{
          marginTop:'0px',
          paddingLeft:'16px',
          counterReset:'hint',
          marginBottom:'16px',
          '& li':{
              fontSize:"0.9rem",
              marginBottom:'0.4rem',
              color:"#444"
          }
      },

      startButton:{
          marginTop:'60px',
          display:'flex',
          justifyContent:"center",
          '& button':{
              width:"fit-content",
              borderRadius:'0px',
              padding: '10px 26px'
          }
      },
      
      unAuthenticatedButton:{
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
            "& .MuiTypography-body2":{
                fontWeight:'bold',
                color:theme.palette.secondary.main,
                marginTop:theme.spacing(1)
            },
      }
})


export const withMediaQuery = (queries = []) => Component => props => {
    const mediaProps = {}
    queries.forEach(q => {
      mediaProps[q[0]] = useMediaQuery(q[1])
    })
    return <Component {...mediaProps} {...props} />
}

class ProblemSet extends Component {

    constructor(){
        super();
        this.state={
           problemSetInfo:{
                title:'Problem Set',
                link: 'problem-set',
                total_time:"00:00:00",
                total_problems:0,
                difficulty_level:'Mortal',
                sessionInProgress: null,
           },
           validProblemSet:false,
           errors:{}
        }
    }

    componentDidMount(){
        const title = this.props.match.params.title;
        axios.get(`/problemset/${title}/`)
            .then((res)=>{
                this.setState({
                    problemSetInfo: res.data,
                    validProblemSet: true,
                })
                // this.props.setSessionId(title)
            })
            .catch((e)=>{
                var data = {'non_field_errors':'something is not right on the server, please check back in a few mins'}
                if (e.response && e.response.status!=500){
                    data = e.response.data
                }
                this.setState({
                    errors:data,
                });
            })
    }

    render() {
        const {classes, isDesktop=false, authenticated} = this.props
        
        return (
            <div>
                <Grid container className={classes.gridContainer}>
                    <Grid item sm={11} className={classes.gridItem}>
                        <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <Typography variant="h4" className={classes.title}>
                                {this.state.problemSetInfo.title}
                            </Typography>
                            <div className={isDesktop? classes.problemSetInfoDesktop: classes.problemSetInfoMobile}>
                                <div className={classes.testDuration}>
                                   <Typography variant="subtitle2">Total Time</Typography> 
                                   <Typography variant="body1"> {this.state.problemSetInfo.total_time} mins</Typography> 
                                </div>
                                <div className={classes.totalQuestions}>
                                   <Typography variant="subtitle2">Total Questions</Typography> 
                                   <Typography variant="body1"> {this.state.problemSetInfo.total_problems} questions</Typography> 
                                </div>
                                <div className={classes.difficultLevel}>
                                   <Typography variant="subtitle2">Difficulty Level</Typography> 
                                   <Typography variant="body1">{this.state.problemSetInfo.difficulty_level}</Typography> 
                                </div>
                            </div>
                            <div className={classes.instructions}>
                                <Typography variant="h4" className={classes.instructionTitle}>
                                Instructions 
                                </Typography>
                                <Typography variant="subtitle2" className={classes.instructionSubtitle}>
                                Make sure you read this carefully 
                                </Typography>

                                <ol className={classes.list}>
                                    <li>
                                        <Typography vairant='body1'>
                                            There is a separate timer for every problem.
                                        </Typography>
                                    </li>
                                    <li> 
                                        <Typography variant="body1">You can end the session by clicking the <span style={{fontWeight:"bold"}}> END SESSION </span> button on the top right corner of the editor.</Typography>
                                    </li>
                                    <li> 
                                        <Typography variant="body1">You can leave an active session at any point and come back to it by using <Link to={`${this.props.match.params.title}`}>{window.location.href}</Link>. Save this link</Typography>
                                    </li>
                                </ol> 
                            </div>

                            <div className={classes.startButton}>
                                {authenticated? 
                                    (
                                        <div className={classes.unAuthenticatedButton}>
                                            <Link to={{
                                                    pathname:`/arena/${this.state.problemSetInfo.link}`,
                                                    problemSetTitle:`${this.state.problemSetInfo.link}`
                                                }}>
                                                <Button variant='contained' color='primary' size='large' disabled={!this.state.validProblemSet}>
                                                    {this.state.problemSetInfo.sessionInProgress? 
                                                    'Resume': 'Start'}
                                                </Button>
                                            </Link>
                                                {this.state.errors && <Typography variant='body2'>{this.state.errors['non_field_errors']}</Typography>}
                                                {!this.state.validProblemSet && !this.state.errors && <Typography variant='body2'>The problemset link is not valid</Typography>}
                                                {this.state.problemSetInfo.sessionInProgress && <Typography variant='body2'>There is already a session named {this.state.problemSetInfo.sessionInProgress} in progress</Typography>}
                                        </div>
                                    ):
                                    (
                                        <div className={classes.unAuthenticatedButton}>
                                            <Button variant='contained' color='primary' size='large' disabled>
                                            Start
                                            </Button>
                                            <Typography variant='body2'>You need to <Link to={{
                                                    pathname:`/login`,
                                                    linkToRouteTo:`/problemset/${this.state.problemSetInfo.link}`
                                                }}>Log In</Link> first</Typography>
                                        </div>
                                    )
                                }

                            </div>


                        </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

ProblemSet.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    // setSessionId: PropTypes.func.isRequired,
    // sessionId: PropTypes.string.isRequired
    // classes: PropTypes.object.isRequired,
    // loginUser: PropTypes.func.isRequired,
    // UI: PropTypes.object.isRequired
};

// export default withStyles(styles)(ProblemSet)
export default withStyles(styles)(withMediaQuery([
    ['isDesktop', theme => theme.breakpoints.up('md')]
  ])(ProblemSet))